import {
  useEffect,
  useCallback,
  createContext,
  useContext,
  useReducer,
} from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { publishStatusUpdate } from '../graphql/mutations'
import { onStatusUpdate } from '../graphql/subscriptions'
import { useAppState } from './useAppState'
import { useAuth } from './useAuth'
import {
  NOTIF_CH_FIRM_DESIGN,
  NOTIF_CH_FIRM_DESIGN_CONFIRM,
  NOTIF_CH_FIRM_DESIGN_WHAT_OUR_SETTINGS,
  NOTIF_CH_FIRM_DESIGN_OUR_SETTINGS,
  NOTIF_CH_RUN_BID,
  NOTIF_CH_RUN_EXECUTE,
  NOTIF_CH_RUN_RETRIEVE,
  NOTIF_CH_RUN_ADVANCE,
  NOTIF_CH_RUN_ADVANCE_OOB,
  NOTIF_CH_AWARD_BID,
  NOTIF_CH_HOST_ADVANCE_SIM_YEAR,
  NOTIF_CH_HOST_MERGE_TEAMS,
} from '../utils/constants'

const NotificationChannelContext = createContext()

const initialState = {
  teamConfirmedDesign: {
    enabled: false,
    message: '',
  },
  teamDesignWhatOurSettings: {
    enabled: false,
    message: {},
  },
  teamDesignOurSettings: {
    enabled: false,
    message: {},
  },
  hostDashboardAdvanceRefetchTeam: false,
  leaderAwardRefetchTeam: false,
  teamRunBidRefetchBids: {
    enabled: false,
    message: {},
  },
  teamRunExecuteRefetchActiveClients: {
    enabled: false,
    message: {},
  },
  teamRunExecuteRefetchOtherIncome: {
    enabled: false,
    message: {},
  },
  teamRunAdvance: {
    enabled: false,
    message: {},
  },
  teamRunAdvanceOobNotif: {
    enabled: false,
    message: {},
  },
  hostAdvanceSimYear: {
    enabled: false,
    message: '',
  },
  hostMergeTeams: {
    enabled: false,
    message: {},
    // Store executed component names
    in: [],
  },
}

function reducer(state, action) {
  switch (action.type) {
    /**
     * Host
     */
    case 'team-confirmed-design':
      return {
        ...state,
        teamConfirmedDesign: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-confirmed-design-executed':
      return {
        ...state,
        teamConfirmedDesign: {
          enabled: false,
          message: '',
        },
      }
    case 'host-dashboard-advance-refetch-team':
      return {
        ...state,
        hostDashboardAdvanceRefetchTeam: true,
      }
    case 'host-dashboard-advance-refetch-team-executed':
      return {
        ...state,
        hostDashboardAdvanceRefetchTeam: false,
      }
    case 'host-advance-sim-year':
      return {
        ...state,
        hostAdvanceSimYear: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'host-advance-sim-year-executed':
      return {
        ...state,
        hostAdvanceSimYear: {
          enabled: false,
          message: '',
        },
      }
    case 'host-merge-teams':
      return {
        ...state,
        hostMergeTeams: {
          ...state.hostMergeTeams,
          enabled: true,
          message: action.payload,
        },
      }
    case 'host-merge-teams-executed': {
      const enabled = {
        enabled:
          action.payload.enabled !== undefined
            ? action.payload.enabled
            : state.hostMergeTeams.enabled,
      }
      const message = {
        message:
          action.payload.message !== undefined
            ? action.payload.message
            : state.hostMergeTeams.message,
      }
      const _in = {
        in:
          action.payload.in !== undefined
            ? [...state.hostMergeTeams.in, action.payload.in]
            : state.hostMergeTeams.in,
      }
      const newState = {
        ...state,
        hostMergeTeams: { ...enabled, ...message, ..._in },
      }

      return newState
    }
    case 'host-merge-teams-executed-final':
      return {
        ...state,
        hostMergeTeams: {
          enabled: false,
          message: {},
          in: [],
        },
      }
    /**
     * Leader
     */
    case 'leader-award-refetch-team':
      return {
        ...state,
        leaderAwardRefetchTeam: true,
      }
    case 'leader-award-refetch-team-executed':
      return {
        ...state,
        leaderAwardRefetchTeam: false,
      }
    /**
     * Team
     */
    case 'team-run-bid-refetch-bids':
      return {
        ...state,
        teamRunBidRefetchBids: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-run-bid-refetch-bids-executed':
      return {
        ...state,
        teamRunBidRefetchBids: {
          enabled: false,
          message: {},
        },
      }
    case 'team-run-execute-refetch-active-clients':
      return {
        ...state,
        teamRunExecuteRefetchActiveClients: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-run-execute-refetch-active-clients-executed':
      return {
        ...state,
        teamRunExecuteRefetchActiveClients: {
          enabled: false,
          message: {},
        },
      }
    case 'team-run-retrieve-refetch-other-income':
      return {
        ...state,
        teamRunExecuteRefetchOtherIncome: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-run-retrieve-refetch-other-income-executed':
      return {
        ...state,
        teamRunExecuteRefetchOtherIncome: {
          enabled: false,
          message: {},
        },
      }
    case 'team-run-advance':
      return {
        ...state,
        teamRunAdvance: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-run-advance-executed':
      return {
        ...state,
        teamRunAdvance: {
          enabled: false,
          message: {},
        },
      }
    case 'team-run-advance-oob-client-notif':
      return {
        ...state,
        teamRunAdvanceOobNotif: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-run-advance-oob-client-notif-executed':
      return {
        ...state,
        teamRunAdvanceOobNotif: {
          enabled: false,
          message: {},
        },
      }

    case 'team-design-what-our-settings':
      return {
        ...state,
        teamDesignWhatOurSettings: {
          enabled: true,
          message: action.payload,
        },
      }
    case 'team-design-what-our-settings-executed':
      return {
        ...state,
        teamDesignWhatOurSettings: {
          enabled: false,
          message: {},
        },
      }
    case 'team-design-what-settings-executed':
      return {
        ...state,
        teamDesignOurSettings: {
          enabled: false,
          message: {},
        },
      }
    default:
      return state
  }
}

export function NotificationChannelProvider({ children }) {
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    initialState
  )
  const {
    state: {
      me,
      offering: { offeringId },
      rtNotification,
    },
    dispatch: dispatchAppState,
  } = useAppState()
  const { userId, getUserId, isHost, isLeader, isTeam } = useAuth()

  const handleSyncUpdate = useCallback(
    (data) => {
      if (userId === null || data.teamId === null) {
        return
      }
      // Check data for teamId
      if (data.teamId === userId) {
        // Data must be an object
        dispatchAppState({ type: data.type, payload: data.value })
      }
    },
    [dispatchAppState, userId]
  )

  /**
   * Team has confirmed their design
   */
  const handleTeamConfirmedDesign = useCallback((data) => {
    dispatch({ type: 'team-confirmed-design', payload: data })
  }, [])

  /**
   * Team has requested the current design settings
   */
  const handleTeamDesignWhatOurSettings = useCallback((data) => {
    dispatch({ type: 'team-design-what-our-settings', payload: data })
  }, [])

  /**
   * A team member has responded with the current design settings
   */
  const handleTeamDesignOurSettings = useCallback((data) => {
    dispatch({ type: 'team-design-our-settings', payload: data })
  }, [])

  /**
   * A team member has advanced to next year
   */
  const handleRunAdvanceUpdate = useCallback(
    (data) => {
      if (isHost) {
        dispatch({ type: 'host-dashboard-advance-refetch-team', payload: data })
      }

      if (isTeam) {
        dispatch({ type: 'team-run-advance', payload: data })
      }
    },
    [isHost, isTeam]
  )

  /**
   * Notify when the client has gone out of business
   */
  const handleRunAdvanceOobUpdate = useCallback(
    (data) => {
      if (isTeam) {
        dispatch({ type: 'team-run-advance-oob-client-notif', payload: data })
      }
    },
    [isTeam]
  )

  const handleRunBidUpdate = useCallback(
    (data) => {
      if (isLeader) {
        dispatch({ type: 'leader-award-refetch-team', payload: data })
      } else if (isTeam) {
        dispatch({ type: 'team-run-bid-refetch-bids', payload: data })
      }
    },
    [isLeader, isTeam]
  )

  /**
   * Run > Execute
   */
  const handleRunExecuteUpdate = useCallback(
    (data) => {
      if (isTeam) {
        dispatch({
          type: 'team-run-execute-refetch-active-clients',
          payload: data,
        })
      }
    },
    [isTeam]
  )

  /**
   * Run > Retrieve
   */
  const handleRunRetrieveUpdate = useCallback(
    (data) => {
      if (isTeam) {
        dispatch({
          type: 'team-run-retrieve-refetch-other-income',
          payload: data,
        })
      }
    },
    [isTeam]
  )

  const handleAwardBidUpdate = useCallback(
    (data) => {
      if (isTeam) {
        dispatch({ type: 'team-run-bid-refetch-bids', payload: data })
      }
    },
    [isTeam]
  )

  /**
   * Host > Advance simulation year
   */
  const handleAdvanceSimYear = useCallback(
    (data) => {
      if (isTeam || isLeader) {
        dispatch({ type: 'host-advance-sim-year', payload: data })
      }
    },
    [isTeam, isLeader]
  )

  /**
   * Host > Merge Firms
   */
  const handleMergeTeams = useCallback(
    (data) => {
      if (isTeam || isLeader) {
        dispatch({ type: 'host-merge-teams', payload: data })
      }
    },
    [isTeam, isLeader]
  )

  const sendRTNotification = useCallback((statusInput) => {
    return API.graphql(graphqlOperation(publishStatusUpdate, statusInput))
  }, [])

  const handleStatusUpdate = useCallback(
    (update) => {
      if (update.updater !== me) {
        if (update.type === NOTIF_CH_FIRM_DESIGN) {
          // Team is making changes on the Design Your Firm page
          handleSyncUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_FIRM_DESIGN_CONFIRM) {
          // Team has confirmed their design
          handleTeamConfirmedDesign(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_FIRM_DESIGN_WHAT_OUR_SETTINGS) {
          // A team member has requested for the current team's design setting
          handleTeamDesignWhatOurSettings(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_FIRM_DESIGN_OUR_SETTINGS) {
          // A team member has responded with the current team's design setting
          handleTeamDesignOurSettings(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_RUN_ADVANCE) {
          handleRunAdvanceUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_RUN_ADVANCE_OOB) {
          // Out of business clients
          handleRunAdvanceOobUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_RUN_BID) {
          handleRunBidUpdate({
            enabled: true,
            message: JSON.parse(update.message),
          })
        } else if (update.type === NOTIF_CH_RUN_EXECUTE) {
          handleRunExecuteUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_RUN_RETRIEVE) {
          handleRunRetrieveUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_AWARD_BID) {
          handleAwardBidUpdate(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_HOST_ADVANCE_SIM_YEAR) {
          handleAdvanceSimYear(JSON.parse(update.message))
        } else if (update.type === NOTIF_CH_HOST_MERGE_TEAMS) {
          // Merge teams/firms
          handleMergeTeams(JSON.parse(update.message))
        }
      }
    },
    [
      me,
      handleSyncUpdate,
      handleTeamConfirmedDesign,
      handleTeamDesignWhatOurSettings,
      handleTeamDesignOurSettings,
      handleRunBidUpdate,
      handleRunExecuteUpdate,
      handleRunRetrieveUpdate,
      handleRunAdvanceUpdate,
      handleRunAdvanceOobUpdate,
      handleAwardBidUpdate,
      handleAdvanceSimYear,
      handleMergeTeams,
    ]
  )

  useEffect(() => {
    if (offeringId === null || offeringId < 0) {
      return
    }

    const channel = rtNotification.channel
    const subscription = API.graphql(
      graphqlOperation(onStatusUpdate, { to: channel })
    ).subscribe({
      next: ({ value }) => {
        const update = value.data.onStatusUpdate
        handleStatusUpdate(update)
      },
      error: (errorObj) => {
        console.warn(errorObj)

        const { error } = errorObj

        if (
          error.errors &&
          error.errors.find((e) => e.message === 'Connection closed')
        ) {
          dispatchAppState({ type: 'reload-on', payload: 'Connection closed' })
        }
      },
    })

    return function cleanup() {
      // Stop receiving data updates from the subscription
      subscription.unsubscribe()
    }
  }, [
    me,
    offeringId,
    dispatchAppState,
    getUserId,
    handleStatusUpdate,
    rtNotification.channel,
  ])

  return (
    <NotificationChannelContext.Provider
      value={{ state, sendRTNotification, dispatch }}
    >
      {children}
    </NotificationChannelContext.Provider>
  )
}

export const useNotificationChannel = () => {
  return useContext(NotificationChannelContext)
}
