import { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Stack from 'react-bootstrap/Stack'
import Spinner from 'react-bootstrap/Spinner'

import { useAppState } from '../../hooks/useAppState'
import { useLeader } from '../../hooks/useLeader'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

const LeaderAwardTotalWonEngagement = () => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const { getTeamsWonEngageByOfferingId } = useLeader()
  const {
    state: { hostAdvanceSimYear, teamConfirmedDesign, hostMergeTeams },
    dispatch: dispatchNC,
  } = useNotificationChannel()

  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (offeringId === -1 || offeringId === null) {
      return
    }

    setLoading(true)
    getTeamsWonEngageByOfferingId(offeringId)
      .then((queryData) => {
        setTeams(queryData.data.getTeamsWonEngageByOfferingId)
      })
      .catch((err) => {
        console.log('Unable to retrieve teams won engagement data', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getTeamsWonEngageByOfferingId, offeringId])

  /**
   * Fetch the team info whenever we receive this notification when host
   * advances to next year
   */
  useEffect(() => {
    if (offeringId === -1 || offeringId === null) {
      return
    }

    if (hostAdvanceSimYear.enabled) {
      if (teams.length === 0) {
        setLoading(true)
      }

      getTeamsWonEngageByOfferingId(offeringId)
        .then((queryData) => {
          setTeams(queryData.data.getTeamsWonEngageByOfferingId)
        })
        .catch((err) => {
          console.log('Unable to retrieve teams won engagement data', err)
        })
        .finally(() => {
          if (teams.length === 0) {
            setLoading(false)
          }
          dispatchNC({ type: 'host-advance-sim-year-executed' })
        })
    }
  }, [
    hostAdvanceSimYear.enabled,
    getTeamsWonEngageByOfferingId,
    dispatchNC,
    offeringId,
    // Be careful here. Don't add teams.length.
  ])

  /**
   * Fetch the team info whenever we receive this notification when a team
   * has confirmed their design
   */
  useEffect(() => {
    if (offeringId === -1 || offeringId === null) {
      return
    }

    if (teamConfirmedDesign.enabled && !loading) {
      if (teams.length === 0) {
        setLoading(true)
      }

      getTeamsWonEngageByOfferingId(offeringId)
        .then((queryData) => {
          setTeams(queryData.data.getTeamsWonEngageByOfferingId)
        })
        .catch((err) => {
          console.log('Unable to retrieve teams won engagement data', err)
        })
        .finally(() => {
          if (teams.length === 0) {
            setLoading(false)
          }
          dispatchNC({ type: 'team-confirmed-design-executed' })
        })
    }
  }, [
    teamConfirmedDesign.enabled,
    getTeamsWonEngageByOfferingId,
    dispatchNC,
    offeringId,
    loading,
    // Be careful here. Don't add teams.length.
  ])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('LeaderAwardTotalWonEngagement')
    ) {
      setLoading(true)
      getTeamsWonEngageByOfferingId(offeringId)
        .then((queryData) => {
          setTeams(queryData.data.getTeamsWonEngageByOfferingId)
        })
        .catch((err) => {
          console.log('Unable to retrieve teams won engagement data', err)
        })
        .finally(() => {
          setLoading(false)
          dispatchNC({
            type: 'host-merge-teams-executed',
            payload: {
              in: 'LeaderAwardTotalWonEngagement',
            },
          })
        })
    }
  }, [hostMergeTeams, dispatchNC, getTeamsWonEngageByOfferingId, offeringId])

  return (
    <Table responsive className="w-100 mt-4 align-middle run-table">
      <thead className="bg-gray-100">
        <tr>
          <th>Total Engagements Won</th>
        </tr>
      </thead>
      <tbody>
        {/**
         * Empty row with a little bit of paddings to fill in the gap
         */}
        <tr>
          <td className="py-1"></td>
        </tr>
        {loading ? (
          <tr>
            <td>
              <Spinner
                as="div"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="secondary"
              />
            </td>
          </tr>
        ) : (
          teams.map((team, teamIndex) => {
            const teamName =
              team.name !== '' ? team.name : `Firm${teamIndex + 1}`
            return (
              <tr key={`${teamIndex}${teamName}`}>
                <td>
                  <Stack
                    direction="horizontal"
                    className="justify-content-between"
                  >
                    <span>{teamName}</span>
                    <span>{team.wonCount}</span>
                  </Stack>
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </Table>
  )
}

export default LeaderAwardTotalWonEngagement
