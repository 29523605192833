import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import StatsWidget from '../../components/StatsWidget'

import { useStats } from '../../hooks/useStats'

import { getLeaderboardSatisfactionText } from '../../utils/utils'

export default function BottomLineStats() {
  const { getSimStats, getTeamStats } = useStats()
  const [simStats, setSimStats] = useState()
  const [teamStats, setTeamStats] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    Promise.all([getSimStats(), getTeamStats()])
      .then((results) => {
        if (results[0]) {
          setSimStats(results[0].data.getSimStats)
        }
        if (results[1]) {
          setTeamStats(results[1].data.getTeamStats)
        }
      })
      .catch((err) => {
        console.log(`Unable to fetch Sim and Team stats`, err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getSimStats, getTeamStats])

  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <h1 className="text-center text-primary fw-bold">
            Overall Program Statistics
          </h1>
          <div className="shadow-plain px-3 py-2 mt-4 rounded">
            Interested in statistics? View key metrics for{' '}
            <strong>Bottom Line</strong> below.
          </div>
        </Col>
      </Row>
      <Row className="gy-4 mt-4 stats-container align-items-stretch">
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={simStats?.totalSims ?? '--'}
            type="0"
            label="Total simulations to date"
          />
        </Col>
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={simStats?.totalTeams ?? '--'}
            type="1"
            label="Total participant teams to date"
          />
        </Col>
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={teamStats?.topTeam ?? '--'}
            type="2"
            label="#1 Firm"
          />
        </Col>
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={
              teamStats?.topProfit
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }).format(teamStats.topProfit)
                : '--'
            }
            type="3"
            label="Highest profit"
          />
        </Col>
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={
              teamStats?.topClientSatisfaction
                ? getLeaderboardSatisfactionText(
                    teamStats.topClientSatisfaction
                  )
                : '--'
            }
            type="4"
            label="Highest client satisfaction"
          />
        </Col>
        <Col md={6} lg={4}>
          <StatsWidget
            loading={loading}
            value={
              teamStats?.topEmployeeSatisfaction
                ? getLeaderboardSatisfactionText(
                    teamStats.topEmployeeSatisfaction
                  )
                : '--'
            }
            type="5"
            label="Highest employee satisfaction"
          />
        </Col>
      </Row>
    </Container>
  )
}
