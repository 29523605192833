import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  leaderboardTotalProfit as leaderboardTotalProfitQuery,
  leaderboardHPRevenue as leaderboardHPRevenueQuery,
  leaderboardEmployeeSatisfaction as leaderboardEmployeeSatisfactionQuery,
  leaderboardClientSatisfaction as leaderboardClientSatisfactionQuery,
} from '../graphql/queries'

export const useLeaderboard = () => {
  const getTotalProfit = useCallback(async (offeringId, year) => {
    return API.graphql(
      graphqlOperation(leaderboardTotalProfitQuery, { offeringId, year })
    )
  }, [])

  const leaderboardHPRevenue = useCallback(async (offeringId) => {
    return API.graphql(
      graphqlOperation(leaderboardHPRevenueQuery, { offeringId })
    )
  }, [])

  const leaderboardEmployeeSatisfaction = useCallback(async (offeringId) => {
    return API.graphql(
      graphqlOperation(leaderboardEmployeeSatisfactionQuery, { offeringId })
    )
  }, [])

  const leaderboardClientSatisfaction = useCallback(async (offeringId) => {
    return API.graphql(
      graphqlOperation(leaderboardClientSatisfactionQuery, { offeringId })
    )
  }, [])

  return {
    getTotalProfit,
    leaderboardHPRevenue,
    leaderboardEmployeeSatisfaction,
    leaderboardClientSatisfaction,
  }
}
