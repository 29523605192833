import './style.css'

import ChatHost from '../ChatHost'

export default function HostSupport() {
  return (
    <div className="mt-3 mt-lg-0">
      <h2 className="text-primary h5 fw-bold mb-4">Support Chat</h2>
      <ChatHost />
    </div>
  )
}
