import { useState, useEffect, useCallback, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import './style.css'

import championIcon from '../../images/icons/champion-icon.svg'

import ModalConfirm from '../ModalConfirm'
import BlsModal from '../BlsModal'

import { useAppState } from '../../hooks/useAppState'
import { useAuth } from '../../hooks/useAuth'
import { useTeam } from '../../hooks/useTeam'
import { useClient } from '../../hooks/useClient'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import {
  NOTIF_CH_RUN_ADVANCE,
  NOTIF_CH_RUN_ADVANCE_OOB,
} from '../../utils/constants'
import { isOutOfBusiness } from '../../utils/bls-utils'

export default function RunAdvance() {
  const {
    state: {
      year,
      me,
      ready,
      offering: { offeringId },
      rtNotification,
    },
    dispatch,
  } = useAppState()
  const navigate = useNavigate()
  const { userId } = useAuth()
  const { updateTeam, readyToAdvance } = useTeam()
  const { getMyActiveClients, updateClient } = useClient()
  const [oobClients, setOobClients] = useState(null)
  const { sendRTNotification } = useNotificationChannel()
  const [inProgress, setInProgress] = useState(false)
  const yearInText =
    year === 1
      ? 'first'
      : year === 2
      ? 'second'
      : year === 3
      ? 'third'
      : year === 4
      ? 'fourth'
      : 'unknown'
  const [showModal, setShowModal] = useState(false)
  const [oobModalShow, setOobModalShow] = useState(null)
  const [oobModalBody, setOobModalBody] = useState('')
  const [advanceErrorModalShow, setAdvanceErrorModalShow] = useState(false)
  const [advanceErrorModalBody, setAdvanceErrorModalBody] = useState('')
  const [oobCheck, setOobCheck] = useState(false)
  const isMounted = useRef(true)

  /**
   * Be sure to set showModal to false when the other team member has clicked
   * the Advance button
   */
  useEffect(() => {
    if (ready === true && showModal === true) {
      setShowModal(false)
    }
  }, [ready, showModal])

  /**
   * Handle advance button click
   */
  const handleAdvanceYear = useCallback(async () => {
    // Don't do anything if ready is true because we already advanced the year
    // and wait for the host to advance
    if (ready) {
      return
    }

    // Mark 'ready', +1 year in team table in the database
    const newYear = year + 1
    try {
      const teamUpdateInput = {
        id: userId,
        ready: true,
        year: newYear,
      }
      await updateTeam(teamUpdateInput)

      // Wrap up the year, reset some states
      dispatch({ type: 'reset-the-year', payload: true })

      // Send notification to the channel
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId: userId, ready: true, newYear }),
        updater: me,
        type: NOTIF_CH_RUN_ADVANCE,
      }
      sendRTNotification(statusInput)

      if (newYear <= 3) {
        navigate('/run?tab=bid', { replace: true })
        // Save tab name to the app state
        dispatch({ type: 'set-run-tab', payload: 'bid' })
      } else {
        // In year 4 & 5, bid tab is inactive, redirect the user to EXECUTE
        // tab instead.
        // Save tab name to the app state
        dispatch({ type: 'set-run-tab', payload: 'execute' })
        navigate('/run?tab=execute', { replace: true })
      }
    } catch (err) {
      console.log('Unable to mark as "ready"', err)
    }
    if (isMounted.current) {
      setInProgress(false)
    }
  }, [
    dispatch,
    me,
    navigate,
    rtNotification.channel,
    sendRTNotification,
    updateTeam,
    userId,
    year,
    ready,
  ])

  /**
   * Redirect the user to the EXECUTE tab in one of the following situations:
   * - User accesses ADVANCE tab using direct URL
   * - User is on the ADVANCE tab when the other team member advances to Year
   *   5
   */
  useEffect(() => {
    if (year !== null && year === 5) {
      // In year 5, BID tab is inactive, redirect the user to EXECUTE
      // tab instead.
      // Save tab name to the app state
      dispatch({ type: 'set-run-tab', payload: 'execute' })
      navigate('/run?tab=execute', { replace: true })
    }
  }, [year, dispatch, navigate])

  // Check for out of business client
  const checkForOutOfBusiness = async () => {
    if (userId === null || year === null) {
      console.log(
        'Unable to check for out-of-business clients. Unknown user id or year.',
        userId,
        year
      )
      return
    }

    const clientsData = await getMyActiveClients(userId, year)
    const clients = clientsData.data.getMyActiveClients
    let _oobClients = []
    let updatePromise = []
    for (let i = 0; i < clients.length; i++) {
      if (isOutOfBusiness(clients[i].target.stability)) {
        _oobClients.push(clients[i].target.displayName)

        // Mark this client as out of business
        updatePromise.push(
          updateClient({
            input: {
              teamId: userId,
              targetId: clients[i].target.id,
              outOfBusiness: true,
            },
          })
        )
      }
    }

    // Send real-time notification to members of the same team if there's oob
    // clients
    if (_oobClients.length > 0) {
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId: userId, oobClients: _oobClients }),
        updater: me,
        type: NOTIF_CH_RUN_ADVANCE_OOB,
      }
      updatePromise.push(sendRTNotification(statusInput))
    }

    if (updatePromise.length > 0) {
      try {
        await Promise.all(updatePromise)
      } catch (err) {
        console.log('Unable to mark client(s) out-of-business', err)
      }
    }

    setOobClients(_oobClients)
  }

  /**
   * Show oob client popup if any
   */
  useEffect(() => {
    if (oobClients === null) {
      return
    }

    if (oobClients.length > 0) {
      setOobModalBody(
        `Unfortunately ${oobClients[0]} has gone out of business! They are no longer your client.`
      )
      setOobModalShow(true)
    } else {
      setOobCheck(true)
    }
  }, [oobClients])

  /**
   * Advance to next year after we showed all the oob client in the modal
   */
  useEffect(() => {
    isMounted.current = true
    if (oobCheck === false) {
      return
    }
    handleAdvanceYear()
    return () => {
      isMounted.current = false
    }
  }, [oobCheck, handleAdvanceYear])

  const handleModalClose = () => {
    setOobModalShow(false)

    // Remove the first one out because we already showed them in the oob
    // modal.
    // Continue to do so until there's no more oob client left in the list.
    if (oobClients.length > 0) {
      setOobClients(oobClients.slice(1))
    }
  }

  const handleConfirm = async () => {
    setShowModal(false)
    setInProgress(true)

    try {
      const readyToAdvanceResult = await readyToAdvance(offeringId, userId)

      if (readyToAdvanceResult?.data?.readyToAdvance?.status) {
        if (readyToAdvanceResult.data.readyToAdvance.status === 'Success') {
          // Check for out-of-business clients
          checkForOutOfBusiness()
        } else if (readyToAdvanceResult.data.readyToAdvance.status === 'Fail') {
          if (
            readyToAdvanceResult.data.readyToAdvance.statusDetail ===
            'This team has already indicated their readiness before'
          ) {
            setAdvanceErrorModalBody(
              'Your team has already advanced to the next simulation year.'
            )
            setAdvanceErrorModalShow(true)
          }
        }
      }
    } catch (err) {
      console.log(`readyToAdvance :: err`, err)
      setInProgress(false)
    }
  }

  const handleAdvanceErrorModalClose = () => {
    setAdvanceErrorModalShow(false)
  }

  // Don't render anything if we're in Year 5
  if (year === 5) {
    return null
  }

  return (
    <>
      <BlsModal
        show={!!oobModalShow}
        setShow={setOobModalShow}
        title={'Out of business'}
        body={oobModalBody}
        onClose={handleModalClose}
      />
      {/**
       * Modal for showing error message when "Advance" failed
       */}
      <BlsModal
        show={advanceErrorModalShow}
        setShow={setAdvanceErrorModalShow}
        title={'Advance'}
        body={advanceErrorModalBody}
        headerTextColor="text-danger"
        onClose={handleAdvanceErrorModalClose}
      />
      <ModalConfirm
        title="Hang on...are you ready to move forward?"
        body={`Proceed to Year ${year + 1}?`}
        show={!!showModal}
        headerTextColor="text-primary"
        handleClose={() => setShowModal(false)}
        handleConfirm={handleConfirm}
      />
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className="text-primary h5 fw-bold">You’re a Rockstar!</h2>
            <p>
              Congratulations, your team has completed the {yearInText} year of
              Bottom Line!
            </p>
            <p>
              So, now what? Well, if you haven’t done so already, be sure that
              you’re happy with your firm’s design.
            </p>
            <div className="text-center mt-5 mb-5">
              <Button
                as={Link}
                to="/design"
                variant="outline-primary rounded-pill"
              >
                Tweak Firm Design
              </Button>
            </div>

            <p>
              Then, when you’re ready, click <strong>Advance</strong> to move
              ahead to the next year.
            </p>
          </Col>
          <Col>
            <Stack direction="horizontal" className="align-items-start" gap={4}>
              <div className="d-none d-lg-flex" style={{ height: 550 }}>
                <div className="vr"></div>
              </div>
              <div className="flex-grow-1">
                <img
                  src={championIcon}
                  alt="champion"
                  width={94}
                  height={192.97}
                  className="d-block mx-auto my-5"
                />
                <Stack
                  direction="horizontal"
                  className="justify-content-between py-3 pe-3 run-advance-box"
                >
                  <div className="h4 text-success fw-bold m-0">
                    Advance to Year {year + 1}
                  </div>
                  <Button
                    size="sm"
                    variant="outline-success"
                    className="rounded-pill px-3 run-advance-button"
                    disabled={inProgress || ready}
                    onClick={() => {
                      setShowModal(true)
                    }}
                  >
                    {inProgress && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        variant="success"
                        className="me-2"
                      />
                    )}{' '}
                    Advance
                  </Button>
                </Stack>
                <p className="mt-1">
                  <em>
                    Disclaimer: Once you advance to the next year, there’s no
                    going back! If desired, ensure that you adjust your firm’s
                    operating parameters and complete any merges before
                    proceeding.
                  </em>
                </p>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  )
}
