import { useState, useEffect, useCallback } from 'react'
import clsx from 'clsx'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Spinner from 'react-bootstrap/Spinner'

import { useTeam } from '../../hooks/useTeam'
import { useAppState } from '../../hooks/useAppState'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

import { ChatForm } from '../Chat'
import { NOTIF_CHAT_HOST } from '../../utils/constants'

import './style.css'

const ChatHost = () => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const {
    state: { teamConfirmedDesign, hostMergeTeams },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const [teams, setTeams] = useState([])
  const { getTeamsByOfferingId } = useTeam()
  const [loading, setLoading] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [hasNewMessages, setHasNewMessages] = useState([])

  const handleFetchTeams = useCallback(async () => {
    return getTeamsByOfferingId(offeringId).then((teamsData) => {
      const _teams = teamsData.data.getTeamsByOfferingId
        .filter((team) => team.mergedWith === null)
        .map((team) => {
          return {
            id: team.id,
            name: team.name,
            numberOfPartners: team.numberOfPartners,
            code: team.code.code,
          }
        })

      setTeams(_teams)
    })
  }, [getTeamsByOfferingId, offeringId])

  /**
   * Initialize hasNewMessages array
   */
  useEffect(() => {
    // Don't initialize on subsequence loading
    if (reloading || !loading) {
      return
    }

    setHasNewMessages(
      teams.map((team) => {
        return {
          id: `${team.id}`,
          new: false,
        }
      })
    )
  }, [teams, reloading, loading])

  /**
   * Load team info every time the page is visited
   */
  useEffect(() => {
    if (offeringId === -1) {
      return
    }

    setLoading(true)
    handleFetchTeams(offeringId)
      .catch((err) => {
        console.log('err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [offeringId, handleFetchTeams])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the team has confirmed their design
   */
  useEffect(() => {
    if (teamConfirmedDesign.enabled) {
      if (offeringId === null || offeringId === -1) {
        return
      }

      setReloading(true)

      handleFetchTeams(offeringId)
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
          setReloading(false)
        })
      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [dispatchNC, handleFetchTeams, offeringId, teamConfirmedDesign.enabled])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('ChatHost')
    ) {
      setReloading(true)

      handleFetchTeams(offeringId)
        .catch((err) => {
          console.log('err', err)
        })
        .finally(() => {
          setReloading(false)
        })
      dispatchNC({
        type: 'host-merge-teams-executed',
        payload: { in: 'ChatHost' },
      })
    }
  }, [hostMergeTeams, dispatchNC, handleFetchTeams, offeringId])

  /**
   * Handle when we receive a new message
   */
  const handleOnNewMessage = (message) => {
    let t = hasNewMessages.find((h) => h.id === message.receiver)

    if (t) {
      const _hasNewMessages = hasNewMessages.filter(
        (h) => `${h.id}` !== `${message.receiver}`
      )
      t.new = true

      setHasNewMessages([..._hasNewMessages, t])
    }
  }

  /**
   * Reset new message status indicator
   */
  const handleResetNewMessageStatus = (id) => {
    let t = hasNewMessages.find((h) => `${h.id}` === `${id}`)

    if (t) {
      const _hasNewMessages = hasNewMessages.filter(
        (h) => `${h.id}` !== `${id}`
      )
      t.new = false

      setHasNewMessages([..._hasNewMessages, t])
    }
  }

  if (loading) {
    return (
      <Spinner
        as="div"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        variant="secondary"
      />
    )
  }

  return (
    <Tabs defaultActiveKey="1" className="chat-tabs">
      {teams.map((team, teamIndex) => {
        const key = `${teamIndex + 1}`
        const hasNewMessage = hasNewMessages.find(
          (h) => `${h.id}` === `${team.id}`
        )

        return (
          <Tab
            key={key}
            eventKey={key}
            title={key}
            tabClassName={clsx(hasNewMessage && hasNewMessage.new && 'has-msg')}
          >
            <ChatForm
              type={NOTIF_CHAT_HOST}
              receiverName={team.name !== '' ? team.name : `Firm${key}`}
              receiverId={`${team.id}`}
              onNewMessage={handleOnNewMessage}
              onFocus={() => handleResetNewMessageStatus(team.id)}
            />
          </Tab>
        )
      })}
    </Tabs>
  )
}

export default ChatHost
