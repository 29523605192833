import {
  PAY_PER_ENGAGEMENT_HOUR,
  AVG_HOURS_SMALL_ENGAGEMENT_AS,
  HOURS_MODIFIER,
  COST_MODIFIER,
  OVERRUN,
  PHANTOM_CLIENTS,
  PHANTOM_MARGIN,
  SATISFACTION_MODIFIER,
  SATISFACTION_SATISFIED,
  SATISFACTION_DISSATISFIED,
  SATISFACTION_DELIGHTED,
} from './constants'

/**
 * Calculate anticipated hours
 * @param {Object} designParams Firm's design parameters object
 * @param {Number} targetHours Target client's hours
 * @returns
 */
export const calcAnticipatedHours = (designParams, targetHours) => {
  const { infra, empDev, comp, staffing, pracDev, philo, expertise } =
    designParams
  let anticipatedHours =
    targetHours +
    targetHours *
      (HOURS_MODIFIER.infra[infra] +
        HOURS_MODIFIER.empDev[empDev] +
        HOURS_MODIFIER.comp[comp] +
        HOURS_MODIFIER.staffing[staffing] +
        HOURS_MODIFIER.pracDev[pracDev] +
        HOURS_MODIFIER.philo[philo] +
        HOURS_MODIFIER.expertise[expertise])

  if (anticipatedHours < targetHours / 2) {
    anticipatedHours = targetHours / 2
  }

  return parseInt(anticipatedHours)
}

/**
 *
 * @param {Object} designParams Firm's design parameters object
 * @returns {Number} Cost per engagement hour
 */
export const calcCostPerEngagementHour = (designParams) => {
  const { infra, empDev, comp, staffing, pracDev, philo, expertise } =
    designParams

  return parseInt(
    PAY_PER_ENGAGEMENT_HOUR +
      (PAY_PER_ENGAGEMENT_HOUR *
        (COST_MODIFIER.infra[infra] +
          COST_MODIFIER.pracDev[pracDev] +
          COST_MODIFIER.empDev[empDev] +
          COST_MODIFIER.comp[comp] +
          COST_MODIFIER.staffing[staffing] +
          COST_MODIFIER.expertise[expertise] +
          COST_MODIFIER.philo[philo])) /
        100
  )
}

/**
 * Calculate anticipated breakeven point
 * @param {Number} anticipatedHours Anticipated hours
 * @param {Number} costPerHour Cost per engagement hour
 * @returns {Number} anticipated breakeven point
 */
export const calcBreakEvenPoint = (anticipatedHours, costPerHour) => {
  return parseInt(anticipatedHours * costPerHour)
}

/**
 * Generate a random number
 */
export const genRandomNumber = () => {
  return Math.round(Math.random() * 100) + 1
}

/**
 * Determine whether the team blew the budget.
 * This is step 1 in "Run high profile engagement"
 *
 * @param {*} lob1
 * @param {*} lob2
 * @param {*} lob3
 * @param {*} philo
 * @param {*} targetHours
 * @returns
 */
export const didTeamBlowBudget = (lob1, lob2, lob3, philo) => {
  const overrunAs = OVERRUN.as[philo]
  const overrunTS = OVERRUN.ts[philo]
  const overrunCS = OVERRUN.cs[philo]
  const randomNumber = genRandomNumber()
  let overrun
  let overrunLOB1 = -1
  let overrunLOB2 = -1
  let overrunLOB3 = -1
  // const servicesCount = [lob1, lob2, lob3].filter((lob) => lob).length

  // Overrun for LOB1
  if (lob1 === 'Aud') {
    overrunLOB1 = overrunAs
  } else {
    if (lob1 === 'Tax') {
      overrunLOB1 = overrunTS
    } else {
      overrunLOB1 = overrunCS
    }
  }

  overrun = overrunLOB1

  // Overrun for LOB2 if available
  if (lob2) {
    if (lob2 === 'Aud') {
      overrunLOB2 = overrunAs
    } else {
      if (lob2 === 'Tax') {
        overrunLOB2 = overrunTS
      } else {
        overrunLOB2 = overrunCS
      }
    }

    // Overrun for LOB3
    if (lob3) {
      if (lob3 === 'Aud') {
        overrunLOB3 = overrunAs
      } else {
        if (lob3 === 'Tax') {
          overrunLOB3 = overrunTS
        } else {
          overrunLOB3 = overrunCS
        }
      }
    }

    // If an engagement is combined (that is, more than one line of business), use the higher odds of overrun.
    if (lob2 !== '') {
      if (overrun < overrunLOB2) {
        overrun = overrunLOB2
      }
    }
    if (lob3 !== '') {
      if (overrun < overrunLOB3) {
        overrun = overrunLOB3
      }
    }
  }

  // Generate a random number between 1 and 100; if it is lower than the odds of an overrun, then the team blew the budget.
  if (randomNumber < overrun) {
    return true
  }

  return false
}

/**
 * Calculate actual hours after the team has blown the budget.
 * This is step 2 in "Run high profile engagement"
 *
 * @param {Boolean} didBlowBudget Whether the team blew the budget
 * @param {Number} anticipatedHours Anticipated hours
 * @param {Number} lobCount Number of LOBs the target client gives to the firm
 * @returns {Number} Actual hours
 */
export const calcActualHours = (
  didBlowBudget = false,
  anticipatedHours,
  lobCount = 1
) => {
  let actualHours = anticipatedHours

  if (didBlowBudget) {
    if (lobCount === 1) {
      // Add 20% to anticipatedHours
      actualHours = anticipatedHours * 1.2
    } else {
      // Add 10% to anticipatedHours
      actualHours = anticipatedHours * 1.1
    }
  }

  return parseInt(actualHours)
}

/**
 * Calculate engagement profit.
 * This is step 3 in "Run high profile engagement"
 *
 * @param {Number} actualHours Team's actual engagement hours with the target client
 * @param {Number} costPerEngagementHour Cost per engagement hour
 * @param {Number} agreedFee Agreed fee
 * @returns
 */
export const calcEngagementProfit = (
  actualHours,
  costPerEngagementHour,
  agreedFee
) => {
  const costOfEngagement = actualHours * costPerEngagementHour
  const profit = agreedFee - costOfEngagement

  return parseInt(profit)
}

/**
 * Get target client's satisfaction.
 * This is step 4 in "Run high profile engagement"
 *
 * @param {Object} designParams
 * @param {Number} delighted Target client's based delighted value
 * @returns
 */
export const getClientSatisfaction = (designParams, delighted) => {
  let result = ''
  const {
    infra,
    pracDev,
    empDev,
    expertise,
    staffing,
    comp,
    philo,
    pracDevBal,
  } = designParams
  const satisfactionModifier =
    SATISFACTION_MODIFIER.infra[infra] +
    SATISFACTION_MODIFIER.pracDev[pracDev] +
    SATISFACTION_MODIFIER.empDev[empDev] +
    SATISFACTION_MODIFIER.expertise[expertise] +
    SATISFACTION_MODIFIER.staffing[staffing] +
    SATISFACTION_MODIFIER.comp[comp] +
    SATISFACTION_MODIFIER.philo[philo] +
    SATISFACTION_MODIFIER.pracDevBal[pracDevBal]
  const satisfactionFactor = satisfactionModifier + delighted + 50
  const randomNumber = genRandomNumber()

  if (randomNumber < 50) {
    result = SATISFACTION_SATISFIED
  } else {
    if (randomNumber > satisfactionFactor) {
      result = SATISFACTION_DISSATISFIED

      // There's a 50% chance of the client will drop the firm.
    } else {
      result = SATISFACTION_DELIGHTED
    }
  }

  return result
}

/**
 * Determine if the client drops the firm.
 * This is step 5 in "Run high profile engagement"
 *
 * @param {String} satisfaction - One of the three values: satisfied, disatisfied, delighted
 * @returns true if the client drops the firm; Otherwise, return false.
 */
export const doesDropFirm = (
  satisfaction = '',
  pracDevBal,
  engageType = ''
) => {
  let randomNumber = genRandomNumber()
  let drop = false

  if (satisfaction === SATISFACTION_SATISFIED) {
    // There's a 10% chance of the client will drop the firm.
    if (randomNumber <= 10) {
      drop = true
    }

    //
    randomNumber = genRandomNumber()
    if (SATISFACTION_MODIFIER.pracDevBal[pracDevBal] > 5) {
      if (randomNumber < 20) {
        drop = true
      } else {
        drop = false
      }
    } else {
      if (randomNumber < 10) {
        drop = true
      } else {
        drop = false
      }
    }
  } else if (satisfaction === SATISFACTION_DISSATISFIED) {
    // There's a 50% chance of the client will drop the firm.
    if (randomNumber < 50) {
      drop = true
    }
  } else if (satisfaction === SATISFACTION_DELIGHTED) {
    drop = false
  }

  // Note: This is different from the original Flash app
  // JIRA ticket BLS-92
  // Single Year engagements should always end, and not continue with an added line of business
  if (engageType === 'Single Year') {
    drop = true
  }

  return drop
}

/**
 * Determine if the client decides to award a new LOB to the firm.
 * This is step 6 in "Run high profile engagement"
 *
 * @param {Boolean} dropFirm Whether or not the client has dropped the firm
 * @param {String} satisfaction One of the three values: satisfied, disatisfied, delighted
 * @param {Number} pracDevBal Practice Development Balance operating parameter value
 * @param {Number} lobCount Number of LOBs the target client gives to the firm
 * @param {String} engageType Engage type
 * @returns
 */
export const doesAddLOB = (
  dropFirm = false,
  satisfaction,
  pracDevBal,
  lobCount = 1,
  engageType = ''
) => {
  const randomNumber = genRandomNumber()
  let addLOB = false

  // Note: This is different from the original Flash app
  // JIRA ticket BLS-92
  // Single Year engagements should always end, and not continue with an added line of business
  if (engageType === 'Single Year') {
    return false
  }

  // If a client is delighted, there is a 50% chance they will add another line of business.
  if (satisfaction === SATISFACTION_DELIGHTED) {
    // Add 10% if pracDevBal is > 5
    if (SATISFACTION_MODIFIER.pracDevBal[pracDevBal] > 5) {
      if (randomNumber < 60) {
        // A client cannot have more than three lines of business from the firm
        if (lobCount < 3) {
          addLOB = true
        }
      }
    } else {
      if (randomNumber < 50) {
        // A client cannot have more than three lines of business from the firm
        if (lobCount < 3) {
          addLOB = true
        }
      }
    }
  } else if (satisfaction === SATISFACTION_SATISFIED && !dropFirm) {
    if (randomNumber < 20) {
      // A client cannot have more than three lines of business from the firm
      if (lobCount < 3) {
        addLOB = true
      }
    }
  }

  return addLOB
}

/**
 * Get a new LOB if awarded
 *
 * @param {Boolean} awardNewLOB true if a new LOB is awarded
 * @param {String} lob2
 * @param {String} lob3
 * @param {String} targetLob2
 * @param {String} targetLob3
 * @param {Object} lobMix My LOB mix
 * @returns
 */
export const getNewLOB = (
  awardNewLOB = false,
  lob2,
  lob3,
  targetLob2 = '',
  targetLob3 = ''
) => {
  let newLob = ''

  if (awardNewLOB) {
    if (lob2 === null) {
      newLob = targetLob2
    } else if (lob3 === null) {
      newLob = targetLob3
    }
  }

  return newLob
}

export const calcNextYearFee = (
  dropFirm = false,
  awardNewLOB = false,
  clientSatisfaction,
  engageType,
  agreedFee,
  targetHours,
  costPerEngagementHour
) => {
  let nextYearFee = 0

  if (!dropFirm) {
    if (awardNewLOB) {
      nextYearFee = agreedFee + targetHours * costPerEngagementHour * 1.8
    } else {
      if (
        clientSatisfaction === SATISFACTION_DELIGHTED &&
        engageType === 'Annual'
      ) {
        nextYearFee = agreedFee * 1.05
      } else if (
        clientSatisfaction === SATISFACTION_SATISFIED &&
        engageType === 'Annual'
      ) {
        nextYearFee = agreedFee * 1.03
      } else if (
        clientSatisfaction === SATISFACTION_DISSATISFIED &&
        engageType === 'Annual'
      ) {
        nextYearFee = agreedFee
      }
    }
  }

  // console.log(
  //   `calcNextYearFee :: dropFirm (${dropFirm}) :: awardNewLOB (${awardNewLOB}) :: clientSatisfaction (${clientSatisfaction}) :: engageType (${engageType}) :: nextYearFee (${nextYearFee}) :: agreedFee (${agreedFee})`
  // )

  return parseInt(nextYearFee)
}

/**
 * Retrieve results of smaller engagements
 */
export const retrieveSmallerEngagements = (
  asMix,
  tsMix,
  csMix,
  expertise,
  numberOfPartners
) => {
  const numAsClients = Math.round(
    asMix * PHANTOM_CLIENTS.as[expertise] * numberOfPartners
  )
  const numTsClients = Math.round(
    tsMix * PHANTOM_CLIENTS.ts[expertise] * numberOfPartners
  )
  const numCsClients = Math.round(
    csMix * PHANTOM_CLIENTS.cs[expertise] * numberOfPartners
  )
  // 200 * number of AS clients * ((AS margin as found on the Phantom Margin lookup table * 120)-120), where 200 is the average number of hours of a small audit engagement and 120 is a typical breakeven point for dollars per engagement hour
  const randomNumber = Math.random() * 0.01 + 1
  const asProfit = Math.round(
    AVG_HOURS_SMALL_ENGAGEMENT_AS *
      numAsClients *
      (PHANTOM_MARGIN.as[expertise] * PAY_PER_ENGAGEMENT_HOUR -
        PAY_PER_ENGAGEMENT_HOUR) *
      randomNumber
  )

  const tsProfit = Math.round(
    AVG_HOURS_SMALL_ENGAGEMENT_AS *
      numTsClients *
      (PHANTOM_MARGIN.ts[expertise] * PAY_PER_ENGAGEMENT_HOUR -
        PAY_PER_ENGAGEMENT_HOUR) *
      randomNumber
  )
  const csProfit = Math.round(
    AVG_HOURS_SMALL_ENGAGEMENT_AS *
      numCsClients *
      (PHANTOM_MARGIN.cs[expertise] * PAY_PER_ENGAGEMENT_HOUR -
        PAY_PER_ENGAGEMENT_HOUR) *
      randomNumber
  )
  const totalProfits = asProfit + tsProfit + csProfit

  return [
    numAsClients,
    numTsClients,
    numCsClients,
    asProfit,
    tsProfit,
    csProfit,
    totalProfits,
  ]
}

/**
 * Check if the client has gone out of business
 *
 * @param {Number} stability Client's stability
 * @returns true if the client has gone out of business.
 */
export const isOutOfBusiness = (stability) => {
  const randomNumber = genRandomNumber()

  if (randomNumber > stability) {
    return true
  }

  return false
}
