import { useEffect, useState, useCallback } from 'react'
import Stack from 'react-bootstrap/Stack'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import clsx from 'clsx'

import HelpButton from '../HelpButton'

import { useNotification } from '../../hooks/useNotification'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { useTeamsByOfferingId } from '../../hooks/useTeamsByOfferingId'
import { useAuth } from '../../hooks/useAuth'
import { useTeam } from '../../hooks/useTeam'
import { useCognito } from '../../hooks/useCognito'
import { useAppState } from '../../hooks/useAppState'
import { useStats } from '../../hooks/useStats'

import { ReactComponent as RefreshIcon } from '../../images/icons/refresh-icon.svg'
import plusIcon from '../../images/icons/plus-icon.svg'

export default function HostTeamsAccessCode() {
  const { getTeamsByOfferingId } = useTeamsByOfferingId()
  const { user, getUserCustomAttribute } = useAuth()
  const { dispatch: notificationDispatch } = useNotification()
  const {
    state: { teamConfirmedDesign, hostMergeTeams },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const { refreshTeamCode, updateTeamCodeInCognito, addTeam } = useTeam()
  const { cognitoAddUser } = useCognito()
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(true)
  const { updateSimStats } = useStats()
  const [refreshTeamCodeId, setRefreshTeamCodeId] = useState(-1)
  const [addTeamInProgress, setAddTeamInProgress] = useState(false)
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()

  const handleFetchTeams = useCallback(async () => {
    return getTeamsByOfferingId(offeringId).then((teamData) => {
      const t = teamData.data.getTeamsByOfferingId
        .filter((team) => team.mergedWith === null)
        .map((team) => {
          return {
            id: team.id,
            name: team.name,
            numberOfPartners: team.numberOfPartners,
            code: team.code.code,
          }
        })

      setTeams(t)
    })
  }, [getTeamsByOfferingId, offeringId])

  /**
   * Load team info every time the page is visited
   */
  useEffect(() => {
    if (offeringId === -1) {
      return
    }

    setLoading(true)
    handleFetchTeams(offeringId).finally(() => {
      setLoading(false)
    })
  }, [offeringId, handleFetchTeams])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the team has confirmed their design
   */
  useEffect(() => {
    if (teamConfirmedDesign.enabled) {
      handleFetchTeams(offeringId)
      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [dispatchNC, handleFetchTeams, teamConfirmedDesign.enabled, offeringId])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('HostTeamsAccessCode')
    ) {
      setLoading(true)
      handleFetchTeams(offeringId).finally(() => {
        setLoading(false)
      })
      dispatchNC({
        type: 'host-merge-teams-executed',
        payload: {
          in: 'HostTeamsAccessCode',
        },
      })
    }
  }, [hostMergeTeams, dispatchNC, handleFetchTeams, offeringId])

  /**
   * Refresh team access code
   */
  const handleRefreshTeamCode = async (teamId, teamIndex) => {
    if (user === null) {
      console.log('Unable to refresh team code :: Unknown host user')
      return
    }
    if (offeringId === null || offeringId === -1) {
      console.log('Unable to refresh team code :: offering id not found')
      return
    }
    if (teamId === null) {
      console.log('Unable to refresh team code :: team id not found')
      return
    }

    const hostNumber = getUserCustomAttribute(user, 'number')

    try {
      setRefreshTeamCodeId(teamId)

      // Refresh team access code in database
      const refreshTeamCodeData = await refreshTeamCode(
        hostNumber,
        offeringId,
        teamId
      )
      const newAccessCode = refreshTeamCodeData.data.refreshTeamCode.newCode

      // Update team access code in Cognito
      await updateTeamCodeInCognito('Team', teamId, newAccessCode)

      // Refetch the teams data
      handleFetchTeams(offeringId)

      // Show notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Firm Access Code Updated`,
          body: `New access code for Firm${teamIndex} is <strong>${newAccessCode}</strong>.`,
          time: new Date().toLocaleTimeString(),
          type: 'success',
          op: { delay: 5000, autohide: true },
        },
      })
    } catch (err) {
      console.error(err)
    } finally {
      setRefreshTeamCodeId(-1)
    }
  }

  /**
   * Add team
   */
  const handleAddNewTeam = async () => {
    if (offeringId === null || offeringId === -1) {
      console.log('Unable to refresh team code :: offering id not found')
      return
    }

    setAddTeamInProgress(true)

    try {
      // Add a new row in the teams table to show the spinner
      setTeams([
        ...teams,
        {
          id: null,
          name: `Firm${teams.length + 1}`,
          numberOfPartners: null,
          code: null,
        },
      ])
      const hostNumber = getUserCustomAttribute(user, 'number')

      // Generate team code and add this team into the database
      const newTeam = await addTeam(offeringId, hostNumber)

      // Create a new user for this team in Cognito
      const newTeamAccessCode = newTeam.data.addTeam.code.code
      const newTeamUserId = newTeam.data.addTeam.id
      await cognitoAddUser(newTeamAccessCode, 'Team', newTeamUserId)

      // Update the Sim stats
      try {
        await updateSimStats(offeringId, 1)

        // Show the success notification
        notificationDispatch({
          type: 'ADD_TOAST',
          payload: {
            title: `Add Team`,
            body: `New team added successfully.`,
            time: new Date().toLocaleTimeString(),
            type: 'success',
            op: { delay: 5000, autohide: true },
          },
        })
      } catch (err) {
        console.log('Unable to update Sim stats', err)

        // Show the success notification
        notificationDispatch({
          type: 'ADD_TOAST',
          payload: {
            title: `Add Team`,
            body: `New team added successfully; however, there was an issue updating the simulation stats. This won't affect the new team's ability to participate in the simulation.`,
            time: new Date().toLocaleTimeString(),
            type: 'alert',
            op: { delay: 5000, autohide: true },
          },
        })
      }
    } catch (err) {
      console.log('Unable to add a new team', err)

      // Show the error notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Add Team`,
          body: `Unable to add a new team.`,
          time: new Date().toLocaleTimeString(),
          type: 'danger',
          op: { delay: 5000, autohide: true },
        },
      })
    } finally {
      // Refetch the team data
      handleFetchTeams()

      setAddTeamInProgress(false)
    }
  }

  return (
    <div>
      <Stack direction="horizontal" gap={2}>
        <h2 className="text-primary h5 fw-bold mb-0">Teams</h2>
        <HelpButton
          title="Teams"
          content="<p>Distribute the access codes for each team of participants to the facilitation team before the simulation runs. All team members (and Coach, if applicable) share the same code.</p><p>If the number of participants has grown enough to warrant adding another team to the active simulation, click <em><strong>Add Team</strong></em> to do that here.</p>"
        />
      </Stack>
      <Table responsive className="w-100 mt-4 align-middle run-table">
        <thead className="bg-gray-100">
          <tr>
            <th>Firm Name</th>
            <th width={150}>Size (people)</th>
            <th width={130}>Access Code</th>
            <th width={20}></th>
          </tr>
        </thead>
        <tbody>
          {/**
           * Empty row with a little bit of paddings to fill in the gap
           */}
          <tr>
            <td colSpan={3} className="py-1"></td>
          </tr>
          {loading && (
            <tr>
              <td>
                <Spinner
                  as="div"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                />
              </td>
              <td>
                <Spinner
                  as="div"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                />
              </td>
              <td>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                />
              </td>
              <td className="px-0"></td>
            </tr>
          )}
          {teams.map((team, teamIndex) => (
            <tr key={`${team.name}-${teamIndex}`}>
              <td>{team.name !== '' ? team.name : `Firm${teamIndex + 1}`}</td>
              <td>
                {team.numberOfPartners === null
                  ? showSpinner()
                  : team.numberOfPartners}
              </td>
              <td>
                {team.code === null
                  ? showSpinner()
                  : team.code !== ''
                  ? team.code
                  : '--'}
              </td>
              <td className="px-0">
                {team.code !== null && (
                  <Button
                    variant="outline"
                    className="p-0"
                    disabled={refreshTeamCodeId === team.id}
                    onClick={() =>
                      handleRefreshTeamCode(team.id, teamIndex + 1)
                    }
                  >
                    <RefreshIcon
                      width="24"
                      height="24"
                      fill="currentColor"
                      className={clsx(
                        refreshTeamCodeId === team.id && 'rotateClockwise'
                      )}
                    />
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <Button
                variant="outline"
                className="p-0"
                disabled={addTeamInProgress}
                onClick={handleAddNewTeam}
              >
                <img src={plusIcon} alt="plus" width={20} height={20} />
                <span className="align-middle ms-1 text-gray-300">
                  Add Team
                </span>
              </Button>
            </td>
          </tr>
        </tfoot>
      </Table>
    </div>
  )
}

const showSpinner = () => {
  return (
    <Spinner
      as="span"
      animation="border"
      size="sm"
      role="status"
      aria-hidden="true"
      variant="secondary"
    />
  )
}
