import { useEffect, useState, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import clsx from 'clsx'
import HelpButton from '../../components/HelpButton'
import arrowIcon from '../../images/icons/arrow-icon.svg'
import loadingIcon from '../../images/icons/loading-icon.svg'
import './styles.css'

import { useAuth } from '../../hooks/useAuth'

export default function Home() {
  const navigate = useNavigate()
  const location = useLocation()
  const [accessCode, setAccessCode] = useState('')
  const { user, signIn } = useAuth()
  const [signingIn, setSigningIn] = useState(false)
  const [errorText, setErrorText] = useState('')
  const [isInvalid, setIsInvalid] = useState(false)
  const accessCodeInputRef = useRef(null)

  // Reset
  useEffect(() => {
    if (accessCodeInputRef.current) {
      setAccessCode('')
      accessCodeInputRef.current.focus()
    }
  }, [location.key])

  const handleSignIn = async (event) => {
    event.preventDefault()
    event.stopPropagation()

    setSigningIn(true)
    try {
      const u = await signIn(accessCode, accessCode)

      // Clean up the access code input box
      setAccessCode('')

      // If it's a Host user, redirect them to the Host Dashboard page
      if (
        u.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Host'
        )
      ) {
        navigate('/host/dashboard', { replace: true })
      } else {
        setSigningIn(false)
      }
    } catch (err) {
      // console.log('Home :: err', err, err.name, 'code:', err.code)
      if (err.name && err.name === 'UserNotFoundException') {
        setErrorText('Invalid access code!')
      }
      setIsInvalid(true)
      setSigningIn(false)
    }
  }

  const handleAccessCodeInput = (e) => {
    setAccessCode(e.target.value)
    setIsInvalid(false)
  }

  return (
    <React.Fragment>
      <Container fluid className="p-0 home-banner">
        <Container>
          <Row className="mb-4">
            <Col xs={6} className="pt-5">
              <h1>
                Welcome to <br /> Bottom Line!
              </h1>
            </Col>
          </Row>
        </Container>
        <div className="home-cloud-0"></div>
        <div className="home-cloud-1"></div>
        <div className="home-road-sign"></div>
      </Container>

      <Container as="main" className="pb-5 main">
        <Row className="mb-4">
          <Col sm={6} md={5}>
            <h2 className="text-primary fw-bold">We’re SO Glad You’re Here!</h2>
            <p>
              In this simulation, you’ll work with your team to design a firm,
              bid on prospective clients, and execute engagements as profitably
              as possible. This all happens in Visionville, a mid-sized,
              midwestern American city.
            </p>
            <p>
              Be aware that Bottom Line moves quickly! You’ve just been promoted
              to partner, and you’ve got your work cut out for you. Ready to dig
              in?
            </p>
            {!user && (
              <Form className="mt-5" noValidate onSubmit={handleSignIn}>
                <Form.Group
                  as={Row}
                  className="align-items-center justify-content-center position-relative"
                >
                  <Stack direction="horizontal" gap={3} className="mb-3">
                    <div className="w-100">
                      <Form.Control
                        ref={accessCodeInputRef}
                        type="password"
                        id="accessCode"
                        placeholder={'Input your access code...'}
                        aria-describedby="accessHelpBlock"
                        value={accessCode}
                        isInvalid={isInvalid}
                        isValid={user && !isInvalid}
                        autoFocus
                        onChange={handleAccessCodeInput}
                      />
                      <Form.Control.Feedback type="invalid" tooltip>
                        {errorText}
                      </Form.Control.Feedback>
                    </div>

                    <Button
                      variant="light"
                      className="login-button"
                      disabled={signingIn}
                      onClick={handleSignIn}
                    >
                      <img
                        src={signingIn ? loadingIcon : arrowIcon}
                        alt={signingIn ? 'loading' : 'arrow'}
                        width="38"
                        height="38"
                        className={clsx(signingIn && 'rotateClockwise')}
                      />
                    </Button>
                    <HelpButton
                      title="Access Code"
                      content="<p>If you don’t have an access code, speak to the simulation’s Host. If you <strong>are</strong> the simulation’s Host, speak to the Program Administrator.</p>"
                    />
                  </Stack>
                </Form.Group>
              </Form>
            )}
          </Col>
          <Col
            sm={{ span: 6 }}
            md={{ span: 6, offset: 1 }}
            className="text-center"
          >
            <div className="ratio ratio-16x9 mb-2 mt-4 mt-sm-0">
              <iframe
                src="https://player.vimeo.com/video/720637133?h=d3c5cec5ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                title="Welcome to RSM &amp; RSM BLS"
              ></iframe>
            </div>
            <small>
              To learn more, check out this video from RSM leadership!
            </small>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  )
}
