export const formatCurrency = (amount) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencySign: 'accounting',
    signDisplay: 'auto',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(amount)
}

/**
 * Return satisfaction descriptive text of a given number between 1-100
 * @param {Number} satisfaction Satisfaction number
 * @returns A+, A, B+, B, C+, C, D+, D, F, or --
 */
export const getLeaderboardSatisfactionText = (satisfaction) => {
  if (satisfaction === null) {
    return '--'
  }

  if (satisfaction >= 95) {
    return 'A+'
  } else if (satisfaction >= 85 && satisfaction < 95) {
    return 'A'
  } else if (satisfaction >= 75 && satisfaction < 85) {
    return 'B+'
  } else if (satisfaction >= 65 && satisfaction < 75) {
    return 'B'
  } else if (satisfaction >= 55 && satisfaction < 65) {
    return 'C+'
  } else if (satisfaction >= 45 && satisfaction < 55) {
    return 'C'
  } else if (satisfaction >= 35 && satisfaction < 45) {
    return 'D+'
  } else if (satisfaction >= 25 && satisfaction < 35) {
    return 'D'
  } else {
    return 'F'
  }
}
