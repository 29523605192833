import clsx from 'clsx'
import Spinner from 'react-bootstrap/Spinner'
import './styles.css'

const StatsWidget = ({ loading = false, value, label, type }) => {
  return (
    <div
      className={clsx(
        'stats-widget-wrapper',
        type === '0' && 'tlp',
        type === '1' && 'tpd',
        type === '2' && 'firm',
        type === '3' && 'hp',
        type === '4' && 'hcs',
        type === '5' && 'hes'
      )}
    >
      <i className="ratio ratio-1x1 "></i>
      {loading ? (
        <Spinner
          as="div"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          variant="secondary"
        />
      ) : (
        <h1>{value}</h1>
      )}
      <p>{label}</p>
    </div>
  )
}
export default StatsWidget
