import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getMyActiveClients as getMyActiveClientsQuery } from '../graphql/queries'
import { updateClient as updateClientMutation } from '../graphql/mutations'

export const useClient = () => {
  const getMyActiveClients = useCallback((teamId, year, clientId) => {
    let input = { teamId, year }

    if (clientId !== undefined) {
      input = { ...input, clientId }
    }
    return API.graphql(graphqlOperation(getMyActiveClientsQuery, { ...input }))
  }, [])

  const updateClient = useCallback((input) => {
    return API.graphql(graphqlOperation(updateClientMutation, input))
  }, [])

  return {
    getMyActiveClients,
    updateClient,
  }
}
