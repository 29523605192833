import { useState } from 'react'
import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import CloseButton from 'react-bootstrap/CloseButton'

import { ChatForm } from '../Chat'
import { useAuth } from '../../hooks/useAuth'
import { NOTIF_CHAT_TEAM } from '../../utils/constants'

const ChatFloat = () => {
  const [show, setShow] = useState(false)
  const [hasNewMessage, setHasNewMessage] = useState(false)
  const { userId } = useAuth()

  const toggleShow = () => {
    setShow(!show)
    setHasNewMessage(false)
  }
  const handleHide = () => setShow(false)

  const handleOnNewMessage = () => {
    setHasNewMessage(true)
  }

  return (
    <>
      <div className={clsx('chat-float-panel', show && 'show')}>
        <Stack direction="horizontal" className="mb-2 chat-float-header">
          <h3 className="mb-0">Support Chat</h3>
          <CloseButton onClick={handleHide} />
        </Stack>
        <ChatForm
          type={NOTIF_CHAT_TEAM}
          receiverName="program Host"
          receiverId={`${userId}`}
          onNewMessage={handleOnNewMessage}
          onFocus={() => setHasNewMessage(false)}
        />
      </div>
      <button
        className={clsx('chat-float-button', hasNewMessage && 'has-new-msg')}
        onClick={() => toggleShow()}
      ></button>
    </>
  )
}

export default ChatFloat
