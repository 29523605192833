import { useCallback, useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import TeamDataView from '../TeamDataView'

import { useAppState } from '../../hooks/useAppState'
import { useLeader } from '../../hooks/useLeader'
import { useBids } from '../../hooks/useBids'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { useTeam } from '../../hooks/useTeam'

import {
  BID_STATUS_PENDING,
  BID_STATUS_WON,
  NOTIF_CH_AWARD_BID,
} from '../../utils/constants'
import { formatCurrency } from '../../utils/utils'

const LeaderAwardTargetList = () => {
  const {
    state: {
      me,
      offering: { offeringId },
      year,
      rtNotification,
    },
  } = useAppState()
  const {
    state: { leaderAwardRefetchTeam },
    sendRTNotification,
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const { getHighProfileEngagements } = useLeader()
  const [engagements, setEngagements] = useState([])
  const [loading, setLoading] = useState(false)
  const [targetId, setTargetId] = useState(null)
  const [awardingBidId, setAwardingBidId] = useState(null)
  const [teamId, setTeamId] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [showSnapshotModal, setShowSnapshotModal] = useState(false)
  const [teamID, setTeamID] = useState(-1)
  const { awardBid } = useBids()

  const handleAwardBid = async (
    bidId,
    targetId,
    winningReason = '',
    teamId
  ) => {
    if (offeringId === -1 || offeringId === null || year === null) {
      console.log('Award bid failed :: Unknown offering id/year')
      return
    }

    try {
      const curEngagement = engagements.find(
        (engagement) => engagement.id === targetId
      )
      const awardingTeam = curEngagement.team.find((t) => t.teamId === teamId)

      // Award the bid
      await awardBid(
        offeringId,
        bidId,
        year,
        teamId,
        targetId,
        curEngagement.lob1,
        winningReason,
        awardingTeam.agreedFee
      )

      // Send real-time notification
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId }),
        updater: me,
        type: NOTIF_CH_AWARD_BID,
      }
      sendRTNotification(statusInput)

      // Refetch the high profile engagements data
      await fetchHighProfileEngagements()
    } catch (err) {
      console.log('Award bid failed', err)
    } finally {
      setAwardingBidId(null)
    }
  }

  const fetchHighProfileEngagements = useCallback(async () => {
    return getHighProfileEngagements(offeringId, year).then(
      (engagementsData) => {
        setEngagements(engagementsData.data.getHighProfileEngagements)
      }
    )
  }, [getHighProfileEngagements, offeringId, year])

  useEffect(() => {
    if (offeringId === -1 || offeringId === null || year === null) {
      return
    }

    if (engagements.length === 0) {
      setLoading(true)
    }

    fetchHighProfileEngagements(offeringId, year)
      .catch((err) => {
        console.log('Unable to fetch high profile engagements', err)
      })
      .finally(() => {
        if (engagements.length === 0) {
          setLoading(false)
        }
      })
    // Be careful here. Don't add engagements.length.
  }, [fetchHighProfileEngagements, offeringId, year])

  /**
   * Refetch the high profile engagments data every time a team submits a bid
   */
  useEffect(() => {
    if (leaderAwardRefetchTeam) {
      fetchHighProfileEngagements().finally(() => {
        dispatchNC({ type: 'leader-award-refetch-team-executed' })
      })
    }
  }, [leaderAwardRefetchTeam, fetchHighProfileEngagements, dispatchNC])

  if (loading) {
    return (
      <p>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          variant="secondary"
        />{' '}
        Loading engagementments...
      </p>
    )
  }

  return (
    <>
      <Feedback
        show={showModal}
        setShowModal={setShowModal}
        onSubmit={async (winningReason) =>
          handleAwardBid(awardingBidId, targetId, winningReason, teamId)
        }
      />
      <Snapshot
        show={showSnapshotModal}
        teamID={teamID}
        setShowSnapshotModal={setShowSnapshotModal}
      />
      <Accordion alwaysOpen>
        {engagements.map((engagement) => (
          <Accordion.Item
            eventKey={engagement.displayName}
            key={engagement.displayName}
          >
            <Accordion.Header>
              {engagement.displayName}
              {engagement.engageYear === year && (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                  className="ms-1"
                />
              )}
            </Accordion.Header>
            <Accordion.Body>
              <Row>
                <Col xs={12} md={6}>
                  <h3 className="text-success fw-bold">{engagement.name}</h3>
                  <ul className="list-unstyled">
                    <li>
                      <strong>Industry:</strong> {engagement.industry}
                    </li>
                    <li>
                      <strong>Immediate Need:</strong>{' '}
                      {engagement.immediateNeed}
                    </li>
                    <li>
                      <strong>Engagement Size:</strong> {engagement.engageSize}
                    </li>
                    <li>
                      <strong>Recurrence:</strong> {engagement.engageType}
                    </li>
                  </ul>
                </Col>
                <Col xs={12} md={6}>
                  <h5 className="text-primary fw-bold mt-2">
                    How Do I Pick the Winner?
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: engagement.winnerDetail,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Table
                    responsive
                    className="w-100 mt-4 align-middle run-table"
                  >
                    <thead className="bg-gray-100">
                      <tr>
                        <th width="30%">Firm Name</th>
                        <th width="30%">Firm Snapshot</th>
                        <th width="20%">Bid Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        // Empty row with a little bit of paddings to fill in the gap
                      }
                      <tr>
                        <td className="py-1"></td>
                      </tr>
                      {engagement.team.map((team, teamIndex) => (
                        <tr key={`${team.teamName}-${teamIndex}`}>
                          <td>{team.teamName}</td>
                          <td>
                            <ViewSnapshot
                              teamId={team.teamId}
                              setTeamID={setTeamID}
                              setShowSnapshotModal={setShowSnapshotModal}
                            />
                          </td>
                          <td>{formatCurrency(team.agreedFee)}</td>
                          <td>
                            <Status
                              teamStatus={team.status}
                              bidId={team.bidId}
                              targetId={engagement.id}
                              awardingBidId={awardingBidId}
                              setAwardingBidId={setAwardingBidId}
                              setTargetId={setTargetId}
                              teamId={team.teamId}
                              setTeamId={setTeamId}
                              showModal={showModal}
                              setShowModal={setShowModal}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  )
}

const Status = ({
  teamStatus,
  bidId,
  targetId,
  awardingBidId,
  showModal,
  setAwardingBidId,
  setTargetId,
  teamId,
  setTeamId,
  setShowModal,
}) => {
  const handleClick = () => {
    setTeamId(teamId)
    setTargetId(targetId)
    setAwardingBidId(bidId)
    setShowModal(true)
  }

  return teamStatus === BID_STATUS_PENDING ? (
    <Button
      variant="outline-success rounded-pill"
      size="sm"
      className="w-100"
      disabled={showModal}
      onClick={handleClick}
    >
      {showModal && awardingBidId === bidId && (
        <Spinner
          as="div"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          variant="success"
        />
      )}{' '}
      Award
    </Button>
  ) : teamStatus === BID_STATUS_WON ? (
    <Button
      variant="outline-primary rounded-pill"
      size="sm"
      className="w-100"
      disabled
    >
      Engagement Awarded
    </Button>
  ) : (
    <Button
      variant="outline-secondary rounded-pill"
      size="sm"
      className="w-100"
      disabled
    >
      Award
    </Button>
  )
}

const initialState = {
  Price: false,
  'Name and/or tagline': false,
  'Practice development setting': false,
  'Industry focus': false,
  'Existing clients': false,
  'Geographic focus setting': false,
  Size: false,
  'Infrastructure setting': false,
  'Balanced settings overall': false,
  'Line of business focus': false,
  'Employee development setting': false,
  Other: false,
}

const Feedback = ({ show, setShowModal, onSubmit }) => {
  const [feedback, setFeedback] = useState(initialState)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleClose = () => {
    setShowModal(false)
  }

  const handleSubmit = async () => {
    const winningReason = Object.keys(
      Object.fromEntries(
        Object.entries(feedback).filter((keyValue) => keyValue[1])
      )
    ).join(', ')

    if (onSubmit) {
      setIsSubmitting(true)
      try {
        await onSubmit(winningReason)
      } catch (err) {
        console.log('Award bid failed', err)
      } finally {
        setIsSubmitting(false)
      }
    }
    setShowModal(false)
  }

  const handleShow = () => {
    setIsSubmitting(false)
    setFeedback(initialState)
  }

  const handleFeedbackChange = (e) => {
    setFeedback({ ...feedback, [e.target.id]: !feedback[e.target.id] })
  }

  return (
    <Modal
      centered
      show={show}
      onShow={handleShow}
      onHide={handleClose}
      // backdrop="static"
      // keyboard={false}
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      size={'lg'}
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="h5 text-success fw-bold">Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>
              It's important that we offer feedback to teams regarding{' '}
              <strong>why</strong> one firm won the work!
            </p>
            <p>
              Select all boxes below that describe <strong>why</strong> you
              chose the winner, and then click <strong>Submit</strong>.
            </p>
          </Col>
        </Row>
        <Form.Group
          as={Row}
          controlId={'award-feedback-group'}
          className="mt-5"
        >
          <Form.Label column visuallyHidden>
            Feedback
          </Form.Label>
          <Col>
            <Row>
              <Col>
                <Form.Check
                  id="Price"
                  label="Price"
                  checked={feedback.Price}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Name and/or tagline"
                  label="Name and/or tagline"
                  checked={feedback['Name and/or tagline']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Practice development setting"
                  label="Practice development setting"
                  checked={feedback['Practice development setting']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  id="Industry focus"
                  label="Industry focus"
                  checked={feedback['Industry focus']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Existing clients"
                  label="Existing clients"
                  checked={feedback['Existing clients']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Geographic focus setting"
                  label="Geographic focus setting"
                  checked={feedback['Geographic focus setting']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  id="Size"
                  label="Size"
                  checked={feedback.Size}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Infrastructure setting"
                  label="Infrastructure setting"
                  checked={feedback['Infrastructure setting']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Balanced settings overall"
                  label="Balanced settings overall"
                  checked={feedback['Balanced settings overall']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Check
                  id="Line of business focus"
                  label="Line of business focus"
                  checked={feedback['Line of business focus']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Employee development setting"
                  label="Employee development setting"
                  checked={feedback['Employee development setting']}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
              <Col>
                <Form.Check
                  id="Other"
                  label="Other"
                  checked={feedback.Other}
                  onChange={handleFeedbackChange}
                  className="mb-3"
                />
              </Col>
            </Row>
          </Col>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-success"
          className="rounded-pill"
          disabled={isSubmitting}
          onClick={handleSubmit}
        >
          {isSubmitting && (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="success"
            />
          )}{' '}
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const ViewSnapshot = ({ teamId, setShowSnapshotModal, setTeamID }) => {
  const handleClick = () => {
    setShowSnapshotModal(true)
    setTeamID(teamId)
  }

  return (
    <Button
      variant="outline-primary rounded-pill"
      size="sm"
      onClick={handleClick}
    >
      View Snapshot
    </Button>
  )
}

const Snapshot = ({ show, setShowSnapshotModal, teamID }) => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const [teamData, setTeamData] = useState([])
  const [loading, setLoading] = useState(true)
  const { getTeamByOfferingId } = useTeam()

  const handleFetchTeams = useCallback(async () => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    return getTeamByOfferingId(offeringId, teamID).then((tData) => {
      setTeamData(tData.data.getTeamByOfferingId)
    })
  }, [getTeamByOfferingId, offeringId, teamID])

  useEffect(() => {
    if (teamID === -1) {
      return
    }
    setLoading(true)
    handleFetchTeams(teamID).finally(() => {
      setLoading(false)
    })
  }, [teamID, handleFetchTeams])

  const handleClose = () => {
    setShowSnapshotModal(false)
  }

  const renderTDV = () => {
    return <TeamDataView team={teamData} initialView="sliders" />
  }
  return (
    <Modal
      centered
      show={show !== false}
      onHide={handleClose}
      dialogClassName="modal-90w"
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      size={'xl'}
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="h5 fw-bold">Team Snapshot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          renderTDV()
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-success"
          className="rounded-pill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LeaderAwardTargetList
