import { forwardRef, useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'
import clsx from 'clsx'

import { useAppState } from '../../hooks/useAppState'
import { useAuth } from '../../hooks/useAuth'

import './style.css'
import logo from '../../images/logos/bottom-line-sim-logo.svg'
import mapIcon from '../../images/icons/map-icon.svg'
import leaderboardIcon from '../../images/icons/leaderboard-icon.svg'
import threeDotsIcon from '../../images/icons/dots-icon.svg'
import twoPeopleIcon from '../../images/icons/two-people-icon.svg'
import moneyIcon from '../../images/icons/money-icon.svg'
import cogIcon from '../../images/icons/cog-icon.svg'
import helpIcon from '../../images/icons/help-icon.svg'
import lockIcon from '../../images/icons/lock-icon.svg'
import barChartIcon from '../../images/icons/bar-chart-icon.svg'
import securityIcon from '../../images/icons/security-icon.svg'
import logoutIcon from '../../images/icons/logout-icon.svg'

export default function Header() {
  const [show, setShow] = useState(false)
  const location = useLocation()
  const { user, isOwner, isTeam, isLeader, isHost, signOut } = useAuth()
  const {
    state: { design },
  } = useAppState()
  const navigate = useNavigate()

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // Close the Offcanvas when the url has changed
  useEffect(() => {
    handleClose()
  }, [location.pathname])

  // Sign out
  const handleSignout = () => {
    try {
      signOut(() => {
        navigate('/', { replace: true })
      })
    } catch (err) {
      console.log('signout', err)
    }
  }

  return (
    <Navbar bg="white" expand="lg" sticky="top" className="py-0 shadow-sm">
      <Container className="h-100">
        <Nav.Link as={NavLink} to="/" className="p-0">
          <Navbar.Brand>
            <img
              src={logo}
              width="125"
              height="21.41"
              className="d-inline-block align-middle"
              alt="Bottom Line Simulation"
            />
          </Navbar.Brand>
        </Nav.Link>
        {user && (
          <>
            {isTeam && (
              <div className="vr bg-gray-400 ms-2 me-3 align-self-center navbar-vertical-rule d-none d-lg-block" />
            )}
            <Navbar.Toggle
              aria-controls="blsOffcanvasNavbar"
              className="p-0 border-0"
              onClick={handleShow}
            />
            <Navbar.Offcanvas
              id="blsOffcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              placement="end"
              show={show}
              onHide={handleClose}
            >
              <Offcanvas.Header closeButton>
                <img
                  src={logo}
                  width="125"
                  height="21.41"
                  className="d-inline-block align-middle"
                  alt="Bottom Line Simulation"
                />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav>
                  {isOwner && (
                    <>
                      <Nav.Link as={NavLink} to="/admin/stats">
                        <img
                          src={barChartIcon}
                          alt="bar chart"
                          width={18}
                          height={16}
                          className="me-2"
                        />
                        Bottom Line Stats
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/admin/access-codes">
                        <img
                          src={securityIcon}
                          alt="security"
                          width={18}
                          height={16}
                          className="me-2"
                        />
                        Host Access Codes
                      </Nav.Link>
                    </>
                  )}
                  {!isOwner && (
                    <Nav.Link as={NavLink} to="/explore">
                      <img
                        src={mapIcon}
                        alt="map"
                        width={20.25}
                        height={18}
                        className="me-2"
                      />
                      Explore Visionville
                    </Nav.Link>
                  )}
                  {isTeam && (
                    <>
                      <Nav.Link as={NavLink} to="/design">
                        <strong>Design Your Firm</strong>
                      </Nav.Link>
                      <Nav.Link
                        as={NavLink}
                        to="run"
                        className={clsx(!design.confirm && 'text-gray-300')}
                        disabled={!design.confirm}
                      >
                        {!design.confirm && (
                          <img
                            src={lockIcon}
                            alt="lock"
                            width={16.69}
                            height={20}
                            className="me-2"
                          />
                        )}
                        <strong>Run Your Firm</strong>
                      </Nav.Link>
                    </>
                  )}
                  {!isOwner && (
                    <>
                      <Nav.Link as={NavLink} to="/leaderboards">
                        <img
                          src={leaderboardIcon}
                          alt="leaderboard"
                          width={16.66}
                          height={16}
                          className="me-2"
                        />
                        Leaderboards
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/teams">
                        <img
                          src={twoPeopleIcon}
                          alt="two people"
                          width={16.66}
                          height={16}
                          className="me-2"
                        />
                        View Teams
                      </Nav.Link>
                    </>
                  )}
                  {isLeader && (
                    <Nav.Link as={NavLink} to="/leader/award">
                      <img
                        src={moneyIcon}
                        alt="money"
                        width={16.66}
                        height={16}
                        className="me-2"
                      />
                      Award Contracts
                    </Nav.Link>
                  )}
                  {isHost && (
                    <Nav.Link as={NavLink} to="/host/dashboard">
                      <img
                        src={cogIcon}
                        alt="cog"
                        width={16.66}
                        height={16}
                        className="me-2"
                      />
                      Host Dashboard
                    </Nav.Link>
                  )}
                  {!isOwner && (
                    <Nav.Link as={NavLink} to="/help">
                      <img
                        src={helpIcon}
                        alt="cog"
                        width={16.66}
                        height={16}
                        className="me-2"
                      />
                      Help / FAQs
                    </Nav.Link>
                  )}
                  <Nav.Link onClick={handleSignout}>
                    <img
                      src={logoutIcon}
                      alt="logout"
                      width={16}
                      height={16}
                      className="me-2"
                    />
                    Log out
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <Navbar
              id="bls-navbar-nav"
              className={clsx(
                'h-100 flex-grow-1 d-none d-lg-flex p-0',
                !isTeam && 'justify-content-end'
              )}
            >
              {isTeam && (
                <Nav className="me-auto h-100 align-items-center">
                  <Stack direction="horizontal" gap={0}>
                    <Nav.Link as={NavLink} to="design">
                      <strong>Design Your Firm</strong>
                    </Nav.Link>
                    <Nav.Link
                      as={NavLink}
                      to="/run"
                      className="position-relative"
                      disabled={!design.confirm}
                    >
                      {!design.confirm && (
                        <img
                          src={lockIcon}
                          alt="lock"
                          width={16.69}
                          height={20}
                          className="header-run-lock-icon"
                        />
                      )}
                      <strong>Run Your Firm</strong>
                    </Nav.Link>
                  </Stack>
                </Nav>
              )}
              <Nav className="h-100 align-items-center">
                <Stack direction="horizontal">
                  {isOwner ? (
                    <>
                      <Nav.Link as={NavLink} to="/admin/stats">
                        <img
                          src={barChartIcon}
                          alt="bar chart"
                          width={18}
                          height={16}
                          className="me-2"
                        />
                        Bottom Line Stats
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/admin/access-codes">
                        <img
                          src={securityIcon}
                          alt="security"
                          width={18}
                          height={16}
                          className="me-2"
                        />
                        Host Access Codes
                      </Nav.Link>
                    </>
                  ) : (
                    <>
                      <Nav.Link as={NavLink} to="/explore" className="ms-auto">
                        <img
                          src={mapIcon}
                          alt="map"
                          width={20.25}
                          height={18}
                          className="me-2"
                        />
                        Explore Visionville
                      </Nav.Link>
                      <Nav.Link as={NavLink} to="/leaderboards">
                        <img
                          src={leaderboardIcon}
                          alt="leaderboard"
                          width={16.66}
                          height={16}
                          className="me-2"
                        />
                        Leaderboards
                      </Nav.Link>
                    </>
                  )}
                  <Dropdown className="ms-3">
                    <Dropdown.Toggle as={DropdownToggle}></Dropdown.Toggle>
                    <Dropdown.Menu align="end" className="shadow-sm">
                      {!isOwner && (
                        <Dropdown.Item
                          as={NavLink}
                          to="/teams"
                          className="ls-none"
                        >
                          <img
                            src={twoPeopleIcon}
                            alt="two people"
                            width={16.66}
                            height={16}
                            className="me-2"
                          />
                          View Teams
                        </Dropdown.Item>
                      )}
                      {isLeader && (
                        <Dropdown.Item
                          as={NavLink}
                          to="/leader/award"
                          className="ls-none"
                        >
                          <img
                            src={moneyIcon}
                            alt="money"
                            width={16.66}
                            height={16}
                            className="me-2"
                          />
                          Award Contracts
                        </Dropdown.Item>
                      )}
                      {isHost && (
                        <Dropdown.Item
                          as={NavLink}
                          to="/host/dashboard"
                          className="ls-none"
                        >
                          <img
                            src={cogIcon}
                            alt="cog"
                            width={16.66}
                            height={16}
                            className="me-2"
                          />
                          Host Dashboard
                        </Dropdown.Item>
                      )}
                      {!isOwner && (
                        <Dropdown.Item
                          as={NavLink}
                          to="/help"
                          className="ls-none"
                        >
                          <img
                            src={helpIcon}
                            alt="cog"
                            width={16.66}
                            height={16}
                            className="me-2"
                          />
                          Help / FAQs
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item
                        className="ls-none"
                        onClick={handleSignout}
                      >
                        <img
                          src={logoutIcon}
                          alt="logout"
                          width={16.66}
                          height={16}
                          className="me-2"
                        />
                        Log out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Stack>
              </Nav>
            </Navbar>
          </>
        )}
      </Container>
    </Navbar>
  )
}

/**
 * Custom DropdownToggle component to use three dots instead of the default
 * arrow icon
 */
const DropdownToggle = forwardRef(({ children, onClick }, ref) => (
  <Button
    className="px-1"
    variant="link"
    ref={ref}
    onClick={(e) => {
      e.preventDefault()
      onClick(e)
    }}
  >
    {children}
    <img src={threeDotsIcon} alt="three dots" width={20} height={4} />
  </Button>
))
