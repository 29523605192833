import React, { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TeamDataView from '../../components/TeamDataView'
import Spinner from 'react-bootstrap/Spinner'

import { useTeam } from '../../hooks/useTeam'
import { useAppState } from '../../hooks/useAppState'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

import './styles.css'

export default function ViewTeams() {
  const [allTeams, setTeams] = useState([])
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const { getTeamsByOfferingId } = useTeam()
  const [loading, setLoading] = useState(false)
  const {
    state: { hostMergeTeams, dispatch: dispatchNC },
  } = useNotificationChannel()

  useEffect(() => {
    if (offeringId === -1 || offeringId === null) {
      return
    }

    setLoading(true)
    getTeamsByOfferingId(offeringId)
      .then((teamsData) => {
        setTeams(
          teamsData.data.getTeamsByOfferingId.filter(
            (team) => team.mergedWith === null
          )
        )
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getTeamsByOfferingId, offeringId])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('ViewTeams')
    ) {
      setLoading(true)
      getTeamsByOfferingId(offeringId)
        .then((teamsData) => {
          setTeams(
            teamsData.data.getTeamsByOfferingId.filter(
              (team) => team.mergedWith === null
            )
          )
        })
        .finally(() => {
          setLoading(false)
          dispatchNC({
            type: 'host-merge-teams-executed',
            payload: {
              in: 'ViewTeams',
            },
          })
        })
    }
  }, [hostMergeTeams, getTeamsByOfferingId, dispatchNC, offeringId])

  const renderTeamsBlock = () => {
    return allTeams.map((team, i) => {
      return <TeamDataView key={i} team={team} initialView="radar" />
    })
  }

  return (
    <Container as="main" className="pb-5 main">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h1 className="text-center text-primary fw-bold">View Teams</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <div className="shadow-plain px-3 py-2 mt-4 mb-5 rounded">
            How has each team designed their firm? View team snapshots below.
          </div>
        </Col>
      </Row>

      {loading ? (
        <div className="text-center">
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="secondary"
            className="me-2"
          />
          Loading...
        </div>
      ) : (
        renderTeamsBlock()
      )}
    </Container>
  )
}
