import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import clsx from 'clsx'

import './style.css'

export default function BlsModal({
  show,
  setShow,
  title,
  body,
  headerTextColor = 'text-primary',
  children,
  compact = false,
  closeButtonDisabled = false,
  onClose,
}) {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setShow(false)
  }

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      dialogClassName={clsx(!compact && 'bls-modal')}
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className={clsx('h5 fw-bold', headerTextColor)}>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof body === 'string' ? (
          <div dangerouslySetInnerHTML={{ __html: body }} />
        ) : (
          children
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-success"
          className="rounded-pill"
          disabled={closeButtonDisabled}
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
