import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import HelpButton from '../HelpButton'
import InputSelect from '../InputSelect'
import BlsModal from '../BlsModal'

import { useAppState } from '../../hooks/useAppState'
import { useTeam } from '../../hooks/useTeam'
import { useMerge } from '../../hooks/useMerge'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { NOTIF_CH_HOST_MERGE_TEAMS } from '../../utils/constants'

import greenCheckMarkIcon from '../../images/icons/green-checkmark-icon.svg'

export default function HostMergeTeams() {
  const {
    state: {
      me,
      offering: { offeringId },
      year,
      rtNotification,
    },
  } = useAppState()
  const { getTeamsByOfferingId, updateTeam } = useTeam()
  const { mergeTeams, moveTeam } = useMerge()
  const {
    state: { teamConfirmedDesign },
    dispatch: dispatchNC,
    sendRTNotification,
  } = useNotificationChannel()
  const [teams, setTeams] = useState([])
  const [teamOne, setTeamOne] = useState('Select a Firm')
  const [teamTwo, setTeamTwo] = useState('Select a Firm')
  const [merging, setMerging] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [smallerFirmName, setSmallerFirmName] = useState('')
  const [largerFirmName, setLargerFirmName] = useState('')
  const [mergingChecklist, setMergingChecklist] = useState([
    'notstarted',
    'notstarted',
    'notstarted',
  ])

  /**
   * Fetch teams that have been confirmed in this offering
   */
  const fetchConfirmedTeams = useCallback(async () => {
    getTeamsByOfferingId(offeringId).then((getTeamsByOfferingIdData) => {
      const confirmedTeams =
        getTeamsByOfferingIdData.data.getTeamsByOfferingId.filter(
          (team) => team.confirm === true && team.mergedWith === null
        )
      setTeams(confirmedTeams)
    })
  }, [getTeamsByOfferingId, offeringId])

  /**
   * Handle merging of two teams
   */
  const handleMerge = async () => {
    // Send real-time notification to the affected teams
    sendRTNotification({
      to: rtNotification.channel,
      message: JSON.stringify({
        started: true,
        teamOne: teamOne,
        teamTwo: teamTwo,
      }),
      updater: me,
      type: NOTIF_CH_HOST_MERGE_TEAMS,
    })

    // Show the progress modal
    setModalShow(true)
    setMerging(true)
    setMergingChecklist(['started', 'notstarted', 'notstarted'])
    // await new Promise((resolve) => setTimeout(resolve, 5000))
    const mergeTeamsResult = await mergeTeams(
      teamOne,
      teamTwo,
      offeringId,
      year
    )
    // console.log('mergeTeamsResult ::', mergeTeamsResult)

    // Consolidate data into larger firm
    // - Partners: Firm 1 number of partners + Firm 2 number of partners
    // - Industries: Firm 1 selections + Firm 2 selections
    // - High-Profile Engagements: Firm 1 clients + Firm 2 clients
    // - Other results: Firm 1 other results + Firm 2 other results

    // Update smaller firm's userId to that of the larger one
    setSmallerFirmName(mergeTeamsResult.data.mergeTeams.smallerTeam.name)
    setLargerFirmName(mergeTeamsResult.data.mergeTeams.largerTeam.name)
    setMergingChecklist(['done', 'started', 'notstarted'])
    // await new Promise((resolve) => setTimeout(resolve, 3000))
    const smallerTeamUpdateInput = {
      id: mergeTeamsResult.data.mergeTeams.smallerTeam.id,
      mergedWith: mergeTeamsResult.data.mergeTeams.largerTeam.id,
      mergedYear: year,
    }
    const updateSmallerTeamPromise = updateTeam(smallerTeamUpdateInput)
    const largerTeamUpdateInput = {
      id: mergeTeamsResult.data.mergeTeams.largerTeam.id,
      yearConfirm: false,
      mergedYear: year,
    }
    const updateLargerTeamPromise = updateTeam(largerTeamUpdateInput)
    const moveTeamPromise = moveTeam(
      'Team',
      mergeTeamsResult.data.mergeTeams.smallerTeam.id,
      mergeTeamsResult.data.mergeTeams.largerTeam.id
    )
    await Promise.all([
      moveTeamPromise,
      updateSmallerTeamPromise,
      updateLargerTeamPromise,
    ])

    // Show complete message
    setMergingChecklist(['done', 'done', 'done'])

    /**
     * Notifications
     */
    const notifMessage = {
      completed: true,
      largerTeamId: mergeTeamsResult.data.mergeTeams.largerTeam.id,
      largerTeamName: mergeTeamsResult.data.mergeTeams.largerTeam.name,
      smallerTeamId: mergeTeamsResult.data.mergeTeams.smallerTeam.id,
      smallerTeamName: mergeTeamsResult.data.mergeTeams.smallerTeam.name,
    }

    // Send real-time notification
    sendRTNotification({
      to: rtNotification.channel,
      message: JSON.stringify(notifMessage),
      updater: me,
      type: NOTIF_CH_HOST_MERGE_TEAMS,
    })

    // Dispatch local notification so other components in Host dashboard can
    // get updated
    dispatchNC({
      type: 'host-merge-teams-executed',
      payload: {
        enabled: true,
        message: notifMessage,
        in: 'HostMergeTeams',
      },
    })

    // Refresh the team list
    fetchConfirmedTeams()

    // Reset teamOne and teamTwo state
    setTeamOne('Select a Firm')
    setTeamTwo('Select a Firm')

    // Hide the progress modal
    setMerging(false)
  }

  const handleModalClose = () => {
    setModalShow(false)
  }

  /**
   * Load the team info
   */
  useEffect(() => {
    fetchConfirmedTeams()
  }, [fetchConfirmedTeams])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the team has confirmed their design
   */
  useEffect(() => {
    if (teamConfirmedDesign.enabled) {
      fetchConfirmedTeams()
      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [dispatchNC, fetchConfirmedTeams, teamConfirmedDesign.enabled])

  return (
    <>
      <BlsModal
        show={modalShow}
        setShow={setModalShow}
        compact
        title={'Merge Firms'}
        closeButtonDisabled={merging}
        onClose={handleModalClose}
      >
        <div className="fw-bold text-center">
          Please wait while merging firms...
        </div>
        <Stack
          direction="horizontal"
          gap={1}
          className={clsx(
            'justify-content-center',
            mergingChecklist[0] === 'notstarted' && 'invisible'
          )}
        >
          <div className="text-center">Consolidating assets...</div>
          {mergingChecklist[0] === 'started' ? (
            <Spinner
              as="div"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
              className="ms-2"
            />
          ) : (
            <img
              src={greenCheckMarkIcon}
              alt="green check mark"
              width={18}
              height={18}
              className="ms-2"
            />
          )}
        </Stack>
        <Stack
          direction="horizontal"
          gap={1}
          className={clsx(
            'justify-content-center',
            mergingChecklist[1] === 'notstarted' && 'invisible'
          )}
        >
          <div className="text-center">
            Moving <strong>{smallerFirmName}</strong> over to{' '}
            <strong>{largerFirmName}</strong>...
          </div>
          {mergingChecklist[1] === 'started' ? (
            <Spinner
              as="div"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="secondary"
              className="ms-2"
            />
          ) : (
            <img
              src={greenCheckMarkIcon}
              alt="green check mark"
              width={18}
              height={18}
              className="ms-2"
            />
          )}
        </Stack>
        <Stack
          direction="horizontal"
          gap={1}
          className={clsx(
            'justify-content-center',
            mergingChecklist[2] === 'notstarted' && 'invisible'
          )}
        >
          <div className="text-center">Merger complete!</div>
          <img
            src={greenCheckMarkIcon}
            alt="green check mark"
            width={18}
            height={18}
            className="ms-2"
          />
        </Stack>
      </BlsModal>
      <div className="mt-3 mt-lg-0">
        <Stack direction="horizontal" gap={2} className="mb-3">
          <h2 className="text-primary h5 fw-bold mb-0">Merge Firms</h2>
          <HelpButton
            title="What Happens When Two Firms Merge?"
            content="<p>For the most part, the “larger” firm absorbs the “smaller” firm. The larger firm is here defined as the firm with the highest profit at the time of the merge. Specific details for the new, merged firm include: </p><ol><li>Firm Name - Larger firm </li><li>Firm Tagline - Larger firm</li><li>Firm Values - Larger firm</li><li>Firm Partners - Firm 1 number of partners + Firm 2 number of partners = New number of partners</li><li>Line of Business Mix - Larger firm</li><li>Expertise - Larger firm</li><li>Industries - Firm 1 selections + Firm 2 selections = New, combined selections</li><li>8 x Operating Parameters - Larger firm</li><li>High-Profile Engagements - Firm 1 client list + Firm 2 client list = New, combined client list</li><li>Other Results - Firm 1 other results + Firm 2 other results = New, combined total of other results</li><li>Client Satisfaction Leaderboard - A modification is placed on the newly calculated leaderboard value. It’s modified by 0.8 in the first year of the merge, 0.85 the next year (if applicable), 0.9 the next year (if applicable), and 0.95 the next year (if applicable).</li><li>Employee Satisfaction Leaderboard - A modification is placed on the newly calculated leaderboard value. It’s modified by 0.8 in the first year of the merge, 0.85 the next year (if applicable), 0.9 the next year (if applicable), and 0.95 the next year (if applicable).</li></ol>"
          />
        </Stack>
        <p>
          When two firms request to merge (and you approve the merge), execute
          the merge here. To do so, select each firm’s name from the drop-down
          lists and click <strong>Confirm Merge</strong>.
        </p>
        <p>
          You cannot reverse this action, so be sure to double check before
          confirming!
        </p>

        <Form>
          <Stack direction="horizontal" gap={2} className="mt-4">
            <InputSelect
              id="firm1"
              options={teams.map((team) => ({
                key: team.name,
                label: team.name,
                value: team.id,
              }))}
              value={teamOne}
              onChange={({ target }) => {
                setTeamOne(target.value)
              }}
              label="Select a Firm"
            />
            <InputSelect
              id="firm2"
              options={teams.map((team) => ({
                key: team.name,
                label: team.name,
                value: team.id,
              }))}
              value={teamTwo}
              onChange={({ target }) => {
                setTeamTwo(target.value)
              }}
              label="Select a Firm"
            />
          </Stack>
          <Button
            variant="outline-success"
            className="rounded-pill px-3 mt-2"
            disabled={
              teamOne === 'Select a Firm' ||
              teamTwo === 'Select a Firm' ||
              teamOne === teamTwo
            }
            onClick={handleMerge}
          >
            Confirm Merge
          </Button>
        </Form>
      </div>
    </>
  )
}
