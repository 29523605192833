import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getLeadersByOfferingId as getLeadersByOfferingIdQuery } from '../graphql/queries'

export const useLeadersByOfferingId = () => {
  const getLeadersByOfferingId = useCallback((offeringId) => {
    return API.graphql(
      graphqlOperation(getLeadersByOfferingIdQuery, { offeringId })
    )
  }, [])

  return { getLeadersByOfferingId }
}
