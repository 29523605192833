/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccessCode = /* GraphQL */ `
  query GetAccessCode($id: Int!) {
    getAccessCode(id: $id) {
      id
      code
      role
      updatedAt
    }
  }
`;
export const getAccessCodes = /* GraphQL */ `
  query GetAccessCodes {
    getAccessCodes {
      id
      code
      role
      updatedAt
    }
  }
`;
export const getOffering = /* GraphQL */ `
  query GetOffering($id: Int!) {
    getOffering(id: $id) {
      id
      hostId
      createdAt
      endedAt
      numTeams
      year
    }
  }
`;
export const getOfferings = /* GraphQL */ `
  query GetOfferings {
    getOfferings {
      id
      hostId
      createdAt
      endedAt
      numTeams
      year
    }
  }
`;
export const getTarget = /* GraphQL */ `
  query GetTarget($id: Int!) {
    getTarget(id: $id) {
      id
      displayName
      industry
      targetHours
      lob1
      lob2
      lob3
      engageSize
      engageType
      engageYear
      delighted
      stability
      startup
      immediateNeed
      detail
      winnerDetail
      outOfBusiness
    }
  }
`;
export const getTargets = /* GraphQL */ `
  query GetTargets($offeringId: Int) {
    getTargets(offeringId: $offeringId) {
      id
      displayName
      industry
      targetHours
      lob1
      lob2
      lob3
      engageSize
      engageType
      engageYear
      delighted
      stability
      startup
      immediateNeed
      detail
      winnerDetail
      outOfBusiness
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: Int!) {
    getTeam(id: $id) {
      id
      offeringId
      code {
        id
        code
        role
        updatedAt
      }
      confirm
      yearConfirm
      ready
      name
      tagline
      values
      numberOfPartners
      expertise
      industries
      infra
      empDev
      comp
      staffing
      pracDev
      pracDevBal
      philo
      geoFocus
      lobMix
      year
      mergedWith
      mergedYear
    }
  }
`;
export const getTeams = /* GraphQL */ `
  query GetTeams {
    getTeams {
      id
      offeringId
      code {
        id
        code
        role
        updatedAt
      }
      confirm
      yearConfirm
      ready
      name
      tagline
      values
      numberOfPartners
      expertise
      industries
      infra
      empDev
      comp
      staffing
      pracDev
      pracDevBal
      philo
      geoFocus
      lobMix
      year
      mergedWith
      mergedYear
    }
  }
`;
export const getTeamByOfferingId = /* GraphQL */ `
  query GetTeamByOfferingId($offeringId: Int!, $id: Int!) {
    getTeamByOfferingId(offeringId: $offeringId, id: $id) {
      id
      offeringId
      code {
        id
        code
        role
        updatedAt
      }
      confirm
      yearConfirm
      ready
      name
      tagline
      values
      numberOfPartners
      expertise
      industries
      infra
      empDev
      comp
      staffing
      pracDev
      pracDevBal
      philo
      geoFocus
      lobMix
      year
      mergedWith
      mergedYear
      clients {
        displayName
      }
    }
  }
`;
export const getTeamsByOfferingId = /* GraphQL */ `
  query GetTeamsByOfferingId($offeringId: Int!) {
    getTeamsByOfferingId(offeringId: $offeringId) {
      id
      offeringId
      code {
        id
        code
        role
        updatedAt
      }
      confirm
      yearConfirm
      ready
      name
      tagline
      values
      numberOfPartners
      expertise
      industries
      infra
      empDev
      comp
      staffing
      pracDev
      pracDevBal
      philo
      geoFocus
      lobMix
      year
      mergedWith
      mergedYear
      clients {
        displayName
      }
    }
  }
`;
export const getBid = /* GraphQL */ `
  query GetBid($id: Int!) {
    getBid(id: $id) {
      id
      bidAt
      bidYear
      offeringId
      teamId
      target {
        id
        displayName
      }
      agreedFee
      status
      winningReason
    }
  }
`;
export const getBids = /* GraphQL */ `
  query GetBids {
    getBids {
      id
      bidAt
      bidYear
      offeringId
      teamId
      target {
        id
        displayName
      }
      agreedFee
      status
      winningReason
    }
  }
`;
export const getTeamBidsByYear = /* GraphQL */ `
  query GetTeamBidsByYear($teamId: Int!, $bidYear: Int!) {
    getTeamBidsByYear(teamId: $teamId, bidYear: $bidYear) {
      id
      bidAt
      bidYear
      offeringId
      teamId
      target {
        id
        displayName
      }
      agreedFee
      status
      winningReason
    }
  }
`;
export const getHighProfileIncome = /* GraphQL */ `
  query GetHighProfileIncome(
    $id: Int
    $incomeType: IncomeType!
    $incomeYear: Int
    $teamId: Int
    $offeringId: Int
  ) {
    getHighProfileIncome(
      id: $id
      incomeType: $incomeType
      incomeYear: $incomeYear
      teamId: $teamId
      offeringId: $offeringId
    ) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      profit
      agreedFee
      target {
        id
        displayName
      }
    }
  }
`;
export const getHighProfileIncomes = /* GraphQL */ `
  query GetHighProfileIncomes(
    $id: Int
    $incomeType: IncomeType!
    $incomeYear: Int
    $teamId: Int
    $offeringId: Int
  ) {
    getHighProfileIncomes(
      id: $id
      incomeType: $incomeType
      incomeYear: $incomeYear
      teamId: $teamId
      offeringId: $offeringId
    ) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      profit
      agreedFee
      target {
        id
        displayName
      }
    }
  }
`;
export const getOtherIncome = /* GraphQL */ `
  query GetOtherIncome(
    $id: Int
    $incomeType: IncomeType!
    $incomeYear: Int
    $teamId: Int
    $offeringId: Int
  ) {
    getOtherIncome(
      id: $id
      incomeType: $incomeType
      incomeYear: $incomeYear
      teamId: $teamId
      offeringId: $offeringId
    ) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      numAsClients
      numTsClients
      numCsClients
      asProfit
      tsProfit
      csProfit
      profit
    }
  }
`;
export const getOtherIncomes = /* GraphQL */ `
  query GetOtherIncomes(
    $id: Int
    $incomeType: IncomeType!
    $incomeYear: Int
    $teamId: Int
    $offeringId: Int
  ) {
    getOtherIncomes(
      id: $id
      incomeType: $incomeType
      incomeYear: $incomeYear
      teamId: $teamId
      offeringId: $offeringId
    ) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      numAsClients
      numTsClients
      numCsClients
      asProfit
      tsProfit
      csProfit
      profit
    }
  }
`;
export const getHost = /* GraphQL */ `
  query GetHost($id: Int!) {
    getHost(id: $id) {
      id
      hostName
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
    }
  }
`;
export const getHosts = /* GraphQL */ `
  query GetHosts {
    getHosts {
      id
      hostName
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
    }
  }
`;
export const getLeader = /* GraphQL */ `
  query GetLeader($id: Int!) {
    getLeader(id: $id) {
      id
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
    }
  }
`;
export const getLeaders = /* GraphQL */ `
  query GetLeaders {
    getLeaders {
      id
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
    }
  }
`;
export const getLeadersByOfferingId = /* GraphQL */ `
  query GetLeadersByOfferingId($offeringId: Int!) {
    getLeadersByOfferingId(offeringId: $offeringId) {
      id
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
    }
  }
`;
export const getHighProfileEngagements = /* GraphQL */ `
  query GetHighProfileEngagements($offeringId: Int!, $bidYear: Int!) {
    getHighProfileEngagements(offeringId: $offeringId, bidYear: $bidYear) {
      id
      displayName
      industry
      engageSize
      engageType
      engageYear
      immediateNeed
      winnerDetail
      lob1
      lob2
      lob3
      agreedFee
      team {
        bidId
        agreedFee
        status
        teamId
        teamName
      }
    }
  }
`;
export const getMyActiveClients = /* GraphQL */ `
  query GetMyActiveClients($clientId: Int, $teamId: Int!, $year: Int!) {
    getMyActiveClients(clientId: $clientId, teamId: $teamId, year: $year) {
      id
      offeringId
      teamId
      bidId
      lob1
      lob2
      lob3
      oldAnticipatedHours
      anticipatedHours
      actualHours
      anticipatedProfit
      actualProfit
      agreedFee
      outOfBusiness
      satisfaction
      drop
      didBlowBudget
      awardNewLOB
      lobCount
      target {
        id
        displayName
        targetHours
        delighted
        engageType
        lob1
        lob2
        lob3
        stability
      }
      income {
        incomeYear
      }
    }
  }
`;
export const getTeamsWonEngageByOfferingId = /* GraphQL */ `
  query GetTeamsWonEngageByOfferingId($offeringId: Int!) {
    getTeamsWonEngageByOfferingId(offeringId: $offeringId) {
      name
      wonCount
    }
  }
`;
export const leaderboardTotalProfit = /* GraphQL */ `
  query LeaderboardTotalProfit($offeringId: Int!) {
    leaderboardTotalProfit(offeringId: $offeringId) {
      teamId
      teamName
      totalProfit
    }
  }
`;
export const leaderboardHPRevenue = /* GraphQL */ `
  query LeaderboardHPRevenue($offeringId: Int!) {
    leaderboardHPRevenue(offeringId: $offeringId) {
      teamId
      teamName
      revenue
    }
  }
`;
export const leaderboardEmployeeSatisfaction = /* GraphQL */ `
  query LeaderboardEmployeeSatisfaction($offeringId: Int!) {
    leaderboardEmployeeSatisfaction(offeringId: $offeringId) {
      teamId
      teamName
      satisfaction
    }
  }
`;
export const leaderboardClientSatisfaction = /* GraphQL */ `
  query LeaderboardClientSatisfaction($offeringId: Int!) {
    leaderboardClientSatisfaction(offeringId: $offeringId) {
      teamId
      teamName
      clientCount
      satisfaction
    }
  }
`;
export const getSimStats = /* GraphQL */ `
  query GetSimStats {
    getSimStats {
      totalSims
      totalTeams
    }
  }
`;
export const getTeamStats = /* GraphQL */ `
  query GetTeamStats {
    getTeamStats {
      topTeam
      topProfit
      topClientSatisfaction
      topEmployeeSatisfaction
    }
  }
`;
