import { useState, useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { sendChat as sendChatMutation } from '../graphql/mutations'
import { onChatUpdate } from '../graphql/subscriptions'
import { useAppState } from './useAppState'
import { useAuth } from './useAuth'
import { NOTIF_CHAT_HOST } from '../utils/constants'

export const useChat = () => {
  const { userId, isHost, isLeader, isTeam } = useAuth()
  const [chats, setChats] = useState([])
  const {
    state: { me, chats: appStateChats },
    dispatch,
  } = useAppState()

  /**
   * Receive chat message
   */
  const handleChatUpdate = useCallback(
    (chat, onNewMessage) => {
      // Filter out a message that is not destined for us
      if (
        // I don't need to process my own message
        chat.sender === me ||
        // Host user dones't need to process a Host message
        (isHost && chat.type === NOTIF_CHAT_HOST) ||
        // Leader user doesn't need to process a chat message
        isLeader ||
        // This message is sent from Host to another Team
        (isTeam && `${chat.receiver}` !== `${userId}`)
      ) {
        return
      }

      setChats([...chats, chat])

      // If I'm a Host user, I need to store the team chats in the App State
      if (isHost) {
        dispatch({ type: 'add-chat', payload: chat })
      }

      if (onNewMessage) {
        onNewMessage(chat)
      }
    },
    [chats, isHost, isLeader, isTeam, me, userId, dispatch]
  )

  /**
   * Connect to a chat room
   */
  const connectChatRoom = useCallback(
    (offeringId, teamId, onNewMessage) => {
      const channel = generateChatRoomName(offeringId, teamId)
      const subscription = API.graphql(
        graphqlOperation(onChatUpdate, { to: channel })
      ).subscribe({
        next: ({ value }) => {
          const update = value.data.onChatUpdate
          handleChatUpdate(update, onNewMessage)
        },
        error: (errorObj) => {
          console.warn(errorObj)
        },
      })

      const chatHistory = appStateChats.find(
        (chat) => `${chat.receiver}` === `${teamId}`
      )
      if (chatHistory) {
        setChats(chatHistory.chats)
      }

      return subscription
    },
    [handleChatUpdate, appStateChats]
  )

  /**
   * Send chat message
   */
  const sendChatMessage = (sendChatInput) => {
    return API.graphql(graphqlOperation(sendChatMutation, sendChatInput))
  }

  /**
   * Handle send chat message
   */
  const sendChat = (sendChatInput) => {
    setChats([...chats, sendChatInput])

    if (isHost) {
      dispatch({
        type: 'add-chat',
        payload: sendChatInput,
      })
    }

    return sendChatMessage(sendChatInput)
  }

  /**
   * Generate chat room name
   *
   * @param {Number} offeringId - Offering ID
   * @param {Number} teamId - Team ID
   * @returns
   */
  const generateChatRoomName = (offeringId, teamId) =>
    `chat-room-${offeringId}-${teamId}`

  return {
    chats,
    connectChatRoom,
    sendChat,
    generateChatRoomName,
  }
}
