import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { cognitoAddUser as cognitoAddUserMutation } from '../graphql/mutations'

export const useCognito = () => {
  const cognitoAddUser = useCallback(async (accessCode, groupName, userId) => {
    return API.graphql(
      graphqlOperation(cognitoAddUserMutation, {
        input: { accessCode, groupName, userId },
      })
    )
  }, [])

  return {
    cognitoAddUser,
  }
}
