import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getTeamBidsByYear as getTeamBidsByYearQuery,
  getMyActiveClients as getMyActiveClientsQuery,
} from '../graphql/queries'
import { awardBidWinner } from '../graphql/mutations'

export const useBids = () => {
  const getTeamBidsByYear = useCallback(async (teamId, bidYear) => {
    return API.graphql(
      graphqlOperation(getTeamBidsByYearQuery, { teamId, bidYear })
    )
  }, [])

  const awardBid = useCallback(
    async (
      offeringId,
      bidId,
      bidYear,
      wonTeamId,
      targetId,
      lob1,
      winningReason,
      agreedFee
    ) => {
      return API.graphql(
        graphqlOperation(awardBidWinner, {
          input: {
            offeringId,
            bidId,
            bidYear,
            wonTeamId,
            targetId,
            lob1,
            winningReason,
            agreedFee,
          },
        })
      )
    },
    []
  )

  const getMyActiveClients = useCallback(async (bidYear, teamId) => {
    return API.graphql(
      graphqlOperation(getMyActiveClientsQuery, {
        bidYear,
        teamId,
      })
    )
  }, [])

  return {
    getTeamBidsByYear,
    awardBid,
    getMyActiveClients,
  }
}
