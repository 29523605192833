import React, { useState } from 'react'
import Toast from 'react-bootstrap/Toast'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import ToastContainer from 'react-bootstrap/Toast'
import './styles.css'
import NotificationIcon from '../../images/icons/notification-icon.svg'

import { useNotification } from '../../hooks/useNotification'

export default function ToastManager() {
  const position = 'top-end'

  const { state } = useNotification()

  return (
    <div aria-live="polite" aria-atomic="true" className="toast-manager">
      {/*<NotificationBadge toasts={state} />*/}
      <ToastContainer
        className="p-3 toast-container position-absolute top-0 end-0"
        position={position}
      >
        {state.map((toast, i) => (
          <CustomToast
            key={toast.id}
            title={toast.title}
            body={toast.body}
            id={toast.id}
            time={toast.time}
            type={toast.type}
            op={toast.op}
          />
        ))}
      </ToastContainer>
    </div>
  )
}

export function CustomToast({ id, title, body, time, type, op }) {
  const toastType = 'toast-' + type
  const options = op ? op : { delay: 5000, autohide: false }

  const { dispatch } = useNotification()

  const onCloseToastHandler = (id) => {
    dispatch({
      type: 'REMOVE_TOAST',
      id: id,
    })
  }

  return (
    <Toast
      onClose={() => onCloseToastHandler(id)}
      show={true}
      delay={options.delay}
      className={toastType}
      autohide={options.autohide}
    >
      <Toast.Header>
        <img
          src={NotificationIcon}
          className="toast-icon rounded me-2"
          alt="notification icon"
        />
        <strong className="me-auto">{title}</strong>
        <small>{time}</small>
      </Toast.Header>
      <Toast.Body>
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </Toast.Body>
    </Toast>
  )
}

//Posible notification dropdown in case we want to add to nav bar
export function NotificationBadge({ toasts, onDelete }) {
  const { dispatch } = useNotification()

  const onAddHandler = (ms) => {
    const dly = ms
    const ah = ms > 0
    const t = ah ? 'success' : 'info'
    //console.log('ADD TOAST', ah)
    dispatch({
      type: 'ADD_TOAST',
      payload: {
        title: 'New Notification',
        body: 'Aliquam quis lacinia justo. Nullam posuere enim eget fermentum mattis.',
        time: new Date().toLocaleTimeString(),
        type: t,
        op: { delay: dly, autohide: ah },
      },
    })
  }

  const onDeleteHandler = () => {
    onDelete()
  }
  return (
    <DropdownButton className="notifications-button" title={toasts.length}>
      {toasts.map((t, i) => (
        <Dropdown.Item as="button" key={i} id={i}>
          <small>{t.time}</small> {t.title}
          <a>X</a>
        </Dropdown.Item>
      ))}
      <Dropdown.Item onClick={() => onAddHandler(-1)}>ADD</Dropdown.Item>
      <Dropdown.Item onClick={() => onAddHandler(6000)}>ADD AUTO</Dropdown.Item>
    </DropdownButton>
  )
}
