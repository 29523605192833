import { useEffect, useState, useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import clsx from 'clsx'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'

import InputNumber from '../../components/InputNumber'
import HostTeamsAccessCode from '../../components/HostTeamsAccessCode'
import HostLeaderAccessCode from '../../components/HostLeaderAccessCode'
import HostMergeTeams from '../../components/HostMergeTeams'
import HostSupport from '../../components/HostSupport'
import HostAdvanceYear from '../../components/HostAdvanceYear'
import HostEndSimulation from '../../components/HostEndSimulation'

import { useAuth } from '../../hooks/useAuth'
import { useHost } from '../../hooks/useHost'
import { useTeam } from '../../hooks/useTeam'
import { useCognito } from '../../hooks/useCognito'
import { useAppState } from '../../hooks/useAppState'
import { useStats } from '../../hooks/useStats'

import greenCheckMarkIcon from '../../images/icons/green-checkmark-icon.svg'

import './style.css'

import {
  addOffering as addOfferingMutation,
  addLeader as addLeaderMutation,
  cognitoAddUser as cognitoAddUserMutation,
} from '../../graphql/mutations'

export default function HostDashboard() {
  const [loading, setLoading] = useState(false)
  const [isCreatingOffering, setIsCreatingOffering] = useState(false)
  const { userId, user, getUserCustomAttribute } = useAuth()
  const { fetchHost } = useHost()
  const { addTeam } = useTeam()
  const { cognitoAddUser } = useCognito()
  const { addSimStats } = useStats()
  const {
    state: {
      offering: { offeringId },
    },
    dispatch,
  } = useAppState()
  const [ended, setEnded] = useState(false)
  const [numberOfTeams, setNumberOfTeams] = useState('1')
  const [creatingChecklist, setCreatingChecklist] = useState([
    'notstarted',
    'notstarted',
    'notstarted',
    'notstarted',
  ])

  const handleFetchHost = useCallback(async () => {
    if (userId === null) {
      return
    }

    setLoading(true)
    fetchHost(userId)
      .then((host) => {
        if (host) {
          /**
           * App state
           */
          // Save local state: offering id
          const _offeringId = host.data.getHost.offering.id
          dispatch({
            type: 'set-offering-id',
            payload: _offeringId !== null ? _offeringId : -1,
          })

          // Sim year
          if (!isNaN(parseInt(host.data.getHost.offering.year))) {
            dispatch({
              type: 'set-year',
              payload: host.data.getHost.offering.year,
            })
          } else {
            dispatch({
              type: 'set-year',
              payload: 1,
            })
          }

          // Also reset create simulation status
          setCreatingChecklist([
            'notstarted',
            'notstarted',
            'notstarted',
            'notstarted',
          ])
        }
      })
      .catch((err) => {
        // TODO: Handle fetchHost error
        console.log('host err', err)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [fetchHost, userId, dispatch])

  /**
   * Reload the host info when the simulation is ended
   */
  useEffect(() => {
    if (!ended) {
      return
    }

    // Reset app state
    dispatch({
      type: 'reset',
    })

    handleFetchHost()
  }, [ended, handleFetchHost, dispatch])

  /**
   * Handle "create simulation"
   */
  const handleCreateSimulation = async () => {
    // Cannot proceed without userId
    if (userId === null) {
      console.log('Cannot create a new offering without a host id')
      return
    }

    /**
     * Offering
     */
    // Create an offering
    try {
      setIsCreatingOffering(true)
      setCreatingChecklist([
        'started',
        'notstarted',
        'notstarted',
        'notstarted',
      ])

      const addOfferingMutationResult = await API.graphql(
        graphqlOperation(addOfferingMutation, {
          input: { hostId: userId, numTeams: numberOfTeams },
        })
      )
      // await new Promise((resolve) => setTimeout(resolve, 3000))
      setCreatingChecklist(['done', 'started', 'notstarted', 'notstarted'])

      /**
       * Team(s)
       */
      const _offeringId = addOfferingMutationResult.data.addOffering.id

      // Create team access codes
      const hostNumber = getUserCustomAttribute(user, 'number')
      if (hostNumber < 0) {
        throw new Error('Cannot add team :: Host number is not available')
      }

      let setupTeamCognitoUsers = []
      for (let i = 0; i < parseInt(numberOfTeams); i++) {
        try {
          // Add team
          const addTeamMutationData = await addTeam(_offeringId, hostNumber)

          const teamId = addTeamMutationData.data.addTeam.id
          const code = addTeamMutationData.data.addTeam.code.code

          // Set up Cognito user for the team
          setupTeamCognitoUsers.push(cognitoAddUser(code, 'Team', teamId))
        } catch (err) {
          throw err
        }
      }

      // Create team users in Cognito
      try {
        await Promise.all(setupTeamCognitoUsers)
      } catch (err) {
        console.log('Set up team users in Cognito failed', err)
      }

      /**
       * Leader
       */
      // Create leader access code
      try {
        setCreatingChecklist(['done', 'done', 'started', 'notstarted'])
        const addLeaderMutationData = await API.graphql(
          graphqlOperation(addLeaderMutation, {
            input: { hostNumber, offeringId: _offeringId, hostId: userId },
          })
        )

        // Create leader user in Cognito
        try {
          const leaderId = addLeaderMutationData.data.addLeader.id
          const code = addLeaderMutationData.data.addLeader.code.code

          await API.graphql(
            graphqlOperation(cognitoAddUserMutation, {
              input: {
                accessCode: code,
                groupName: 'Leader',
                userId: leaderId,
              },
            })
          )
        } catch (err) {
          throw err
        }
      } catch (err) {
        throw err
      }

      /**
       * Save the sim stats
       */
      try {
        setCreatingChecklist(['done', 'done', 'done', 'started'])
        await addSimStats(_offeringId, parseInt(numberOfTeams))

        // Clean up the interface
        setCreatingChecklist(
          'notstarted',
          'notstarted',
          'notstarted',
          'notstarted'
        )
        setNumberOfTeams('1')
        setEnded(false)

        // Now, we can show the host dashboard
        dispatch({
          type: 'set-offering-id',
          payload: _offeringId !== null ? _offeringId : -1,
        })
      } catch (err) {
        throw err
      }
    } catch (err) {
      console.error(err)
    } finally {
      setIsCreatingOffering(false)
    }
  }

  if (offeringId === null) {
    return (
      <Container as="main" className="pb-5 main">
        <Row className="mb-4">
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <h1 className="text-center text-primary fw-bold">Host Dashboard</h1>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <>
      <Container as="main" className="pb-5 main">
        <Row className="mb-4">
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <h1 className="text-center text-primary fw-bold">Host Dashboard</h1>
            {!loading && (
              <div
                className="shadow-plain px-3 py-2 mt-4 rounded"
                dangerouslySetInnerHTML={{ __html: getBlurb(offeringId) }}
              />
            )}
          </Col>
        </Row>

        {/** Show when offering hasn't been created yet */}
        {offeringId === -1 && (
          <>
            <Row className="my-5 justify-content-center">
              <Col xs="auto">
                <Form>
                  <InputNumber
                    as="div"
                    id="numTeams"
                    className="gx-0 hstack gap-5"
                    label="Desired Number of Teams:"
                    labelClassName="mb-0"
                    min={1}
                    max={30}
                    value={numberOfTeams}
                    setValue={(value) => setNumberOfTeams(value)}
                    disabled={isCreatingOffering}
                  />
                </Form>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs="auto">
                <Button
                  variant="outline-success"
                  className="rounded-pill flex-shrink-0"
                  disabled={isCreatingOffering}
                  onClick={handleCreateSimulation}
                >
                  {isCreatingOffering && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-2"
                    />
                  )}
                  Create Simulation
                </Button>
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 text-gray-400">
              <Col xs="auto">
                <Table className="table table-borderless">
                  <tbody>
                    <tr
                      className={clsx(
                        creatingChecklist[0] === 'notstarted' && 'invisible'
                      )}
                    >
                      <td>Initiate simulation...</td>
                      <td>
                        {creatingChecklist[0] === 'started' ? (
                          <Spinner
                            as="div"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                            className="ms-2"
                          />
                        ) : (
                          <img
                            src={greenCheckMarkIcon}
                            alt="green check mark"
                            width={18}
                            height={18}
                            className="ms-2"
                          />
                        )}
                      </td>
                    </tr>
                    <tr
                      className={clsx(
                        creatingChecklist[1] === 'notstarted' && 'invisible'
                      )}
                    >
                      <td className="text-end">Set up team(s)...</td>
                      <td>
                        {creatingChecklist[1] === 'started' ? (
                          <Spinner
                            as="div"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                            className="ms-2"
                          />
                        ) : (
                          <img
                            src={greenCheckMarkIcon}
                            alt="green check mark"
                            width={18}
                            height={18}
                            className="ms-2"
                          />
                        )}
                      </td>
                    </tr>
                    <tr
                      className={clsx(
                        creatingChecklist[2] === 'notstarted' && 'invisible'
                      )}
                    >
                      <td className="text-end">Set up leader...</td>
                      <td>
                        {creatingChecklist[2] !== 'notstarted' &&
                          (creatingChecklist[2] === 'started' ? (
                            <Spinner
                              as="div"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              variant="secondary"
                              className="ms-2"
                            />
                          ) : (
                            <img
                              src={greenCheckMarkIcon}
                              alt="green check mark"
                              width={18}
                              height={18}
                              className="ms-2"
                            />
                          ))}
                      </td>
                    </tr>
                    <tr
                      className={clsx(
                        creatingChecklist[3] === 'notstarted' && 'invisible'
                      )}
                    >
                      <td className="text-end">Finalize setup...</td>
                      <td>
                        {creatingChecklist[3] !== 'notstarted' &&
                          (creatingChecklist[3] === 'started' ? (
                            <Spinner
                              as="div"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              variant="secondary"
                              className="ms-2"
                            />
                          ) : (
                            <img
                              src={greenCheckMarkIcon}
                              alt="green check mark"
                              width={18}
                              height={18}
                              className="ms-2"
                            />
                          ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}

        {/** Show when offering has already been created */}
        {offeringId > -1 && (
          <>
            <Row>
              <Col lg={7}>
                <HostTeamsAccessCode />
              </Col>
              <Col lg={{ offset: 1 }}>
                <HostLeaderAccessCode />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-none d-lg-block mt-lg-5"></div>
              </Col>
            </Row>
            <Row>
              <Col lg={7}>
                <HostMergeTeams />
                <div className="py-lg-2"></div>
                <HostAdvanceYear />
              </Col>
              <Col lg={{ offset: 1 }}>
                <HostSupport />
              </Col>
            </Row>
            <Row>
              <Col>
                <HostEndSimulation onEnded={() => setEnded(true)} />
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}

const getBlurb = (offeringId) => {
  if (offeringId === null) {
    return ''
  } else if (offeringId === -1) {
    return `There is currently no active simulation. To create a simulation, enter the desired number of teams and click <strong>Create Simulation</strong>.`
  } else {
    return `Use this page to manage the active simulation. From here you can: view the access codes for each team of participants, view the shared access code for all leaders/facilitators, merge firms together, advance the simulation year, provide support for participant teams, and formally end the current simulation.`
  }
}
