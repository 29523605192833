/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addOffering = /* GraphQL */ `
  mutation AddOffering($input: InputAddOffering) {
    addOffering(input: $input) {
      id
      hostId
      createdAt
      endedAt
      numTeams
      year
    }
  }
`;
export const removeOffering = /* GraphQL */ `
  mutation RemoveOffering($input: InputRemoveOffering) {
    removeOffering(input: $input) {
      id
    }
  }
`;
export const addTarget = /* GraphQL */ `
  mutation AddTarget($input: InputAddTarget) {
    addTarget(input: $input) {
      id
      displayName
      industry
      targetHours
      lob1
      lob2
      lob3
      engageSize
      engageType
      engageYear
      delighted
      stability
      startup
      immediateNeed
      detail
      winnerDetail
      outOfBusiness
    }
  }
`;
export const addTeam = /* GraphQL */ `
  mutation AddTeam($input: InputAddTeam) {
    addTeam(input: $input) {
      id
      offeringId
      code {
        id
        code
        role
        updatedAt
      }
      confirm
      yearConfirm
      ready
      name
      tagline
      values
      numberOfPartners
      expertise
      industries
      infra
      empDev
      comp
      staffing
      pracDev
      pracDevBal
      philo
      geoFocus
      lobMix
      year
      mergedWith
      mergedYear
    }
  }
`;
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: InputUpdateTeam) {
    updateTeam(input: $input) {
      status
      statusDetail
    }
  }
`;
export const advanceSimYear = /* GraphQL */ `
  mutation AdvanceSimYear($input: InputAdvanceSimYear) {
    advanceSimYear(input: $input) {
      status
      statusDetail
    }
  }
`;
export const readyToAdvance = /* GraphQL */ `
  mutation ReadyToAdvance($input: InputReadyToAdvance) {
    readyToAdvance(input: $input) {
      status
      statusDetail
    }
  }
`;
export const addBid = /* GraphQL */ `
  mutation AddBid($input: InputAddBid) {
    addBid(input: $input) {
      id
      bidAt
      bidYear
      offeringId
      teamId
      target {
        id
        displayName
      }
      agreedFee
      status
      winningReason
    }
  }
`;
export const addHighProfileIncome = /* GraphQL */ `
  mutation AddHighProfileIncome($input: InputAddHighProfileIncome) {
    addHighProfileIncome(input: $input) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      profit
      agreedFee
      target {
        id
        displayName
      }
    }
  }
`;
export const addOtherIncome = /* GraphQL */ `
  mutation AddOtherIncome($input: InputAddOtherIncome) {
    addOtherIncome(input: $input) {
      id
      teamId
      incomeType
      offeringId
      incomeYear
      numAsClients
      numTsClients
      numCsClients
      asProfit
      tsProfit
      csProfit
      profit
    }
  }
`;
export const addLeader = /* GraphQL */ `
  mutation AddLeader($input: InputAddLeader) {
    addLeader(input: $input) {
      id
      offering {
        id
        hostId
        createdAt
        endedAt
        numTeams
        year
      }
      updatedAt
      code {
        id
        code
        role
        updatedAt
      }
    }
  }
`;
export const awardBidWinner = /* GraphQL */ `
  mutation AwardBidWinner($input: InputAwardBidWinner) {
    awardBidWinner(input: $input) {
      status
      statusDetail
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: InputUpdateClient) {
    updateClient(input: $input) {
      status
      statusDetail
    }
  }
`;
export const refreshHostCode = /* GraphQL */ `
  mutation RefreshHostCode($input: InputRefreshHostCode) {
    refreshHostCode(input: $input) {
      newCode
      updatedAt
    }
  }
`;
export const refreshTeamCode = /* GraphQL */ `
  mutation RefreshTeamCode($input: InputRefreshTeamCode) {
    refreshTeamCode(input: $input) {
      newCode
      updatedAt
    }
  }
`;
export const updateHost = /* GraphQL */ `
  mutation UpdateHost($input: InputUpdateHost) {
    updateHost(input: $input) {
      status
      statusDetail
    }
  }
`;
export const addSimStats = /* GraphQL */ `
  mutation AddSimStats($input: InputAddSimStats) {
    addSimStats(input: $input) {
      offeringId
      numTeams
      updatedAt
    }
  }
`;
export const updateSimStats = /* GraphQL */ `
  mutation UpdateSimStats($input: InputUpdateSimStats) {
    updateSimStats(input: $input) {
      offeringId
      numNewTeams
    }
  }
`;
export const addTeamStats = /* GraphQL */ `
  mutation AddTeamStats($input: InputAddTeamStats) {
    addTeamStats(input: $input) {
      offeringId
      hostId
      hostName
      teamId
      teamName
      totalProfit
      clientSatisfaction
      employeeSatisfaction
      highProfileRevenue
      createdAt
    }
  }
`;
export const initHost = /* GraphQL */ `
  mutation InitHost {
    initHost {
      id
      code {
        id
        code
        role
        updatedAt
      }
    }
  }
`;
export const mergeTeams = /* GraphQL */ `
  mutation MergeTeams($input: InputMergeTeams) {
    mergeTeams(input: $input) {
      largerTeam {
        id
        name
      }
      smallerTeam {
        id
        name
      }
    }
  }
`;
export const cognitoAddUser = /* GraphQL */ `
  mutation CognitoAddUser($input: InputCognitoAddUser) {
    cognitoAddUser(input: $input) {
      result
    }
  }
`;
export const cognitoUpdateUser = /* GraphQL */ `
  mutation CognitoUpdateUser($input: InputCognitoUpdateUser) {
    cognitoUpdateUser(input: $input) {
      status
      statusDetail
    }
  }
`;
export const cognitoDeleteUser = /* GraphQL */ `
  mutation CognitoDeleteUser($input: InputCognitoDeleteUser) {
    cognitoDeleteUser(input: $input) {
      status
      statusDetail
    }
  }
`;
export const cognitoInitHost = /* GraphQL */ `
  mutation CognitoInitHost($input: [InputCognitoInitHost!]!) {
    cognitoInitHost(input: $input) {
      hostId
      hostNumber
      code
      status
      statusDetail
    }
  }
`;
export const publishStatusUpdate = /* GraphQL */ `
  mutation PublishStatusUpdate(
    $to: String!
    $message: String!
    $type: String!
    $updater: String!
    $updatedAt: AWSDateTime
  ) {
    publishStatusUpdate(
      to: $to
      message: $message
      type: $type
      updater: $updater
      updatedAt: $updatedAt
    ) {
      to
      message
      type
      updater
      updatedAt
    }
  }
`;
export const sendChat = /* GraphQL */ `
  mutation SendChat(
    $to: String!
    $sender: String!
    $receiver: String
    $message: String!
    $type: String!
    $sentAt: String
  ) {
    sendChat(
      to: $to
      sender: $sender
      receiver: $receiver
      message: $message
      type: $type
      sentAt: $sentAt
    ) {
      to
      sender
      receiver
      message
      type
      sentAt
    }
  }
`;
