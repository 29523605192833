import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'

import { ReactComponent as RefreshIcon } from '../../images/icons/refresh-icon.svg'

import { useHosts } from '../../hooks/useHosts'
import { useNotification } from '../../hooks/useNotification'

import './style.css'

export default function HostAccessCodes() {
  const {
    getHosts,
    getHostNumber,
    refreshHostCode,
    updateHostCodeInCognito,
    updateHostName,
  } = useHosts()
  const [hosts, setHosts] = useState([])
  const [refreshHostCodeId, setRefreshHostCodeId] = useState(-1)
  const { dispatch } = useNotification()
  const [loading, setLoading] = useState(false)
  const [savingNameForHostIndex, setSavingNameForHostIndex] = useState('')

  useEffect(() => {
    setLoading(true)
    getHosts()
      .then((hostsData) => {
        const _hosts = hostsData.data.getHosts.map((host) => {
          return {
            ...host,
            hostName: host.hostName === null ? '' : host.hostName,
          }
        })
        setHosts(_hosts)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [getHosts])

  const handleRefreshHostCode = async (hostId, hostIndex) => {
    if (hosts.length === 0) {
      return
    }

    try {
      setRefreshHostCodeId(hostId)

      const hostNumber = getHostNumber(hosts, hostId)

      // Refresh the host access code
      const refreshHostCodeData = await refreshHostCode(hostId, hostNumber)

      const newAccessCode = refreshHostCodeData.data.refreshHostCode.newCode

      // Update host access code in Cognito
      await updateHostCodeInCognito('Host', hostId, newAccessCode)

      // Refetch the host data
      const hostsData = await getHosts()
      const _hosts = hostsData.data.getHosts.map((host) => {
        return {
          ...host,
          hostName: host.hostName === null ? '' : host.hostName,
        }
      })
      setHosts(_hosts)

      // Show the success notification
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Refresh Host Access Code`,
          body: `Access code for Host #${hostIndex} updated.`,
          time: new Date().toLocaleTimeString(),
          type: 'success',
          op: { delay: 5000, autohide: true },
        },
      })
    } catch (err) {
      console.error(err)
    } finally {
      setRefreshHostCodeId(-1)
    }
  }

  const setInputValue = (value, hostIndex) => {
    if (hosts.length === 0) {
      return
    }

    let newHosts = [...hosts]
    newHosts[hostIndex].hostName = value
    setHosts(newHosts)
  }

  const handleKeyPress = async (e, hostId, hostIndex) => {
    // User presses ENTER key
    if (e.charCode === 13) {
      handleSaveName(hostId, hostIndex)
    }
  }

  const handleSaveName = async (hostId, hostIndex) => {
    setSavingNameForHostIndex(hostIndex)
    const hostName = hosts[hostIndex].hostName
    try {
      await updateHostName(hostId, hostName)

      // Show success notification
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Update Host Name`,
          body: `Host #${
            hostIndex + 1
          }'s name successfully updated to <strong>${hostName}</strong>.`,
          time: new Date().toLocaleTimeString(),
          type: 'success',
          op: { delay: 5000, autohide: true },
        },
      })
    } catch (err) {
      // console.log('Unable to update host name', err)
      dispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Update Host Name`,
          body: `Sorry, an error occured while updating Host #${
            hostIndex + 1
          }'s name. Please try again!`,
          time: new Date().toLocaleTimeString(),
          type: 'danger',
          op: { delay: 5000, autohide: true },
        },
      })
    } finally {
      setSavingNameForHostIndex('')
    }
  }

  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
          <h1 className="text-center text-primary fw-bold">
            Host Access Codes
          </h1>
          <div className="shadow-plain px-3 py-2 mt-4 rounded">
            Each Host of the <strong>Bottom Line Simulation</strong> requires a
            unique access code. The code allows the Host to log into the web
            application and generate access codes for both teams of participants
            and facilitators/leaders. When providing a code to a Host, type the
            Host’s name in the <strong>Code Recipient</strong> box and tap the
            enter key. To revoke a Host’s access, click the refresh icon to the
            right of the code.
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table responsive className="w-100 mt-4 align-middle run-table">
            <thead className="bg-gray-100">
              <tr>
                <th width="15%">Host #</th>
                <th width="70%">
                  <Stack direction="horizontal" gap={2}>
                    <span>Code Recipient</span>
                  </Stack>
                </th>
                <th width="15%">Access Code</th>
              </tr>
            </thead>
            <tbody>
              {/**
               * Empty row with a little bit of paddings to fill in the gap
               */}
              <tr>
                <td colSpan={3} className="py-1"></td>
              </tr>
              {loading ? (
                <tr>
                  <td>
                    <Spinner
                      as="div"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="secondary"
                    />
                  </td>
                  <td>
                    <Spinner
                      as="div"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="secondary"
                    />
                  </td>
                  <td>
                    <Spinner
                      as="div"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="secondary"
                    />
                  </td>
                </tr>
              ) : (
                hosts.map((host, hostIndex) => {
                  return (
                    <tr key={`${hostIndex}`}>
                      <td>{hostIndex + 1}</td>
                      <td className="position-relative">
                        {savingNameForHostIndex === hostIndex && (
                          <div className="host-ac-saving-spinner">
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              variant="secondary"
                            />
                          </div>
                        )}
                        <Form.Control
                          type="text"
                          placeholder="Enter Recipient Name Here"
                          value={host.hostName}
                          onChange={(e) =>
                            setInputValue(e.target.value, hostIndex)
                          }
                          onKeyPress={(e) =>
                            handleKeyPress(e, host.id, hostIndex)
                          }
                          className="flex-fill"
                        />
                      </td>
                      <td>
                        <Stack
                          direction="horizontal"
                          className="justify-content-between"
                        >
                          <span className="host-access-code-text">
                            {host.code.code}
                          </span>
                          <Button
                            variant="outline"
                            className="p-0"
                            disabled={refreshHostCodeId === host.id}
                            onClick={() =>
                              handleRefreshHostCode(host.id, hostIndex + 1)
                            }
                          >
                            <RefreshIcon
                              width="24"
                              height="24"
                              fill="currentColor"
                              className={clsx(
                                refreshHostCodeId === host.id &&
                                  'rotateClockwise'
                              )}
                            />
                          </Button>
                        </Stack>
                      </td>
                    </tr>
                  )
                })
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}
