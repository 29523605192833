import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Excel from 'exceljs'
import { saveAs } from 'file-saver'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { useAuth } from '../../hooks/useAuth'
import { useIncome } from '../../hooks/useIncome'
import { useTeam } from '../../hooks/useTeam'
import { useAppState } from '../../hooks/useAppState'

import { formatCurrency } from '../../utils/utils'
import { BOTTOM_LINE_LOGO_BASE64 } from '../../utils/constants'

export default function RunView() {
  const {
    state: {
      design: { name },
    },
  } = useAppState()
  const { userId } = useAuth()
  const { getHighProfileIncomesByTeamId, getOtherIncomesByTeamId } = useIncome()
  const { fetchTeam } = useTeam()
  const [highProfileIncomes, setHighProfileIncomes] = useState([])
  const [otherIncomes, setOtherIncomes] = useState([])
  const [numberOfPartners, setNumberOfPartners] = useState(null)
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    let isMounted = true
    Promise.all([
      getHighProfileIncomesByTeamId(userId),
      getOtherIncomesByTeamId(userId),
      fetchTeam(userId),
    ]).then((data) => {
      const incomesData = data[0]
      const otherIncomesData = data[1]
      const teamData = data[2]

      if (isMounted) {
        setHighProfileIncomes(incomesData.data.getHighProfileIncomes)
        setOtherIncomes(
          populateFiveYears(otherIncomesData.data.getOtherIncomes)
        )
        setNumberOfPartners(teamData.numberOfPartners)

        setDataLoaded(true)
      }
    })
    return () => {
      isMounted = false
    }
  }, [
    setHighProfileIncomes,
    setOtherIncomes,
    getHighProfileIncomesByTeamId,
    getOtherIncomesByTeamId,
    userId,
    fetchTeam,
  ])

  let clientNameList = []
  highProfileIncomes.forEach((clientIncome) => {
    if (!clientNameList.includes(clientIncome.target.displayName)) {
      clientNameList.push(clientIncome.target.displayName)
    }
  })
  let clientIncomeList = []
  clientNameList.forEach((clientName) => {
    clientIncomeList.push(
      highProfileIncomes
        .filter(
          (hpClientIncome) => hpClientIncome.target.displayName === clientName
        )
        .sort((a, b) => a.incomeYear - b.incomeYear)
    )
  })
  clientIncomeList = clientIncomeList.map((clientIncome) => {
    return populateFiveYears(clientIncome)
  })

  const allIncomes = [...highProfileIncomes, ...otherIncomes]
  const incomesYear1 = allIncomes.filter(
    (income) => income && income.incomeYear === 1
  )
  const totalProfitYear1 =
    incomesYear1.length > 0
      ? incomesYear1.reduce(
          (prevValue, curValue) => prevValue + curValue.profit,
          0
        )
      : null
  const incomesYear2 = allIncomes.filter(
    (income) => income && income.incomeYear === 2 && income.profit !== null
  )
  const totalProfitYear2 =
    incomesYear2.length > 0
      ? incomesYear2.reduce(
          (prevValue, curValue) => prevValue + curValue.profit,
          0
        )
      : null
  const incomesYear3 = allIncomes.filter(
    (income) => income && income.incomeYear === 3
  )
  const totalProfitYear3 =
    incomesYear3.length > 0
      ? incomesYear3.reduce(
          (prevValue, curValue) => prevValue + curValue.profit,
          0
        )
      : null
  const incomesYear4 = allIncomes.filter(
    (income) => income && income.incomeYear === 4
  )
  const totalProfitYear4 =
    incomesYear4.length > 0
      ? incomesYear4.reduce(
          (prevValue, curValue) => prevValue + curValue.profit,
          0
        )
      : null
  const incomesYear5 = allIncomes.filter(
    (income) => income && income.incomeYear === 5
  )
  const totalProfitYear5 =
    incomesYear5.length > 0
      ? incomesYear5.reduce(
          (prevValue, curValue) => prevValue + curValue.profit,
          0
        )
      : null

  const totalProfits = [
    totalProfitYear1,
    totalProfitYear2,
    totalProfitYear3,
    totalProfitYear4,
    totalProfitYear5,
  ]

  const profitPerPartners = [
    totalProfitYear1 !== null ? totalProfitYear1 / numberOfPartners : null,
    totalProfitYear2 !== null ? totalProfitYear2 / numberOfPartners : null,
    totalProfitYear3 !== null ? totalProfitYear3 / numberOfPartners : null,
    totalProfitYear4 !== null ? totalProfitYear4 / numberOfPartners : null,
    totalProfitYear5 !== null ? totalProfitYear5 / numberOfPartners : null,
  ]

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Your Profits</h2>
          <p>
            It’s now time to examine all results for this year. Put on your CFO
            hat and consider:
          </p>
          <p>How do things look?</p>
          <p>
            If desired, you can also download an Excel version of your financial
            results. To do so, click <strong>Download Financials</strong>.
          </p>
        </Col>
        <Col>
          <Stack direction="horizontal" className="align-items-start" gap={4}>
            <div className="d-none d-lg-flex" style={{ height: 460 }}>
              <div className="vr"></div>
            </div>
            <div className="flex-grow-1 w-100">
              <h2 className="text-success h3 fw-bold">View Financials</h2>
              <Table
                responsive="md"
                className="w-100 mt-4 align-middle run-table"
              >
                <thead className="bg-gray-100">
                  <tr>
                    <th>Client Name</th>
                    <th>Year 1</th>
                    <th>Year 2</th>
                    <th>Year 3</th>
                    <th>Year 4</th>
                    <th>Year 5</th>
                  </tr>
                </thead>
                <tbody>
                  {clientIncomeList.map((clientIncome, clientIncomeIndex) => (
                    <tr key={clientIncomeIndex.toString()}>
                      {
                        // Client Name column
                      }
                      <td>{clientNameList[clientIncomeIndex]}</td>
                      {clientIncome.map((income, incomeIndex) => {
                        // Income year column
                        if (income === null) {
                          return <td key={incomeIndex.toString()}>--</td>
                        } else {
                          return (
                            <td
                              key={incomeIndex.toString()}
                              className={clsx(
                                !isNaN(parseInt(income.profit)) &&
                                  parseInt(income.profit) < 0
                                  ? 'text-danger'
                                  : 'gray-500'
                              )}
                            >
                              {income.profit === null
                                ? '--'
                                : formatCurrency(income.profit)}
                            </td>
                          )
                        }
                      })}
                    </tr>
                  ))}
                  <tr>
                    <td>All Other Engagements</td>
                    {otherIncomes.map((income, incomeIndex) =>
                      income ? (
                        <td
                          key={incomeIndex.toString()}
                          className={clsx(
                            !isNaN(parseInt(income.profit)) &&
                              parseInt(income.profit) < 0
                              ? 'text-danger'
                              : 'gray-500'
                          )}
                        >
                          {income === null || income.profit === null
                            ? '--'
                            : formatCurrency(income.profit)}
                        </td>
                      ) : (
                        <td key={incomeIndex.toString()}>--</td>
                      )
                    )}
                  </tr>
                </tbody>
                <tfoot>
                  <tr className="border-top fw-bold">
                    <td>Total Profits/(Losses)</td>
                    {totalProfits.map((totalProfit, totalProfitIndex) => (
                      <td
                        key={totalProfitIndex.toString()}
                        className={clsx(
                          !isNaN(parseInt(totalProfit)) &&
                            parseInt(totalProfit) < 0
                            ? 'text-danger'
                            : 'gray-500'
                        )}
                      >
                        {totalProfit === null
                          ? '--'
                          : formatCurrency(totalProfit)}
                      </td>
                    ))}
                  </tr>
                  <tr className="border-top">
                    <td>Number of Partners</td>
                    <td>{totalProfitYear1 ? numberOfPartners : '--'}</td>
                    <td>{totalProfitYear2 ? numberOfPartners : '--'}</td>
                    <td>{totalProfitYear3 ? numberOfPartners : '--'}</td>
                    <td>{totalProfitYear4 ? numberOfPartners : '--'}</td>
                    <td>{totalProfitYear5 ? numberOfPartners : '--'}</td>
                  </tr>
                  <tr className="border-top fw-bold">
                    <td>Profit/(Losses) Per Partner</td>
                    {profitPerPartners.map(
                      (profitPerPartner, profitPerPartnersIndex) =>
                        profitPerPartner !== null ? (
                          <td
                            key={profitPerPartnersIndex.toString()}
                            className={clsx(
                              profitPerPartner !== null &&
                                parseInt(profitPerPartner) < 0
                                ? 'text-danger'
                                : 'gray-500'
                            )}
                          >
                            {formatCurrency(profitPerPartner)}
                          </td>
                        ) : (
                          <td key={profitPerPartnersIndex.toString()}>--</td>
                        )
                    )}
                  </tr>
                </tfoot>
              </Table>

              <Button
                variant="outline-success"
                className="rounded-pill d-block mx-auto mt-5"
                disabled={!dataLoaded}
                onClick={() =>
                  saveExcel(
                    name,
                    clientNameList,
                    clientIncomeList,
                    otherIncomes,
                    totalProfits,
                    totalProfits.map((totalProfit) =>
                      totalProfit !== null ? numberOfPartners : null
                    ),
                    profitPerPartners
                  )
                }
              >
                Download Financials (.xlsx)
              </Button>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}

const saveExcel = async (
  teamName = '',
  clientNameList = [],
  clientIncomeList = [],
  otherIncome = [],
  totalProfits = [],
  numberOfPartners = [],
  profitPerPartners = []
) => {
  if (clientNameList.length !== clientIncomeList.length) {
    console.log('Inconsistent data')
    throw new Error('Inconsistent data')
  }

  const outputFileName = `Bottom Line Income Statement${
    teamName !== '' ? ' - '.concat(teamName) : ''
  }.xlsx`
  const workSheetName = 'Your Firm'
  const workbook = new Excel.Workbook()
  const worksheet = workbook.addWorksheet(workSheetName)
  worksheet.columns = [
    {
      header: '',
      key: 'clientName',
      width: 21,
      style: {
        font: { name: 'Arial' },
      },
    },
    {
      header: 'Year 1',
      key: 'year1',
      width: 14,
      style: {
        font: { name: 'Arial' },
        numFmt: '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)',
      },
    },
    {
      header: 'Year 2',
      key: 'year2',
      width: 14,
      style: {
        font: { name: 'Arial' },
        numFmt: '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)',
      },
    },
    {
      header: 'Year 3',
      key: 'year3',
      width: 14,
      style: {
        font: { name: 'Arial' },
        numFmt: '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)',
      },
    },
    {
      header: 'Year 4',
      key: 'year4',
      width: 14,
      style: {
        font: { name: 'Arial' },
        numFmt: '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)',
      },
    },
    {
      header: 'Year 5',
      key: 'year5',
      width: 14,
      style: {
        font: { name: 'Arial' },
        numFmt: '_($* #,##0_);_($* (#,##0);_($* "-"??_);_(@_)',
      },
    },
  ]

  // Remove default header row because we need empty rows at top for logo
  worksheet.spliceRows(1, 1, [])

  // Add 6 empty rows. This space is for the logo
  Array(6)
    .fill('')
    .forEach(() => worksheet.addRow({}))

  // Add the logo
  const logo = workbook.addImage({
    base64: BOTTOM_LINE_LOGO_BASE64,
    extension: 'png',
  })
  worksheet.addImage(logo, {
    tl: { col: 0, row: 0 },
    br: { col: 2.75, row: 5 },
  })

  // Add the column headers
  worksheet.getCell('A7').value = ''
  worksheet.getCell('B7').value = 'Year 1'
  worksheet.getCell('C7').value = 'Year 2'
  worksheet.getCell('D7').value = 'Year 3'
  worksheet.getCell('E7').value = 'Year 4'
  worksheet.getCell('F7').value = 'Year 5'

  // Loop through to add data
  clientIncomeList.forEach((income, incomeIndex) => {
    worksheet.addRow({
      clientName: clientNameList[incomeIndex],
      year1: income[0] !== null ? income[0].profit : 0,
      year2: income[1] !== null ? income[1].profit : 0,
      year3: income[2] !== null ? income[2].profit : 0,
      year4: income[3] !== null ? income[3].profit : 0,
      year5: income[4] !== null ? income[4].profit : 0,
    })
  })

  // Add two empty rows
  worksheet.addRow({})
  worksheet.addRow({})

  // Add "All other engagements" row
  worksheet.addRow({
    clientName: 'All other engagements',
    year1: otherIncome[0] ? otherIncome[0].profit : 0,
    year2: otherIncome[1] ? otherIncome[1].profit : 0,
    year3: otherIncome[2] ? otherIncome[2].profit : 0,
    year4: otherIncome[3] ? otherIncome[3].profit : 0,
    year5: otherIncome[4] ? otherIncome[4].profit : 0,
  })

  // Add one empty row
  worksheet.addRow({})

  // Add "Total profits" row
  worksheet.addRow({
    clientName: 'Total profits',
    year1: typeof totalProfits[0] === 'number' ? totalProfits[0] : 0,
    year2: typeof totalProfits[1] === 'number' ? totalProfits[1] : 0,
    year3: typeof totalProfits[2] === 'number' ? totalProfits[2] : 0,
    year4: typeof totalProfits[3] === 'number' ? totalProfits[3] : 0,
    year5: typeof totalProfits[4] === 'number' ? totalProfits[4] : 0,
  })

  // Add "Number of partners" row
  worksheet.addRow({
    clientName: 'Number of partners',
    year1: typeof numberOfPartners[0] === 'number' ? numberOfPartners[0] : '-',
    year2: typeof numberOfPartners[1] === 'number' ? numberOfPartners[1] : '-',
    year3: typeof numberOfPartners[2] === 'number' ? numberOfPartners[2] : '-',
    year4: typeof numberOfPartners[3] === 'number' ? numberOfPartners[3] : '-',
    year5: typeof numberOfPartners[4] === 'number' ? numberOfPartners[4] : '-',
  })
  // Set style for "Number of partners" cell to General
  worksheet.eachRow({ includeEmpty: true }, function (row, rowNumber) {
    if (row.getCell(1).value === 'Number of partners') {
      row.eachCell({ includeEmpty: true }, function (cell, cellNumber) {
        cell.numFmt = 'General'
      })
    }
  })

  // Add "Profit per partner" row
  worksheet.addRow({
    clientName: 'Profit per partner',
    year1: typeof profitPerPartners[0] === 'number' ? profitPerPartners[0] : 0,
    year2: typeof profitPerPartners[1] === 'number' ? profitPerPartners[1] : 0,
    year3: typeof profitPerPartners[2] === 'number' ? profitPerPartners[2] : 0,
    year4: typeof profitPerPartners[3] === 'number' ? profitPerPartners[3] : 0,
    year5: typeof profitPerPartners[4] === 'number' ? profitPerPartners[4] : 0,
  })

  // Write Excel content
  const buf = await workbook.xlsx.writeBuffer()

  // Download file
  saveAs(new Blob([buf]), outputFileName)
}

/**
 * Create an array of 5 years of income.
 * Empty year is filled with null.
 *
 * @param {[]} arr
 * @returns
 */
const populateFiveYears = (arr) => {
  if (arr.length === 0) {
    return Array(5).fill(null)
  } else if (arr.length === 1) {
    if (arr[0].incomeYear === 1) {
      return [...arr, ...Array(5 - arr.length).fill(null)]
    } else if (arr[0].incomeYear > 1) {
      return [
        ...Array(arr[0].incomeYear - 1).fill(null),
        ...arr,
        ...Array(5 - arr[0].incomeYear).fill(null),
      ]
    }
  } else {
    if (arr[arr.length - 1].incomeYear === 2) {
      return [...arr, ...Array(5 - arr[arr.length - 1].incomeYear).fill(null)]
    } else if (arr[0].incomeYear > 1) {
      return [
        ...Array(arr[0].incomeYear - 1).fill(null),
        ...arr,
        ...Array(5 - arr[arr.length - 1].incomeYear).fill(null),
      ]
    }
    return [...arr, ...Array(5 - arr.length).fill(null)]
  }
}
