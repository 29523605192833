import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  mergeTeams as mergeTeamsMutation,
  cognitoUpdateUser as cognitoUpdateUserMutation,
} from '../graphql/mutations'

export const useMerge = () => {
  const mergeTeams = useCallback(
    async (teamOneId, teamTwoId, offeringId, year) => {
      return API.graphql(
        graphqlOperation(mergeTeamsMutation, {
          input: {
            teamOneId,
            teamTwoId,
            offeringId,
            year,
          },
        })
      )
    },
    []
  )

  const moveTeam = useCallback((groupName, userId, newUserId) => {
    const userName = `${groupName}${userId}`

    return API.graphql(
      graphqlOperation(cognitoUpdateUserMutation, {
        input: { userName, newUserId },
      })
    )
  }, [])

  return {
    mergeTeams,
    moveTeam,
  }
}
