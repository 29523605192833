import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import HelpButton from '../../components/HelpButton'

export default function InputNumber({
  as = Row,
  id,
  label,
  helpTitle,
  helpContent,
  min,
  max,
  setMaxAttribute = true,
  value,
  setValue,
  isInvalid = false,
  setIsInvalid,
  errorText = '',
  className,
  labelClassName = '',
  labelBreakpointClassName = '',
  disabled = false,
  dollarSign = false,
  formatNumber = false,
}) {
  const rowColStructure = as === Row
  const defaultLabelBreakpointClassName = rowColStructure
    ? 'col-xs-4 col-md-7 col-lg-5 col-xl-4'
    : ''

  const handleInputRangeChange = (e) => {
    setIsInvalid && setIsInvalid(false)

    const value = formatNumber
      ? e.target.value.replace(/,/g, '').trim()
      : e.target.value

    if (value !== '') {
      const v = parseInt(value)

      if (v < min || v > max) {
        return
      }
    }

    if (formatNumber) {
      setValue(
        value !== ''
          ? new Intl.NumberFormat('en-US', {
              style: 'decimal',
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(parseInt(value))
          : ''
      )
    } else {
      setValue(e.target.value)
    }
  }

  return (
    <Form.Group
      as={as}
      className={clsx(
        'position-relative',
        errorText !== '' ? 'align-items-start' : 'align-items-center',
        className
      )}
      controlId={id}
    >
      <Form.Label
        column={rowColStructure}
        className={clsx(
          labelBreakpointClassName
            ? labelBreakpointClassName
            : defaultLabelBreakpointClassName,
          labelClassName
        )}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Form.Label>
      <div className={clsx(rowColStructure && 'col')}>
        <Stack direction="horizontal" gap={3}>
          <div className="w-100">
            <div className="input-group">
              {dollarSign ? (
                <span className="ms-sm-n4 pe-0 input-group-text bg-white border-0">
                  $&nbsp;
                </span>
              ) : null}
              <Form.Control
                type={formatNumber ? 'text' : 'number'}
                min={min}
                max={setMaxAttribute ? max : undefined}
                inputMode="numeric"
                pattern="\d*"
                value={value}
                onChange={handleInputRangeChange}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                disabled={disabled}
                className={clsx(dollarSign ? 'rounded' : '')}
                isInvalid={isInvalid}
              />
              {errorText !== '' && (
                <Form.Control.Feedback type="invalid">
                  {errorText}
                </Form.Control.Feedback>
              )}
            </div>
          </div>
          {(helpTitle || helpContent) && (
            <HelpButton title={helpTitle} content={helpContent} />
          )}
        </Stack>
      </div>
    </Form.Group>
  )
}
