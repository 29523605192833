import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Skyline from '../../components/Skyline'
import { ReactComponent as TrophyIcon } from '../../images/icons/trophy-icon.svg'

import './style.css'

import LeaderboardOverall from '../../components/LeaderboardOverall'
import LeaderboardTotalProfit from '../../components/LeaderboardTotalProfit'
import LeaderboardHighProfileRevenue from '../../components/LeaderboardHighProfileRevenue'
import LeaderboardEmployeeSatisfaction from '../../components/LeaderboardEmployeeSatisfaction'
import LeaderboardClientSatisfaction from '../../components/LeaderboardClientSatisfaction'
import HelpButton from '../../components/HelpButton'

const Leaderboards = () => {
  const [option, setOption] = useState('overall')

  const handleChange = (e) => {
    setOption(e.target.value)
  }

  return (
    <Container fluid as="main" className="p-0 main overflow-hidden">
      <div className="leaderboard-help-button">
        <HelpButton
          title={'Leaderboards'}
          content={`<p><strong>Leaderboards</strong></p>
            <p>There are five leaderboards in total:</p>
            <ol>
              <li>Overall</li>
              <li>Total Profit</li>
              <li>Client Satisfaction</li>
              <li>Employee Satisfaction</li>
              <li>High-Profile Revenue</li>
            </ol>
            <p>To view a leaderboard, select it from the drop-down menu.</p>
            <p><strong>Where do the leaderboard rankings/values come from?</strong><p>
            <p>A firm's ranking on the Overall leaderboard is calculated from their weighted rankings on the other four leaderboards. Weightings are as follows:</p>
            <ul class="list-unstyled">
              <li>Total Profit (30%)</li>
              <li>Client Satisfaction (25%)</li>
              <li>Employee Satisfaction (25%)</li>
              <li>High-Profile Revenue (20%)</li>
            </ul>
            <p>The Total Profit leaderboard shows each firm's overall profit.</p>
            <p>The Client Satisfaction leaderboard shows each firm's level of client satisfaction.
            The value stems from the results shown when executing engagements.</p>
            <p>The Employee Satisfaction leaderboard shows each firm's level of employee satisfaction.
            The value stems from the firm's operating parameters, particularly Employee Development, Compensation and Staffing.</p>
            <p>The High-Profile Revenue leaderboard shows the revenue generated from each firm’s high-profile engagements.</p>
            <p><strong>What happens when two firms merge?</strong></p>
            <p>When two firms merge, there is a modification placed on the new firm's values for both the Client Satisfaction
            and Employee Satisfaction leaderboards. This is because change can be disruptive, and it may negatively impact
            both the employee and the client experience. But fear not: just as levels of satisfaction will typically
            stabilize as time goes by, this modification will fade. For each year in the sim after the merge, you'll see
            the modification diminish.</p>`}
        />
      </div>
      <Skyline />
      <div className="h-road"></div>
      <div className="grass">
        <div className="tree-3" />
        <Row className="mb-4">
          <Col
            xs={{ span: 12 }}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            lg={{ span: 6, offset: 3 }}
            className="relative"
          >
            <div className="leaderboard-billboard">
              <h1 className="text-center text-primary fw-bold">
                <TrophyIcon width={96} className="leaderboard-trophy-icon" />
                Leaderboards
              </h1>
              <hr className="leaderboard-header" />
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="12" md="4">
                  Select a leaderboard:
                </Form.Label>
                <Col sm="12" md="8">
                  <Form.Select
                    aria-label="Leaderboard"
                    defaultValue="overall"
                    onChange={handleChange}
                  >
                    <option>Which leaderboard would you like to view?</option>
                    <option value="overall">Overall</option>
                    <option value="totalProfit">Total Profit</option>
                    <option value="clientSatisfaction">
                      Client Satisfaction
                    </option>
                    <option value="employeeSatisfaction">
                      Employee Satisfaction
                    </option>
                    <option value="highProfileRevenue">
                      High-Profile Revenue
                    </option>
                  </Form.Select>
                </Col>
              </Form.Group>

              {option === 'overall' && <LeaderboardOverall />}
              {option === 'totalProfit' && <LeaderboardTotalProfit />}
              {option === 'highProfileRevenue' && (
                <LeaderboardHighProfileRevenue />
              )}
              {option === 'employeeSatisfaction' && (
                <LeaderboardEmployeeSatisfaction />
              )}
              {option === 'clientSatisfaction' && (
                <LeaderboardClientSatisfaction />
              )}
              <hr className="leaderboard-footer1" />
              <hr className="leaderboard-footer2" />
            </div>
            <div className="tree-2" />
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default Leaderboards
