import { useState, useCallback, useContext, createContext } from 'react'
import { Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid'

import { useAppState } from './useAppState'

const AuthContext = createContext()

export function AuthProvider({ children }) {
  const [user, setUser] = useState(false)
  const { dispatch } = useAppState()

  // Reset app state
  const resetAppState = useCallback(() => {
    dispatch({ type: 'reset-state' })
  }, [dispatch])

  const getAuthenticatedUser = useCallback(async () => {
    return Auth.currentAuthenticatedUser({ bypassCache: true })
      .then((u) => {
        // Set my identify
        dispatch({ type: 'set-me', payload: uuidv4() })

        setUser(u)

        return u
      })
      .catch(() => {
        resetAppState()
        setUser(null)
      })
  }, [resetAppState, dispatch])

  const signIn = async (accessCode) => {
    return Auth.signIn(accessCode, accessCode)
      .then((u) => {
        // Set my identify
        dispatch({ type: 'set-me', payload: uuidv4() })

        setUser(u)

        return u
      })
      .catch((err) => {
        resetAppState()
        setUser(null)
        throw err
      })
  }

  const signOut = async (callback) => {
    try {
      await Auth.signOut()
      setUser(null)

      if (callback) {
        callback()
      }
    } catch (err) {
      throw err
    } finally {
      resetAppState()
    }
  }

  const getGroup = (u) => {
    return u ? u.signInUserSession.accessToken.payload['cognito:groups'] : false
  }

  const isOwnerCheck = (u) => {
    return u
      ? u.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Owner'
        )
      : false
  }

  const isHostCheck = (u) => {
    return u
      ? u.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Host'
        )
      : false
  }

  const isLeaderCheck = (u) => {
    return u
      ? u.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Leader'
        )
      : false
  }

  const isTeamCheck = (u) => {
    return u
      ? u.signInUserSession.accessToken.payload['cognito:groups'].includes(
          'Team'
        )
      : false
  }

  const getUserId = useCallback((u) => {
    return u ? u.attributes['custom:userid'] : null
  }, [])

  /**
   * Get user's custom attribute
   */
  const getUserCustomAttribute = useCallback((u, attrName) => {
    return u ? u.attributes[`custom:${attrName}`] : null
  }, [])

  const group = getGroup(user)
  const isOwner = isOwnerCheck(user)
  const isHost = isHostCheck(user)
  const isLeader = isLeaderCheck(user)
  const isTeam = isTeamCheck(user)
  const userId = user ? parseInt(user.attributes['custom:userid']) : null

  return (
    <AuthContext.Provider
      value={{
        user,
        group,
        userId,
        isOwner,
        isHost,
        isLeader,
        isTeam,
        signIn,
        signOut,
        getAuthenticatedUser,
        getUserId,
        getUserCustomAttribute,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
