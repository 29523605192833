import { useEffect, useState, useCallback, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'

import { useAppState } from '../../hooks/useAppState'
import { useLeaderboard } from '../../hooks/useLeaderboard'
import { useTeam } from '../../hooks/useTeam'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

import TeamDataView from '../TeamDataView'

const LeaderboardOverall = () => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const {
    state: { hostMergeTeams },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const {
    leaderboardHPRevenue,
    leaderboardEmployeeSatisfaction,
    leaderboardClientSatisfaction,
    getTotalProfit,
  } = useLeaderboard()
  const [list, setList] = useState([])
  const [teamId, setTeamId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const isMounted = useRef(true)

  const handleClose = () => {
    setShowModal(false)
  }

  const loadLeaderboards = useCallback(
    async (offeringId) => {
      const totalProfitPromise = getTotalProfit(offeringId)
      const clientSatisfactionPromise =
        leaderboardClientSatisfaction(offeringId)
      const employeeSatisfactionPromise =
        leaderboardEmployeeSatisfaction(offeringId)
      const hpRevenuePromise = leaderboardHPRevenue(offeringId)

      return Promise.all([
        totalProfitPromise,
        clientSatisfactionPromise,
        employeeSatisfactionPromise,
        hpRevenuePromise,
      ]).then((values) => {
        // Total profit ranks
        const totalProfitRanks = values[0].data.leaderboardTotalProfit.map(
          (v, rank) => {
            return {
              ...v,
              rank: (rank + 1) * 0.3,
            }
          }
        )
        // Client satisfaction ranks
        const clientSatisRanks =
          values[1].data.leaderboardClientSatisfaction.map((v, rank) => {
            return {
              ...v,
              rank: (rank + 1) * 0.25,
            }
          })
        // Employee satisfaction ranks
        const employeeSatisRanks =
          values[2].data.leaderboardEmployeeSatisfaction.map((v, rank) => {
            return {
              ...v,
              rank: (rank + 1) * 0.25,
            }
          })
        // High-Profile Revenue ranks
        const hpRevenueRanks = values[3].data.leaderboardHPRevenue.map(
          (v, rank) => {
            return {
              ...v,
              rank: (rank + 1) * 0.2,
            }
          }
        )
        // console.log('totalProfitRanks', totalProfitRanks)
        // console.log('clientSatisRanks', clientSatisRanks)
        // console.log('employeeSatisRanks', employeeSatisRanks)
        // console.log('hpRevenueRanks', hpRevenueRanks)

        let teams = []
        for (let i = 0; i < totalProfitRanks.length; i++) {
          const {
            teamId,
            teamName,
            totalProfit,
            rank: tpRank,
          } = totalProfitRanks[i]
          const { rank: csRank } = clientSatisRanks.find(
            (c) => c.teamId === teamId
          ) ?? { rank: 0 }
          const { rank: esRank } = employeeSatisRanks.find(
            (c) => c.teamId === teamId
          ) ?? { rank: 0 }
          const { rank: hprRank } = hpRevenueRanks.find(
            (c) => c.teamId === teamId
          )

          // console.log(
          //   `teamId (${teamId}) :: teamName (${teamName}) :: tpRank (${tpRank}) :: csRank (${csRank}) :: esRank (${esRank}) :: hprRank (${hprRank}) :: Total Rank (${getRanking(
          //     tpRank,
          //     csRank,
          //     esRank,
          //     hprRank
          //   )})`
          // )
          teams.push({
            teamId: teamId,
            teamName: teamName,
            totalProfit: totalProfit,
            rank: getRanking(tpRank, csRank, esRank, hprRank),
          })
        }

        if (teams.length > 0) {
          /**
           * Team with the lowest total ranking value is the best/highest
           * ranking team on the Leaderboard.
           *
           * In the event of tie, fall back to use the highest Total Profit
           * to determine which firm ranks higher on the Leaderboard.
           */
          const sortedList = teams.sort((teamA, teamB) =>
            teamA.rank === teamB.rank
              ? teamB.totalProfit - teamA.totalProfit
              : teamA.rank - teamB.rank
          )
          // console.log('sortedList', sortedList)

          if (isMounted.current) {
            setList(sortedList)
          }
        }
      })
    },
    [
      getTotalProfit,
      leaderboardClientSatisfaction,
      leaderboardEmployeeSatisfaction,
      leaderboardHPRevenue,
    ]
  )

  useEffect(() => {
    isMounted.current = true
    if (offeringId === null || offeringId === -1) {
      return
    }

    setLoading(true)
    loadLeaderboards(offeringId)
      .catch((err) => {
        console.log('Unable to load the overall leaderboard', err)
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
    return () => {
      isMounted.current = false
    }
  }, [offeringId, loadLeaderboards])

  const handleViewTeam = (teamId) => {
    setTeamId(teamId)
    setShowModal(true)
  }

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('LeaderboardOverall')
    ) {
      setLoading(true)
      loadLeaderboards(offeringId)
        .catch((err) => {
          console.log('Unable to load the overall leaderboard', err)
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false)
          }
          dispatchNC({
            type: 'host-merge-teams-executed',
            payload: {
              ...hostMergeTeams.message,
              in: 'LeaderboardOverall',
            },
          })
        })
    }
  }, [hostMergeTeams, loadLeaderboards, dispatchNC, offeringId])

  return (
    <>
      <ViewDesign show={showModal} handleClose={handleClose} teamId={teamId} />
      <Table responsive className="w-100 table leaderboard-bb-table">
        <thead>
          <tr>
            <th>Pos</th>
            <th>Firm Name</th>
            <th width="20%">Firm Design</th>
          </tr>
        </thead>
        <tbody>
          {/**
           * Empty row with a little bit of paddings to fill in the gap
           */}
          <tr>
            <td colSpan={3} className="py-1"></td>
          </tr>
          {loading ? (
            <tr>
              {Array(3)
                .fill('')
                .map((_, index) => (
                  <td key={index.toString()}>
                    <Spinner
                      as="div"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="secondary"
                    />
                  </td>
                ))}
            </tr>
          ) : (
            list.map((team, index) => (
              <tr key={index.toString()}>
                <td>{index + 1}</td>
                <td>{team.teamName}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    className="rounded-pill"
                    size={'sm'}
                    onClick={() => handleViewTeam(team.teamId)}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  )
}

const ViewDesign = ({ show, teamId, handleClose }) => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const { getTeamByOfferingId } = useTeam()
  const [team, setTeam] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    if (offeringId === null || offeringId === -1 || teamId === null) {
      return
    }
    setLoading(true)
    getTeamByOfferingId(offeringId, teamId)
      .then((teamData) => {
        if (isMounted) {
          setTeam(teamData.data.getTeamByOfferingId)
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    return () => {
      isMounted = false
    }
  }, [offeringId, teamId, getTeamByOfferingId])

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName="modal-90w"
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      size={'xl'}
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="h5 fw-bold">Team Snapshot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          team && <TeamDataView team={team} />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-success"
          className="rounded-pill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

const getRanking = (tpRank, csRank, esRank, hprRank) => {
  return tpRank + csRank + esRank + hprRank
}

export default LeaderboardOverall
