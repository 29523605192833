import { useState, useEffect, useCallback } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { useNavigate } from 'react-router-dom'

import InputText from '../../components/InputText'
import InputNumber from '../../components/InputNumber'
import InputRange from '../../components/InputRange'
import InputMultipleChoices from '../../components/InputMultipleChoices'
import InputTriangle from '../../components/InputTriangle'
import ModalConfirm from '../../components/ModalConfirm'
import LoadingData from '../../components/LoadingData'
import BlsModal from '../../components/BlsModal'

import { useAuth } from '../../hooks/useAuth'
import { useAppState } from '../../hooks/useAppState'
import { useTeam } from '../../hooks/useTeam'
import { useNotification } from '../../hooks/useNotification'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import {
  NOTIF_CH_FIRM_DESIGN,
  NOTIF_CH_FIRM_DESIGN_CONFIRM,
  NOTIF_CH_FIRM_DESIGN_WHAT_OUR_SETTINGS,
} from '../../utils/constants'

export default function DesignFirm() {
  const {
    state: {
      me,
      year,
      design,
      rtNotification,
      offering: { offeringId },
    },
    dispatch,
  } = useAppState()
  const { userId } = useAuth()
  const { updateTeam, fetchTeam } = useTeam()
  const navigate = useNavigate()
  const {
    state: {
      teamConfirmedDesign,
      teamDesignWhatOurSettings,
      teamDesignOurSettings,
    },
    sendRTNotification,
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const [isConfirming, setIsConfirming] = useState(false)
  const { dispatch: notificationDispatch } = useNotification()
  const [confirming, setConfirming] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataSent, setDataSent] = useState(false)
  const [otherMemberConfirmShow, setOtherMemberConfirmShow] = useState(false)
  const [errorModalShow, setErrorModalShow] = useState(false)

  const setInputValue = (type, value) => {
    // Update the app state
    // Industries value is handled inside its own component

    if (type !== 'set-industry') {
      dispatch({ type, payload: value })
    }

    // Send live updated value
    const statusInput = {
      to: rtNotification.channel,
      message: JSON.stringify({ teamId: userId, type, value }),
      updater: me,
      type: NOTIF_CH_FIRM_DESIGN,
    }

    // API.graphql(graphqlOperation(publishStatusUpdate, statusInput))
    sendRTNotification(statusInput).catch((err) => {
      console.log('sendRTNotification failed', err)
    })
  }

  /**
   * Fetch the team info whenever we receive this notification
   * that the team has confirmed their design
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1 || userId === null) {
      return
    }

    if (
      teamConfirmedDesign.enabled &&
      teamConfirmedDesign.message &&
      teamConfirmedDesign.message.teamId === userId
    ) {
      setLoading(true)

      fetchTeam(userId)
        .then((team) => {
          if (team) {
            // Save team data into the app state
            dispatch({ type: 'set-firm', payload: team })
          }
        })
        .finally(() => {
          setLoading(false)
          setOtherMemberConfirmShow(true)
        })

      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [
    dispatchNC,
    fetchTeam,
    teamConfirmedDesign.enabled,
    teamConfirmedDesign.message,
    offeringId,
    userId,
    dispatch,
  ])

  const pingTeamMembersForTeamData = useCallback(
    async (teamId) => {
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId }),
        updater: me,
        type: NOTIF_CH_FIRM_DESIGN_WHAT_OUR_SETTINGS,
      }

      sendRTNotification(statusInput).catch((err) => {
        console.log('sendRTNotification failed', err)
      })
    },
    [me, rtNotification.channel, sendRTNotification]
  )

  /**
   * Send back design settings whenever we receive this notification when a
   * member of the same team has requested most up-to-date design data
   */
  useEffect(() => {
    if (userId === null) {
      return
    }

    if (teamDesignWhatOurSettings.enabled && !dataSent) {
      if (teamDesignWhatOurSettings.message) {
        try {
          const { teamId } = teamDesignWhatOurSettings.message

          // Only members of the same team should show this notification
          if (teamId !== userId) {
            return
          }

          // Don't send it if the design was already confirmed
          if (design.confirm) {
            return
          }

          setDataSent(true)
          dispatchNC({ type: 'team-design-what-our-settings-executed' })

          // Send back the design setting
          const statusInput = {
            to: rtNotification.channel,
            message: JSON.stringify({ teamId, design }),
            updater: me,
            type: NOTIF_CH_FIRM_DESIGN_WHAT_OUR_SETTINGS,
          }

          sendRTNotification(statusInput).catch((err) => {
            console.log('sendRTNotification failed', err)
          })
        } catch (err) {
          console.log('Unable to parse oobClients JSON.', err)
        }
      }
    }
  }, [
    design,
    teamDesignWhatOurSettings,
    notificationDispatch,
    userId,
    rtNotification.channel,
    me,
    sendRTNotification,
    dispatchNC,
    dataSent,
  ])

  useEffect(() => {
    if (userId === null) {
      return
    }
    const _teamDesignOurSettings = { ...teamDesignOurSettings }

    if (_teamDesignOurSettings.enabled) {
      dispatchNC({ type: 'team-design-our-settings-executed' })

      if (_teamDesignOurSettings.message) {
        try {
          const { teamId, design } = _teamDesignOurSettings.message

          // Only members of the same team should process this
          if (teamId !== userId) {
            return
          }

          // Don't send it if already there's no design setting
          if (!design) {
            return
          }

          // Save team data into the app state
          dispatch({ type: 'set-firm', payload: design })
        } catch (err) {
          console.log('Unable to parse oobClients JSON.', err)
        }
      }
    }
  }, [teamDesignOurSettings, dispatch, dispatchNC, userId])

  /**
   * Refetch team info whenenver the user visits this page
   */
  useEffect(() => {
    if (userId === null || userId === -1) {
      return
    }

    setLoading(true)

    fetchTeam(userId)
      .then((team) => {
        if (team) {
          if (team.confirm) {
            // Save team data into the app state if the team has confirmed
            // their design before
            dispatch({ type: 'set-firm', payload: team })
          } else {
            // Try to ping the other team member to get the current design data
            // being held in their app state
            pingTeamMembersForTeamData(userId)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [dispatch, fetchTeam, userId, pingTeamMembersForTeamData])

  const handleConfirmDesign = async () => {
    if (userId === null) {
      console.log('Cannot confirm the team design :: userId not found.')
      return
    }

    try {
      setIsConfirming(true)

      // Sanitize text inputs to remove leading and trailing whitespace
      let _design = { ...design }
      _design.name = _design.name.trim()
      _design.tagline = _design.tagline.trim()
      _design.values = _design.values.trim()

      // yearConfirm
      const yearConfirm = _design.confirm ? true : false

      // Update team in the database
      const teamUpdateInput = {
        id: parseInt(userId),
        ..._design,
        industries: JSON.stringify(design.industries),
        lobMix: JSON.stringify(design.lobMix),
        confirm: true,
        yearConfirm,
      }
      await updateTeam(teamUpdateInput)

      // Broadcast real-time notification
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId: userId }),
        updater: me,
        type: NOTIF_CH_FIRM_DESIGN_CONFIRM,
      }

      sendRTNotification(statusInput).catch((err) => {
        console.log('sendRTNotification failed', err)
      })

      // Can turn off isConfirming now
      setIsConfirming(false)

      if (yearConfirm) {
        dispatch({ type: 'set-yearConfirm', payload: true })
      }

      // Set confirm design state
      dispatch({ type: 'set-confirm' })

      // Show a notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Woohoo!`,
          body: `You've just locked in your firm design.`,
          time: new Date().toLocaleTimeString(),
          type: 'success',
          op: { delay: 10000, autohide: true },
        },
      })

      // Navigate to the Run page
      navigate('/run', { replace: true })
    } catch (err) {
      console.log('Update team failed', err)
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Sorry!`,
          body: `There was an issue with confirming your design. Please try again!`,
          time: new Date().toLocaleTimeString(),
          type: 'danger',
          op: { delay: 1000, autohide: false },
        },
      })
      setIsConfirming(false)
    }
  }

  return (
    <>
      <ModalConfirm
        title="Hang on...are you happy with your firm’s design?"
        body={`<p>Ensure that you’re confident in your design before moving forward, as this will lock in your firm’s operating parameters. (You’ll be able to adjust them in the future, but only by a small amount each year. After all, change takes time!)</p><p>Lock in your firm’s design?</p>`}
        show={confirming}
        headerTextColor="text-primary"
        handleClose={() => setConfirming(false)}
        handleConfirm={() => {
          setConfirming(false)
          handleConfirmDesign()
        }}
      />
      <LoadingData show={loading} />
      <BlsModal
        title="Confirm Design"
        body={`Your firm design is confirmed! Proceed to the <strong>Run Your Firm</strong> page.`}
        show={otherMemberConfirmShow}
        setShow={setOtherMemberConfirmShow}
        compact={true}
      />
      <BlsModal
        title="Design Your Firm"
        body="You must enter a name for your firm and the number of partners must be greater than 0."
        show={errorModalShow}
        setShow={setErrorModalShow}
        compact={true}
        headerTextColor="text-danger"
      />
      <Container as="main" className="pb-5 main">
        <Row className="mb-4">
          <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
            <h1 className="text-center text-primary fw-bold">
              Design Your Firm
            </h1>
            <div className="shadow-plain px-3 py-2 mt-4 mb-5 rounded">
              Use this page to design your firm throughout the simulation. After
              making changes, click <strong>Confirm Design</strong> to lock your
              selections in.
            </div>
          </Col>
        </Row>
        {design.confirm !== null && (
          <>
            <Form as={Row} className="gx-0">
              <Col md={5}>
                <h5 className="text-primary fw-bold">Identity</h5>
                <InputText
                  id="firmName"
                  className="mb-3"
                  label="Firm Name:"
                  placeholder="Enter firm name here"
                  helpTitle="Firm Name"
                  helpContent={`Need help? Many firms are named after a founding partner, or they combine the initials of several partners. However, it’s ultimately up to you how you want to be known in the marketplace!`}
                  value={design.name}
                  disabled={design.confirm}
                  setValue={(value) => setInputValue('set-firm-name', value)}
                  onBlur={() =>
                    setInputValue('set-firm-name', design.name.trim())
                  }
                />
                <InputText
                  id="firmTagline"
                  className="mb-3"
                  label="Firm Tagline:"
                  placeholder="Enter firm tagline here"
                  helpTitle="Firm Tagline"
                  helpContent={`Your tagline can be thought-provoking, or simple and straightforward, and it can help you to stand out in the bid process. To write it, describe your firm in one sentence or phrase (e.g., “Our vision is to be the first-choice advisor to middle market leaders globally.”).`}
                  value={design.tagline}
                  disabled={design.confirm}
                  setValue={(value) => setInputValue('set-firm-tagline', value)}
                  onBlur={() =>
                    setInputValue('set-firm-tagline', design.tagline.trim())
                  }
                />
                <InputText
                  id="firmValues"
                  className="mb-3"
                  label="Firm Values:"
                  placeholder="Enter top 3 values here"
                  helpTitle="Firm Value"
                  helpContent={`What does your firm value? Innovation? Employee development? Building relationships that last?`}
                  value={design.values}
                  disabled={design.confirm}
                  setValue={(value) => setInputValue('set-firm-values', value)}
                  onBlur={() =>
                    setInputValue('set-firm-values', design.values.trim())
                  }
                />
                <InputNumber
                  id="numPartners"
                  className="mb-3"
                  label="Number of Partners:"
                  helpTitle="Number of Partners"
                  helpContent={`Enter the number of partners running your firm, which is the number of people on your team.`}
                  min={1}
                  max={30}
                  value={design.numberOfPartners}
                  disabled={design.confirm}
                  setValue={(value) =>
                    setInputValue('set-number-partners', value)
                  }
                />
              </Col>
              <Col md={{ offset: 1 }}>
                <h5 className="text-primary fw-bold">Specialization</h5>
                <InputRange
                  label="Expertise:"
                  className="mb-2"
                  id="expertise"
                  helpTitle="Expertise"
                  helpContent={`Do you want to keep your options open in terms of clients, or do you want to attract specialized clients who demand high expertise? Similarly, do you prefer to stick with established processes, or do you embrace change and look for ways to innovate? The more general you are, the more you’ll compete on price, but the larger the universe of potential customers, and the more reliable the work will be (fewer one-off projects). On the other hand, the more specialized you are, the more equipped you might be to take on gnarly, complex problems that have not been solved before.`}
                  minLabel="Routine"
                  maxLabel="Unique"
                  value={design.expertise}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-expertise', value)}
                />
                <InputMultipleChoices
                  className="mb-3"
                  label="Industries:"
                  helpTitle="Industries"
                  disabled={design.confirm}
                  helpContent={`Visionville has several industries. If you intend to target one or more of them, make your selections here. The fewer targeted industries you have, the more appealing you may be to potential clients demanding high expertise…but being highly specialized will cut down on your universe of clients. Of course, there may be the opportunity to merge with another firm that has different expertise down the road.`}
                  setValue={(value) => setInputValue('set-industry', value)}
                />
              </Col>
              <Col md={5} className="mt-4 mb-5 mb-md-0">
                <h5 className="text-primary fw-bold">Line of Business</h5>
                <p className="text-center mt-4">
                  <em>Drag the point to the desired location:</em>
                </p>
                <InputTriangle
                  value={design.lobMix}
                  disabled={design.confirm}
                />
              </Col>
              <Col md={{ offset: 1 }} className="mt-3">
                <h5 className="text-primary fw-bold mb-4">
                  Operating Parameters
                </h5>
                <InputRange
                  className="mb-2"
                  label="Infrastructure:"
                  id="infrastructure"
                  helpTitle="Infrastructure"
                  helpContent={`Will your employees have the newest equipment and software, or will they have to make do with ancient laptops running Windows 7? Will your offices be located near the top floor of a ritzy building in a prestigious part of town, or will you inhabit more modest accommodations? Consider: Better tech increases efficiency, and it affects how your clients see you. Can you afford to project the image of confidence and growth that comes from nice offices and high-tech tools?`}
                  minLabel="Duct tape"
                  maxLabel="Shiny"
                  value={design.infra}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) =>
                    setInputValue('set-infrastructure', value)
                  }
                />
                <InputRange
                  className="mb-2"
                  label="Employee Dev.:"
                  id="employeeDev"
                  helpTitle="Employee Development"
                  helpContent={`When it comes to developing your employees, will you focus on keeping them credentialed (e.g., to meet their designation requirements) or will you invest in a variety of development opportunities that support their growth in leadership, advisory and specialized skill areas? In addition to job-specific expertise, for example, do you want your employees to be able to develop deep relationships and demonstrate leadership presence, effective communication and business acumen? What about develop industry and global knowledge? Remember: Developing employees is expensive, but it reduces risk and increases customer satisfaction.`}
                  minLabel="Credential"
                  maxLabel="Differentiate"
                  value={design.empDev}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-employee-dev', value)}
                />
                <InputRange
                  className="mb-2"
                  label="Compensation:"
                  id="compensation"
                  helpTitle="Compensation"
                  helpContent={`How well will you pay employees relative to other firms (including benefits)? Low pay increases turnover, which decreases efficiency and keeps clients from building personal relationships with your staff.`}
                  minLabel="Lagging"
                  maxLabel="Leading"
                  value={design.comp}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-compensation', value)}
                />
                <InputRange
                  className="mb-2"
                  label="Staffing:"
                  id="staffing"
                  helpTitle="Staffing"
                  helpContent={`Will you run lean, or will you staff for maximum flexibility? High staffing levels increase the hours spent on engagements, but they can lead to higher customer satisfaction.`}
                  minLabel="Lean"
                  maxLabel="Long"
                  value={design.staffing}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-staffing', value)}
                />
                <InputRange
                  className="mb-2"
                  label="Practice Dev.:"
                  id="practiceDev"
                  helpTitle="Practice Development"
                  helpContent={`Practice development is the art of building relationships, both with potential and current clients. It can include advertising, hiring business developers, charitable giving, and spending unbilled time with customers. Practice development is expensive… but would you hire a firm you’d never heard of?`}
                  minLabel="Minimize"
                  maxLabel="Emphasize"
                  value={design.pracDev}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-practice-dev', value)}
                />
                <InputRange
                  className="mb-2"
                  label="Prac. Dev. Balance:"
                  id="pracDevBalance"
                  helpTitle="Practice Development Balance"
                  helpContent={`With the resources you invest in practice development, will your focus be on finding new clients or developing relationships with the clients you acquire?`}
                  minLabel="New Clients"
                  maxLabel="Existing"
                  value={design.pracDevBal}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) =>
                    setInputValue('set-practice-dev-balance', value)
                  }
                />
                <InputRange
                  className="mb-2"
                  label="Philosophy:"
                  id="philosophy"
                  helpTitle="Philosophy"
                  helpContent={`How conservative is your firm? More conservative firms tend to prescribe more procedures, avoid pushing work down to less experienced staff, demand more complete documentation, project estimates more conservatively, and generally take fewer risks. It costs money to be conservative… but then again, conservative firms are less likely to blow their budgets on engagements.`}
                  minLabel="Aggresive"
                  maxLabel="Conservative"
                  value={design.philo}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) => setInputValue('set-philosophy', value)}
                />
                <InputRange
                  className="mb-2"
                  label="Geographic Focus:"
                  id="geographicFocus"
                  helpTitle="Geographic Focus"
                  helpContent={`What will your focus be: clients who operate locally, globally, or somewhere in between?`}
                  minLabel="Local"
                  maxLabel="Global"
                  value={design.geoFocus}
                  disabled={design.confirm && design.yearConfirm && year >= 1}
                  limitRange={
                    design.confirm &&
                    ((!design.yearConfirm && year > 1) || year === 1)
                  }
                  setValue={(value) =>
                    setInputValue('set-geographic-focus', value)
                  }
                />
              </Col>
            </Form>
            <Row className="justify-content-center mt-5">
              <Col xs="auto">
                <Button
                  variant="outline-success"
                  className="rounded-pill"
                  disabled={
                    isConfirming ||
                    (design.confirm && design.yearConfirm && year >= 1)
                  }
                  onClick={() => {
                    if (!design.confirm) {
                      if (!design.name.trim() || !design.numberOfPartners) {
                        setErrorModalShow(true)
                        return
                      }
                      setConfirming(true)
                    } else {
                      handleConfirmDesign()
                    }
                  }}
                >
                  {isConfirming && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="success"
                      className="me-2"
                    />
                  )}{' '}
                  Confirm Design
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </>
  )
}
