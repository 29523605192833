import React, { useState, useRef, useEffect } from 'react'
import { motion, useMotionValue, useAnimation } from 'framer-motion'

const Knob = ({
  row,
  setRow,
  col,
  setCol,
  constraintsRef,
  disabled,
  dropCallback,
}) => {
  const kRef = useRef(null)
  const tileSize = 10

  //temp row and col values as mirror for resizing issue
  const [rowTemp, setRowTemp] = useState(-1)
  const [colTemp, setColTemp] = useState(-1)

  const y = useMotionValue(row * tileSize)
  const x = useMotionValue(col * tileSize)

  const controls = useAnimation()

  const [isDragging, setDragging] = useState(false)

  const solveClass = () => {
    return disabled ? 'input-triangle-knob disabled' : 'input-triangle-knob'
  }

  const solvePosition = (r, c) => {
    return { x: c * tileSize, y: r * tileSize }
  }

  useEffect(() => {
    if (!isDragging) {
      x.set(col * tileSize - 2)
      y.set(row * tileSize - 1)
    }
  }, [isDragging, col, row, x, y])

  useEffect(() => {
    if (!disabled) {
      window.addEventListener('resize', handleResize)
      //Clean up - remove listener
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  })

  function handleResize() {
    const refR = rowTemp !== -1 ? rowTemp : row
    const refC = colTemp !== -1 ? colTemp : col

    if (!isDragging) {
      x.set(refC * tileSize - 2)
      y.set(refR * tileSize - 1)
    }
  }

  const onDragHandler = (event, info) => {
    const kRect = kRef.current.getBoundingClientRect()
    const bRect = constraintsRef.current.getBoundingClientRect()

    setDragging(true)

    kRef.current.style.zIndex = '100000'
  }

  const onDropHandler = (event, info) => {
    const kRect = kRef.current.getBoundingClientRect()
    const bRect = constraintsRef.current.getBoundingClientRect()

    // const checkDrop = props.dropCallback(
    const checkDrop = dropCallback(
      kRect.x - bRect.x + Math.ceil(kRect.width / 1.2 / 2),
      kRect.y - bRect.y + Math.ceil(kRect.height / 1.2 / 2),
      row,
      col
    )

    let solvedPos = {}

    if (checkDrop !== undefined) {
      //centers dot to tile
      solvedPos = solvePosition(checkDrop.r, checkDrop.c)
      setCol(checkDrop.c)
      setRow(checkDrop.r)
      //temp values for resize issue use only
      setColTemp(checkDrop.c)
      setRowTemp(checkDrop.r)
    } else {
      //centers dot to origin tile since user tried to move to a non possible tile
      solvedPos = solvePosition(row, col)

      setCol(col)
      setRow(row)
      //temp values for resize issue use only
      setColTemp(col)
      setRowTemp(row)
    }
    controls.start({
      x: solvedPos.x - 2,
      y: solvedPos.y - 1,
      transition: {
        type: 'spring',
        stiffness: 1500,
        damping: 80,
      },
    })

    kRef.current.style.zIndex = '1'
    setDragging(false)
  }

  const draggable = () => {
    return !disabled
  }

  return (
    <motion.div
      ref={kRef}
      drag={draggable()}
      dragConstraints={constraintsRef}
      dragMomentum={false}
      className={solveClass()}
      onDragStart={onDragHandler}
      onDragEnd={onDropHandler}
      animate={controls}
      style={{ x, y }}
      whileDrag={{ scale: 1.2 }}
    />
  )
}

export default Knob
