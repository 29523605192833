import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getSimStats as getSimStatsQuery,
  getTeamStats as getTeamStatsQuery,
} from '../graphql/queries'
import {
  addSimStats as addSimStatsMutation,
  updateSimStats as updateSimStatsMutation,
  addTeamStats as addTeamStatsMutation,
} from '../graphql/mutations'

export const useStats = () => {
  const addSimStats = useCallback(async (offeringId, numTeams) => {
    return API.graphql(
      graphqlOperation(addSimStatsMutation, {
        input: {
          offeringId,
          numTeams,
        },
      })
    )
  }, [])

  const updateSimStats = useCallback(async (offeringId, numNewTeams) => {
    return API.graphql(
      graphqlOperation(updateSimStatsMutation, {
        input: {
          offeringId,
          numNewTeams,
        },
      })
    )
  }, [])

  const addTeamStats = useCallback(async (offeringId) => {
    return API.graphql(
      graphqlOperation(addTeamStatsMutation, {
        input: {
          offeringId,
        },
      })
    )
  }, [])

  const getSimStats = useCallback(async () => {
    return API.graphql(graphqlOperation(getSimStatsQuery))
  }, [])

  const getTeamStats = useCallback(async () => {
    return API.graphql(graphqlOperation(getTeamStatsQuery))
  }, [])

  return {
    addSimStats,
    updateSimStats,
    addTeamStats,
    getSimStats,
    getTeamStats,
  }
}
