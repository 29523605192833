import React from 'react'
import ReactDOM from 'react-dom'
import './css/index.css'
import './scss/custom.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from './hooks/useAuth'
import { AppStateProvider } from './hooks/useAppState'
import { NotificationProvider } from './hooks/useNotification'
import { NotificationChannelProvider } from './hooks/useNotificationChannel'

ReactDOM.render(
  <React.StrictMode>
    <AppStateProvider>
      <AuthProvider>
        <BrowserRouter>
          <NotificationProvider>
            <NotificationChannelProvider>
              <App />
            </NotificationChannelProvider>
          </NotificationProvider>
        </BrowserRouter>
      </AuthProvider>
    </AppStateProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
