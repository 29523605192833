import React, { useState, useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Skyline from '../../components/Skyline'
import Building from '../../components/Building'
import BlsModal from '../../components/BlsModal'
import { ReactComponent as Bilboard } from '../../images/city-assets/leaderboards-bb.svg'
import { ReactComponent as VideoBuilding } from '../../images/city-assets/explore-building-video.svg'

import { useAppState } from '../../hooks/useAppState'

import { useTarget } from '../../hooks/useTarget'

import './style.css'

const ExploreVisionville = () => {
  const [companies, setCompanies] = useState(null)
  const { fetchTargets } = useTarget()
  const {
    state: {
      year,
      ready,
      offering: { offeringId },
    },
  } = useAppState()
  const currentYear = ready ? year - 1 : year
  const designConfirmed = useAppState().state.design.confirm
  const [show, setShow] = useState(false)

  useEffect(() => {
    updateOnRezie()
  }, [])

  useEffect(() => {
    let isMounted = true
    if (offeringId === null || offeringId === -1) {
      return
    }

    fetchTargets(offeringId)
      .then((targetsList) => {
        const _targets = targetsList.data.getTargets

        if (isMounted) {
          setCompanies(_targets)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
    return () => (isMounted = false)
  }, [fetchTargets, offeringId])

  const createBuilding = (bI) => {
    if (companies !== null) {
      return (
        <Building
          name={companies[bI].displayName}
          id={companies[bI].id}
          status={
            companies[bI].engageYear > currentYear && companies[bI].startup
              ? '0'
              : !companies[bI].outOfBusiness
              ? '1'
              : '2'
          }
          type={bI}
          acceptBids={
            Math.round(companies[bI].engageYear) === Math.round(currentYear) &&
            designConfirmed
          }
          detail={companies[bI].detail}
          immediateNeed={companies[bI].immediateNeed}
          industry={companies[bI].industry}
          engageSize={companies[bI].engageSize}
          engageType={companies[bI].engageType}
        />
      )
    } else {
      return (
        <div className="loading-building">
          <span>
            Loading
            <br />
            Company Information...
          </span>
        </div>
      )
    }
  }

  function updateOnRezie() {
    const vRoad = document.getElementById('col-road-0')

    if (vRoad !== undefined && vRoad !== null) {
      const jointW = (vRoad.clientWidth - 82) / 2 + 3

      const root = document.querySelector(':root')

      root.style.setProperty('--pseudo-joint-width', jointW + 'px')
      root.style.setProperty('--pseudo-joint-right', '-' + jointW + 'px')
    }
  }

  window.onresize = updateOnRezie

  return (
    <Container fluid as="main" className="p-0 main overflow-hidden">
      <BlsModal
        title="Welcome to Visionville"
        children={
          <>
            <div className="ratio ratio-16x9">
              <iframe
                src="https://player.vimeo.com/video/709523912?h=3ce88dd9e7"
                title="Welcome to Visionville"
                allowFullScreen
              ></iframe>
            </div>
          </>
        }
        show={show}
        setShow={setShow}
      ></BlsModal>
      <Skyline
        helpTitle="Business Intelligence"
        helpContent="<p>To view the business intelligence details for an organization, click the business. Use these details when you design your firm and when you make bids on clients.</p><p>If you see a marker next to a business, the business is currently up for bid.</p>"
      />
      <div className="h-road"></div>
      <div className="grass">
        {/* Welcome to Visionville & bilboard video building */}
        <Row className="g-0 explore-welcome-row">
          <Col sm="12" md="5" className="text-center">
            <Bilboard className="explore-billboard mx-auto img-fluid" />
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road mx-auto road-joint-tl"></div>
          </Col>
          <Col sm="12" md="5">
            <VideoBuilding
              onClick={() => setShow(true)}
              className="explore-billboard2 mx-auto img-fluid"
            />
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road mx-auto road-joint-tl"></div>
          </Col>
        </Row>

        {/* Company buildings  */}
        <Row className="g-0">
          <Col sm="12" md="5">
            <Row>
              <Col sm="6">{createBuilding(0)}</Col>
              <Col sm="6">{createBuilding(1)}</Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(2)}</Col>
              <Col sm="6">{createBuilding(3)}</Col>
            </Row>
            <Row className="my-4">
              <Col className="relative">
                <div className="h-road road-joint-r"></div>
                <div className="people-0"></div>
              </Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(4)}</Col>
              <Col sm="6">{createBuilding(5)}</Col>
            </Row>

            <Row>
              <Col sm="6">{createBuilding(6)}</Col>
              <Col sm="6">{createBuilding(7)}</Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(8)}</Col>
              <Col sm="6">{createBuilding(9)}</Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(10)}</Col>
              <Col sm="6">{createBuilding(11)}</Col>
            </Row>
          </Col>
          <Col sm="12" md="1" className="d-none d-md-flex" id="col-road-0">
            <div className="v-road mx-auto road-joint-br"></div>
          </Col>
          <Col sm="12" md="5">
            <Row>
              <Col sm="6">{createBuilding(12)}</Col>
              <Col sm="6">{createBuilding(13)}</Col>
            </Row>
            <Row>
              <Col sm="6" className="pb-4 relative">
                <div className="tree-0"></div>
                {createBuilding(14)}
              </Col>
              <Col sm="6">{createBuilding(15)}</Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(16)}</Col>
              <Col sm="6">{createBuilding(17)}</Col>
            </Row>
            <Row className="my-4">
              <Col className="relative">
                <div className="people-1"></div>
                <div className="h-road  road-joint-l road-joint-r"></div>
              </Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(18)}</Col>
              <Col sm="6">{createBuilding(19)}</Col>
            </Row>
            <Row>
              <Col sm="6">{createBuilding(20)}</Col>
              <Col sm="6">{createBuilding(21)}</Col>
            </Row>
            <Row>
              <Col sm="6" className="pb-4 relative">
                <div className="tree-1"></div>
                {createBuilding(22)}
              </Col>
              <Col sm="6" className="pb-4">
                {createBuilding(23)}
              </Col>
            </Row>
          </Col>

          <Col sm="12" md="1" className="d-none d-md-flex">
            <div className="v-road mx-auto road-joint-br"></div>
          </Col>
        </Row>
      </div>
      <div className="h-road"></div>
      <div className="h-road"></div>
    </Container>
  )
}
export default ExploreVisionville
