import HelpButton from '../../components/HelpButton'
import './style.css'

const Skyline = (props) => {
  const helpTitle = props.helpTitle
  const helpContent = props.helpContent

  return (
    <div className="skyline">
      <div className="skyline-cloud-0"></div>
      <div className="skyline-cloud-1 d-none d-sm-block"></div>
      <div className="skyline-cloud-2"></div>
      {(helpTitle || helpContent) && (
        <div className="skyline-help-button">
          <HelpButton title={helpTitle} content={helpContent} />
        </div>
      )}
    </div>
  )
}

export default Skyline
