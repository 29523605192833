import { useState, useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getTeam,
  getTeams as getTeamsQuery,
  getTeamByOfferingId as getTeamByOfferingIdQuery,
  getTeamsByOfferingId as getTeamsByOfferingIdQuery,
} from '../graphql/queries'
import {
  refreshTeamCode as refreshTeamCodeMutation,
  cognitoUpdateUser as cognitoUpdateUserMutation,
  updateTeam as updateTeamMutation,
  addTeam as addTeamMutation,
  readyToAdvance as readyToAdvanceMutation,
} from '../graphql/mutations'

export const useTeam = () => {
  const [team, setTeam] = useState(null)
  const [teamLoading, setTeamLoading] = useState(false)

  const fetchTeam = useCallback(async (id) => {
    setTeamLoading(true)
    return API.graphql(
      graphqlOperation(getTeam, {
        id,
      })
    )
      .then((queryResult) => {
        setTeam(queryResult.data.getTeam)
        return queryResult.data.getTeam
      })
      .catch((err) => {
        throw err
      })
      .finally(() => {
        setTeamLoading(false)
      })
  }, [])

  const getTeams = useCallback(async () => {
    return API.graphql(graphqlOperation(getTeamsQuery))
  }, [])

  const refreshTeamCode = useCallback(
    async (hostNumber, offeringId, teamId) => {
      if (hostNumber === -1) {
        throw new Error('Invalid host number')
      }
      if (offeringId === undefined) {
        throw new Error('Invalid offering id')
      }
      if (teamId === undefined) {
        throw new Error('Invalid team id')
      }
      return API.graphql(
        graphqlOperation(refreshTeamCodeMutation, {
          input: { hostNumber, offeringId, teamId },
        })
      )
    },
    []
  )

  const updateTeamCodeInCognito = useCallback(
    (groupName, userId, newAccessCode) => {
      const userName = `${groupName}${userId}`

      return API.graphql(
        graphqlOperation(cognitoUpdateUserMutation, {
          input: { userName, newAccessCode },
        })
      )
    },
    []
  )

  const updateTeam = useCallback((teamInput) => {
    return API.graphql(
      graphqlOperation(updateTeamMutation, {
        input: { ...teamInput },
      })
    )
  }, [])

  const addTeam = useCallback((offeringId, hostNumber) => {
    return API.graphql(
      graphqlOperation(addTeamMutation, {
        input: { offeringId, hostNumber },
      })
    )
  }, [])

  /**
   * Team indicates they're ready to advance to the next Sim year
   */
  const readyToAdvance = useCallback((offeringId, teamId) => {
    return API.graphql(
      graphqlOperation(readyToAdvanceMutation, {
        input: { offeringId, teamId },
      })
    )
  }, [])

  const getTeamByOfferingId = useCallback((offeringId, id) => {
    return API.graphql(
      graphqlOperation(getTeamByOfferingIdQuery, { offeringId, id })
    )
  }, [])

  const getTeamsByOfferingId = useCallback((offeringId) => {
    return API.graphql(
      graphqlOperation(getTeamsByOfferingIdQuery, { offeringId })
    )
  }, [])

  return {
    team,
    teamLoading,
    getTeams,
    fetchTeam,
    refreshTeamCode,
    updateTeamCodeInCognito,
    updateTeam,
    addTeam,
    readyToAdvance,
    getTeamByOfferingId,
    getTeamsByOfferingId,
  }
}
