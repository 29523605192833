import { useEffect, useState, useRef } from 'react'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Modal from 'react-bootstrap/Modal'

import { useAppState } from '../../hooks/useAppState'
import { useLeaderboard } from '../../hooks/useLeaderboard'
import { useTeam } from '../../hooks/useTeam'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

import TeamDataView from '../TeamDataView'

import { formatCurrency } from '../../utils/utils'

const LeaderboardTotalProfit = () => {
  const {
    state: {
      ready,
      offering: { offeringId },
    },
  } = useAppState()
  const {
    state: { hostMergeTeams },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const { getTotalProfit } = useLeaderboard()
  const [list, setList] = useState([])
  const [teamId, setTeamId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const isMounted = useRef(true)

  const handleClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    isMounted.current = true

    if (offeringId === null || offeringId === -1) {
      return
    }

    setLoading(true)

    getTotalProfit(offeringId)
      .then((totalProfitData) => {
        if (isMounted.current) {
          setList(totalProfitData.data.leaderboardTotalProfit)
        }
      })
      .catch((err) => {
        console.log('Unable to retrieve total profit leaderboard.', err)
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
    return () => {
      isMounted.current = false
    }
  }, [getTotalProfit, offeringId, ready])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('LeaderboardTotalProfit')
    ) {
      setLoading(true)
      getTotalProfit(offeringId)
        .then((totalProfitData) => {
          if (isMounted.current) {
            setList(totalProfitData.data.leaderboardTotalProfit)
          }
        })
        .catch((err) => {
          console.log('Unable to retrieve total profit leaderboard.', err)
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false)
          }
          dispatchNC({
            type: 'host-merge-teams-executed',
            payload: {
              in: 'LeaderboardTotalProfit',
            },
          })
        })
    }
  }, [hostMergeTeams, getTotalProfit, dispatchNC, offeringId])

  const handleViewTeam = (teamId) => {
    setTeamId(teamId)
    setShowModal(true)
  }

  return (
    <>
      <ViewDesign show={showModal} handleClose={handleClose} teamId={teamId} />
      <Table responsive className="w-100 table leaderboard-bb-table">
        <thead>
          <tr>
            <th>Pos</th>
            <th>Firm Name</th>
            <th width="20%">Firm Design</th>
            <th width="20%">Total Profit</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              {Array(4)
                .fill('')
                .map((_, index) => (
                  <td key={index.toString()}>
                    <Spinner
                      as="div"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      variant="secondary"
                    />
                  </td>
                ))}
            </tr>
          ) : (
            list.map((team, index) => (
              <tr key={index.toString()}>
                <td>{index + 1}</td>
                <td>{team.teamName}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    className="rounded-pill"
                    size={'sm'}
                    onClick={() => handleViewTeam(team.teamId)}
                  >
                    View
                  </Button>
                </td>
                <td>
                  {team.totalProfit === null
                    ? '--'
                    : formatCurrency(team.totalProfit)}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </>
  )
}

const ViewDesign = ({ show, teamId, handleClose }) => {
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()
  const { getTeamByOfferingId } = useTeam()
  const [team, setTeam] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    if (offeringId === null || offeringId === -1 || teamId === null) {
      return
    }
    setLoading(true)
    getTeamByOfferingId(offeringId, teamId)
      .then((teamData) => {
        if (isMounted) {
          setTeam(teamData.data.getTeamByOfferingId)
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    return () => {
      isMounted = false
    }
  }, [offeringId, teamId, getTeamByOfferingId])

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      dialogClassName="modal-90w"
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      size={'xl'}
    >
      <Modal.Header className="border-0 pb-0">
        <Modal.Title className="h5 fw-bold">Team Snapshot</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          team && <TeamDataView team={team} />
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-success"
          className="rounded-pill"
          onClick={handleClose}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default LeaderboardTotalProfit
