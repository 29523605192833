import React, { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import InputRange from '../../components/InputRange'
import InputTriangle from '../../components/InputTriangle'

import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js'

import { Radar } from 'react-chartjs-2'

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
)

export const radarOptions = {
  layout: {
    padding: { left: 0, right: 0 },
  },
  scales: {
    r: {
      angleLines: {
        display: true,
      },
      suggestedMin: 0,
      suggestedMax: 10,
    },
  },
}
export const plugins = [
  {
    legend: {
      display: 'false',
      position: 'left',
    },
  },
]

const renderIndustries = (inds) => {
  const labels = {
    cs: 'Consumer Products',
    fs: 'Financial Services',
    hc: 'Health Care',
    in: 'Industrials',
    ls: 'Life Sciences',
    np: 'Non-Profit',
    tmt: 'Tech, Media & Telecom',
  }
  let result = ''

  try {
    const industries = Object.entries(JSON.parse(inds))
      .filter((keyValue) => keyValue[1] === true)
      .flat()
      .filter((v) => typeof v === 'string')

    result = industries.map((ind) => labels[ind]).join(', ')
  } catch (err) {
    console.log('Error parsing industries data', err)
  }

  return result
}

export default function TeamDataView({ team, initialView = 'radar' }) {
  return (
    <div>
      <Row>
        <Col>
          <h2 className="text-primary fw-bold mb-4">
            Team: {team.name !== null && team.name}
          </h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <strong className="text-primary">Tagline:</strong>
          <p>{team.tagline !== null && team.tagline}</p>
        </Col>
        <Col>
          <strong className="text-primary">Total Partners:</strong>
          <p>{team.numberOfPartners !== null && team.numberOfPartners}</p>
        </Col>
        <Col>
          <strong className="text-primary">Values:</strong>
          <p>{team.values !== null && team.values}</p>
        </Col>
        <Col>
          <strong className="text-primary">Industries:</strong>
          <p>{team.industries !== null && renderIndustries(team.industries)}</p>
        </Col>
        <Col>
          <strong className="text-primary">Current Clients:</strong>
          <p>
            {team.clients &&
              team.clients.map((client) => client.displayName).join(', ')}
          </p>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6}>
          <Stack gap={4}>
            <div>
              <h4 className="text-success fw-bold mb-2">Line of Business</h4>
              <InputTriangle value={JSON.parse(team.lobMix)} disabled={true} />
            </div>
            <div>
              <h4 className="text-success fw-bold mb-2 mt-5">Specialization</h4>
              <InputRange
                label="Expertise:"
                id="expertise"
                helpTitle="Expertise"
                helpContent={`Do you want to keep your options open in terms of clients, or do you want to attract specialized clients who demand high expertise? Similarly, do you prefer to stick with established processes, or do you embrace change and look for ways to innovate? The more general you are, the more you’ll compete on price, but the larger the universe of potential customers, and the more reliable the work will be (fewer one-off projects). On the other hand, the more specialized you are, the more equipped you might be to take on gnarly, complex problems that have not been solved before.`}
                minLabel="Routine"
                maxLabel="Unique"
                value={team.expertise}
                disabled={true}
              />
            </div>
          </Stack>
        </Col>
        <Col md={6} className="mt-5 mt-md-0">
          <h4 className="text-success fw-bold mb-2 mt-3 mt-md-0">
            Operating Parameters
          </h4>
          <div className="graph-container ">
            {
              <OpParametersView
                data={[
                  {
                    name: 'Infrastructure',
                    value: team.infra,
                  },
                  {
                    name: 'Employee Dev',
                    value: team.empDev,
                  },
                  {
                    name: 'Compensation',
                    value: team.comp,
                  },
                  {
                    name: 'Staffing',
                    value: team.staffing,
                  },
                  {
                    name: 'Practice Dev.',
                    value: team.pracDev,
                  },
                  {
                    name: 'Prac. Dev. Balance',
                    value: team.pracDevBal,
                  },
                  {
                    name: 'Philosophy',
                    value: team.philo,
                  },
                  {
                    name: 'Geographic Focus',
                    value: team.geoFocus,
                  },
                ]}
                view={initialView}
              />
            }
          </div>
        </Col>
      </Row>
      <hr />
    </div>
  )
}

export function Sliders(props) {
  const data = props.data

  return (
    <div className="slider-container">
      <InputRange
        className="mb-2"
        label="Infrastructure:"
        id="infrastructure"
        helpTitle="Infrastructure"
        helpContent={`Will your employees have the newest equipment and software, or will they have to make do with ancient laptops running Windows 7? Will your offices be located near the top floor of a ritzy building in a prestigious part of town, or will you inhabit more modest accommodations? Consider: Better tech increases efficiency, and it affects how your clients see you. Can you afford to project the image of confidence and growth that comes from nice offices and high-tech tools?`}
        minLabel="Duct tape"
        maxLabel="Shiny"
        value={data[0]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Employee Dev.:"
        id="employeeDev"
        helpTitle="Employee Development"
        helpContent={`When it comes to developing your employees, will you focus on keeping them credentialed (e.g., to meet their designation requirements) or will you invest in a variety of development opportunities that support their growth in leadership, advisory and specialized skill areas? In addition to job-specific expertise, for example, do you want your employees to be able to develop deep relationships and demonstrate leadership presence, effective communication and business acumen? What about develop industry and global knowledge? Remember: Developing employees is expensive, but it reduces risk and increases customer satisfaction.`}
        minLabel="Credential"
        maxLabel="Differentiate"
        value={data[1]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Compensation:"
        id="compensation"
        helpTitle="Compensation"
        helpContent={`How well will you pay employees relative to other firms (including benefits)? Low pay increases turnover, which decreases efficiency and keeps clients from building personal relationships with your staff.`}
        minLabel="Lagging"
        maxLabel="Leading"
        value={data[2]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Staffing:"
        id="staffing"
        helpTitle="Staffing"
        helpContent={`Will you run lean, or will you staff for maximum flexibility? High staffing levels increase the hours spent on engagements, but they can lead to higher customer satisfaction.`}
        minLabel="Lean"
        maxLabel="Long"
        value={data[3]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Practice Dev.:"
        id="practiceDev"
        helpTitle="Practice Development"
        helpContent={`Practice development is the art of building relationships, both with potential and current clients. It can include advertising, hiring business developers, charitable giving, and spending unbilled time with customers. Practice development is expensive… but would you hire a firm you'd never heard of?`}
        minLabel="Minimize"
        maxLabel="Emphasize"
        value={data[4]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Prac. Dev. Balance:"
        id="pracDevBalance"
        helpTitle="Practice Development Balance"
        helpContent={`What will your focus be: finding new clients, or developing relationships with the clients you already have?`}
        minLabel="New Clients"
        maxLabel="Existing"
        value={data[5]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Philosophy:"
        id="philosophy"
        helpTitle="Philosophy"
        helpContent={`How conservative is your firm? More conservative firms tend to prescribe more procedures, avoid pushing work down to less experienced staff, demand more complete documentation, project estimates more conservatively, and generally take fewer risks. It costs money to be conservative… but then again, conservative firms are less likely to blow their budgets on engagements.`}
        minLabel="Aggresive:"
        maxLabel="Conservative"
        value={data[6]}
        disabled={true}
      />
      <InputRange
        className="mb-2"
        label="Geographic Focus:"
        id="geographicFocus"
        helpTitle="Geographic Focus"
        helpContent={`What will your focus be: clients who operate locally, globally, or somewhere in between?`}
        minLabel="Local"
        maxLabel="Global"
        value={data[7]}
        disabled={true}
      />
    </div>
  )
}

export function OpParametersView(props) {
  const [view, setView] = useState(props.view)

  const labels = props.data.map((params) => {
    return params.name
  })
  const data = props.data.map((params) => {
    return params.value
  })

  const teamData = {
    labels: labels,
    datasets: [
      {
        label: 'values',
        data: data,
        backgroundColor: 'rgba(0, 156, 222, 0.2)',
        borderColor: 'rgba(0, 156, 222, 1)',
        fill: true,
        borderWidth: 2,
      },
    ],
  }

  const manageView = () => {
    if (view === 'radar')
      return (
        <div className="radar-container">
          <Radar data={teamData} options={radarOptions} plugins={plugins} />
        </div>
      )

    return <Sliders data={data} />
  }

  const toogleView = () => {
    const newView = view === 'radar' ? 'sliders' : 'radar'
    setView(newView)
  }

  return (
    <React.Fragment>
      {manageView()}
      <div className="text-center">
        <Button
          variant="outline-success"
          className="rounded-pill mx-auto"
          onClick={toogleView}
        >
          View {view === 'radar' ? 'Sliders' : 'Radar'}
        </Button>
      </div>
    </React.Fragment>
  )
}
