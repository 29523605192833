import { useEffect } from 'react'
import clsx from 'clsx'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useSearchParams, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import './style.css'

import { useAppState } from '../../hooks/useAppState'
import { useWindowSize } from '../../hooks/useWindowSize'

import RunBid from '../../components/RunBid'
import RunExecute from '../../components/RunExecute'
import RunRetrieve from '../../components/RunRetrieve'
import RunView from '../../components/RunView'
import RunReflect from '../../components/RunReflect'
import RunAdvance from '../../components/RunAdvance'

import { ReactComponent as RunTabStart } from '../../images/run-tabs/run-tab-start.svg'
import { ReactComponent as RunTabMiddle } from '../../images/run-tabs/run-tab-middle.svg'
import { ReactComponent as RunTabEnd } from '../../images/run-tabs/run-tab-end.svg'
import { ReactComponent as RunTabMobile } from '../../images/run-tabs/run-tab-mobile.svg'

export default function RunFirm() {
  const {
    state: {
      year,
      ready,
      design,
      run: { lastVisitedTab },
    },
    // dispatch,
  } = useAppState()
  const [searchParams, setSearchParams] = useSearchParams()
  const location = useLocation()
  const Content = getContent(searchParams.get('tab'))

  /**
   * Go back to previously visited tab when coming from another page
   */
  useEffect(() => {
    if (searchParams.get('tab') !== null) {
      return
    }

    if (lastVisitedTab !== '') {
      setSearchParams({ tab: lastVisitedTab })
    } else {
      setSearchParams({ tab: 'bid' })
    }
  }, [location, lastVisitedTab, setSearchParams, searchParams])

  // Firm design confirmed, show the Run Your Firm content
  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col
          md={{ span: 10, offset: 1 }}
          lg={{ span: 6, offset: 3 }}
          className="text-center"
        >
          <h1 className="text-center text-primary fw-bold d-inline-block">
            <span>Run Your Firm</span>
            {design.confirm !== null && <Timeline year={year} ready={ready} />}
          </h1>
          {design.confirm === false && (
            <p>Whoops! Please confirm your firm design first.</p>
          )}
        </Col>
      </Row>
      {design.confirm === false && (
        /**
         * Team hasn't confirmed their design yet but visited this page using
         * the direct URL.
         */
        <Row className="justify-content-center mt-4">
          <Col xs="auto">
            <Button
              as={Link}
              to="/design"
              variant="outline-primary rounded-pill"
            >
              Go back to Design Your Firm
            </Button>
          </Col>
        </Row>
      )}
      {design.confirm === true && (
        // Render chevron tabs
        <>
          <Row className="overflow-hidden">
            <Col
              xs={{ span: 8, offset: 2 }}
              sm={{ span: 10, offset: 0 }}
              md={{ span: 8, offset: 1 }}
              lg={{ span: 7, offset: 2 }}
              xxl={{ span: 10, offset: 1 }}
              className="text-center text-xxl-start"
            >
              <Tabs />
            </Col>
          </Row>
          <Row>
            {/** Render content */}
            <Col>
              <Content />
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

function getContent(search = '') {
  switch (search) {
    case 'execute':
      return RunExecute
    case 'retrieve':
      return RunRetrieve
    case 'view':
      return RunView
    case 'reflect':
      return RunReflect
    case 'advance':
      return RunAdvance
    case 'bid':
    default:
      return RunBid
  }
}

function Timeline({ year, ready }) {
  return (
    <div>
      <div className="timeline">
        <div className={clsx('timeline-dot', year >= 1 && 'active')}>
          <span className={'timeline-label'}>
            {year === 1 && !ready && 'Year 1'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-connector',
            year === 2 && ready ? 'ready' : year > 1 && 'active'
          )}
        >
          <span className="timeline-connector-label">
            {year === 2 && ready && 'Approaching Year 2'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-dot',
            ((!ready && year >= 2) || (ready && year > 2)) && 'active'
          )}
        >
          <span className={'timeline-label'}>
            {year === 2 && !ready && 'Year 2'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-connector',
            year === 3 && ready ? 'ready' : year > 2 && 'active'
          )}
        >
          <span className="timeline-connector-label">
            {year === 3 && ready && 'Approaching Year 3'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-dot',
            ((!ready && year >= 3) || (ready && year > 3)) && 'active'
          )}
        >
          <span className={'timeline-label'}>
            {year === 3 && !ready && 'Year 3'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-connector',
            year === 4 && ready ? 'ready' : year > 3 && 'active'
          )}
        >
          <span className="timeline-connector-label">
            {year === 4 && ready && 'Approaching Year 4'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-dot',
            ((!ready && year >= 4) || (ready && year > 4)) && 'active'
          )}
        >
          <span className={'timeline-label'}>
            {year === 4 && !ready && 'Year 4'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-connector',
            year === 5 && ready ? 'ready' : year > 4 && 'active'
          )}
        >
          <span className="timeline-connector-label">
            {year === 5 && ready && 'Approaching Year 5'}
          </span>
        </div>
        <div
          className={clsx(
            'timeline-dot',
            ((!ready && year >= 5) || (ready && year > 5)) && 'active'
          )}
        >
          <span className={'timeline-label'}>{year === 5 && 'Year 5'}</span>
        </div>
      </div>
    </div>
  )
}

function Tab({ label, active, index, tabCount, disabled = false }) {
  const [_, setSearchParams] = useSearchParams()
  const { dispatch } = useAppState()
  const { width } = useWindowSize()
  const SM_BREAKPOINT = 575

  const handleTabClick = (tabName) => {
    // Save tab name to the app state
    dispatch({ type: 'set-run-tab', payload: tabName })
    setSearchParams({ tab: tabName })
  }

  return (
    <li
      id={label.toLowerCase()}
      className={clsx(
        'list-unstyled d-inline-block position-relative run-tab-item',
        label.toLowerCase()
      )}
    >
      <Button
        className={clsx('p-0 text-gray-400 run-tab-button', active && 'active')}
        role="tab"
        disabled={disabled}
        onClick={() => handleTabClick(label.toLowerCase())}
      >
        {width <= SM_BREAKPOINT ? (
          <RunTabMobile />
        ) : index === 0 ? (
          <RunTabStart />
        ) : index === tabCount - 1 ? (
          <RunTabEnd />
        ) : (
          <RunTabMiddle />
        )}
        <span className="run-tab-button-label">{label}</span>
      </Button>
    </li>
  )
}

function Tabs() {
  const {
    state: { year },
  } = useAppState()
  const [searchParams] = useSearchParams()
  const labels = ['BID', 'EXECUTE', 'RETRIEVE', 'VIEW', 'REFLECT', 'ADVANCE']

  return (
    <ul role="tablist" className="run-tabs">
      {labels.map((label, labelIndex) => (
        <Tab
          key={label}
          index={labelIndex}
          tabCount={labels.length}
          label={label}
          active={label.toLowerCase() === searchParams.get('tab')}
          disabled={
            (label === 'BID' && year > 3) || (label === 'ADVANCE' && year === 5)
          }
        />
      ))}
    </ul>
  )
}
