import { createContext, useContext, useReducer } from 'react'

const NotificationContext = createContext()

export const initialState = []

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_TOAST':
      action.payload.id = getRndId(state)
      return [{ ...action.payload }, ...state]
    case 'REMOVE_TOAST':
      return state.filter((t) => t.id !== action.id)
    case 'REMOVE_ALL_TOAST':
      return []
    default:
      return state
  }
}

const getRndId = (state) => {
  let idrnd = Math.random()
  return idExists(state, idrnd) ? getRndId() : idrnd
}

function idExists(state, id) {
  return state.some(function (el) {
    return el.id === id
  })
}

export function NotificationProvider({ children }) {
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    initialState
  )

  return (
    <NotificationContext.Provider value={{ state, dispatch }}>
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => {
  return useContext(NotificationContext)
}
