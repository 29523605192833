import { Link } from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import HelpButton from '../../components/HelpButton'
import pencilInCogIcon from '../../images/icons/pencil-in-cog-icon.svg'
import rankIcon from '../../images/icons/rank-icon.svg'
import discussIcon from '../../images/icons/team-discuss-icon.svg'
import { useAppState } from '../../hooks/useAppState'

import './style.css'
export default function RunReflect() {
  const {
    state: { year },
  } = useAppState()

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">Take a Moment...</h2>
          {year === 5 ? (
            <>
              <p>
                It’s been a busy five years for your firm, and now is a great
                time to reflect!
              </p>
              <p>
                First, check the leaderboards to see how you stack up against
                the competition. Then, come back here to reflect.
              </p>
            </>
          ) : (
            <>
              <p>
                It’s been a busy year for your firm, and now is a great time to
                reflect!
              </p>
              <p>
                Consider your firm’s financials, and the results of your
                high-profile engagements. Would you like to make changes to your
                firm’s design? If so, you can tweak your operating parameters
                ever so slightly — go and do that now.
              </p>
              <p>
                You may also wish to consider merging with another firm. A merge
                is entirely optional, and both teams will have to agree to the
                merge. Once agreed, direct your merge request to the
                simulation’s Host.
              </p>
            </>
          )}
        </Col>
        <Col>
          <Stack direction="horizontal" className="align-items-start" gap={4}>
            <div className="d-none d-lg-flex" style={{ height: 500 }}>
              <div className="vr"></div>
            </div>
            <div className="flex-grow-1">
              <h2 className="text-success h3 fw-bold">Reflect</h2>
              {year === 5 ? (
                <>
                  <div className="bg-gray-100 px-3 py-2 mt-4 text-center mb-4">
                    As a team, discuss the following reflection questions:
                  </div>
                  <Row className="align-items-end">
                    <Col md={4} lg={3} className="text-center">
                      <img
                        src={discussIcon}
                        alt="team discuss"
                        className="discuss-icon img-fluid"
                      />
                    </Col>
                    <Col md={8} lg={9}>
                      <ul>
                        <li>
                          What might you do differently if you could go back and
                          start again?
                        </li>
                        <li>What steps are you glad you took?</li>
                        <li>What bidding strategy worked well?</li>
                        <li>Were your clients happy? Why or why not?</li>
                        <li>Is your firm well positioned for the future?</li>
                      </ul>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className="bg-gray-100 px-3 py-2 mt-4 text-center">
                    As you reflect, consider the following:
                  </div>
                  <Row className="mt-5">
                    <Col className="text-center">
                      <img
                        src={pencilInCogIcon}
                        alt="pencil in cog"
                        width={94}
                        height={94}
                        className="mb-4"
                      />
                      <h6 className="text-primary fw-bold d-flex align-items-center justify-content-center reflect-h6">
                        Operating Parameters
                      </h6>
                      <p className="mb-0">
                        What needs to change?
                        <br />
                        Is your focus in the wrong area?
                      </p>
                      <Button
                        as={Link}
                        to="/design"
                        variant="outline-success rounded-pill mx-auto mt-5"
                      >
                        Adjust Firm Design
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <img
                        src={rankIcon}
                        alt="rank"
                        width={94}
                        height={94}
                        className="mb-4"
                      />
                      <h6 className="text-primary fw-bold d-flex align-items-center justify-content-center reflect-h6">
                        Merge
                        <div className="mx-1">
                          <HelpButton
                            title="Why Merge?"
                            content="<p>Some clients are reassured by working with a larger firm, believing they will bring more resources and greater stability — especially large clients, and clients with large aspirations. Consider merging with another firm as a way to grow quickly. Or, if your firm is struggling, as one possible path to success.</p><p>Bear in mind, however, that larger isn’t always better. It can be disruptive, and thus negatively impact the employee and client experience. It may also make you less attractive to clients who prefer a more personal touch. If you do merge with another firm, expect to see a change in your overall employee and client satisfaction rankings...good luck!</p>"
                          />
                        </div>
                      </h6>
                      <p className="mb-4 mb-md-0">
                        Will merging with another firm help you to improve?
                      </p>
                      <Button
                        as={Link}
                        to="/teams"
                        variant="outline-success rounded-pill mx-auto mt-5"
                      >
                        View Other Firms
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}
