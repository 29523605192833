import React, { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import './style.css'

import { useChat } from '../../hooks/useChat'

import { useAppState } from '../../hooks/useAppState'
import { NOTIF_CHAT_HOST, NOTIF_CHAT_TEAM } from '../../utils/constants'

import { ReactComponent as SendIcon } from '../../images/icons/send-icon.svg'

export function ChatForm({
  type,
  receiverName = '',
  receiverId = '',
  onNewMessage,
  onFocus,
}) {
  const {
    state: {
      me,
      offering: { offeringId },
    },
  } = useAppState()
  const { chats, generateChatRoomName, connectChatRoom, sendChat } = useChat()
  const [message, setMessage] = useState('')
  const chatMessageRef = useRef(null)
  const chatMsgsBlockRef = useRef(null)

  useEffect(() => {
    if (offeringId === null || offeringId < 0) {
      return
    }

    // console.log('Connecting to chat room...', offeringId, receiverId)
    const subscription = connectChatRoom(offeringId, receiverId, onNewMessage)

    return function cleanup() {
      // console.log('Disconnecting from chat room...', offeringId, receiverId)
      // Stop receiving data updates from the subscription
      subscription.unsubscribe()
    }
  }, [connectChatRoom, offeringId, receiverId, onNewMessage])

  /**
   * Always scroll to the bottom of chat message block
   */
  useEffect(() => {
    chatMsgsBlockRef.current.scrollTop = parseInt(
      chatMsgsBlockRef.current.scrollHeight
    )
  }, [chats])

  const handleMessageChange = (e) => {
    setMessage(e.target.value)
  }

  const handleSendMsg = (e) => {
    // Don't submit the form
    e.preventDefault()

    // Do not send empty text
    if (message.trim() === '') {
      return
    }

    // Send the chat message
    const sendChatInput = {
      to: generateChatRoomName(offeringId, receiverId),
      sender: me,
      receiver: `${receiverId}`,
      message,
      type,
    }
    sendChat(sendChatInput)

    // Clear the input box and set focus
    setMessage('')
    chatMessageRef.current.focus()
  }

  return (
    <form
      className={clsx(
        'chat-form',
        type === NOTIF_CHAT_TEAM ? 'float' : NOTIF_CHAT_HOST ? 'host' : ''
      )}
    >
      <div className="chat-msgs-block" ref={chatMsgsBlockRef}>
        <div className="text-gray-300 chat-top-area">
          {receiverName !== '' && (
            <>
              <div>You are messaging{type !== NOTIF_CHAT_HOST && ' the'}</div>
              <div>{receiverName}...</div>
            </>
          )}
        </div>
        <div className="bubbles-group">
          {chats.map((chat, i) => {
            let bubbleType = ''
            if (type === NOTIF_CHAT_HOST) {
              if (chat.type === NOTIF_CHAT_HOST) {
                bubbleType = 'local-bubble'
              } else {
                bubbleType = 'contact-bubble'
              }
            } else if (type === NOTIF_CHAT_TEAM) {
              if (chat.type === NOTIF_CHAT_TEAM) {
                bubbleType = 'local-bubble'
              } else {
                bubbleType = 'contact-bubble'
              }
            }

            return (
              <div
                key={i}
                className={bubbleType}
                dangerouslySetInnerHTML={{
                  __html: chat.message.replace(/\n/g, '<br/>'),
                }}
              />
            )
          })}
        </div>
      </div>
      <div className="chat-new-msg-block">
        <Form.Control
          ref={chatMessageRef}
          as="textarea"
          placeholder="Type something!"
          value={message}
          className="chat-input"
          onChange={handleMessageChange}
          onFocus={onFocus}
          onKeyDown={(e) => {
            // Send the message if the user hits Enter key
            if (e.code === 'Enter' && !e.shiftKey) {
              handleSendMsg(e)
            }
          }}
        />
        <Button
          variant="outline"
          className="btn-snd"
          disabled={message.trim() === ''}
          onClick={handleSendMsg}
        >
          <SendIcon width="16" height="14" fill="currentColor" />
        </Button>
      </div>
    </form>
  )
}
