import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function AwardBids() {
  return (
    <Container as="main" className="pb-5 main">
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <h1 className="text-center text-primary fw-bold">
            There's nothing here!
          </h1>
        </Col>
      </Row>
    </Container>
  )
}
