import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import HelpButton from '../../components/HelpButton'

export default function InputText({
  as = Row,
  id,
  label = '',
  placeholder = '',
  value,
  setValue,
  helpTitle = '',
  helpContent = '',
  className,
  labelClassName = '',
  labelBreakpointClassName = '',
  inputColor = 'gray-500',
  disabled = false,
  onBlur,
  dollarSign = false,
}) {
  const rowColStructure = as === Row
  const defaultLabelBreakpointClassName = rowColStructure
    ? 'col-xs-3 col-md-5 col-lg-4 col-xl-3'
    : ''

  const handleInputRangeChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <Form.Group
      as={as}
      className={clsx('gx-0 align-items-center', className)}
      controlId={id}
    >
      {label !== '' && (
        <Form.Label
          column={rowColStructure}
          className={clsx(
            labelBreakpointClassName
              ? labelBreakpointClassName
              : defaultLabelBreakpointClassName,
            labelClassName
          )}
        >
          <span dangerouslySetInnerHTML={{ __html: label }} />
        </Form.Label>
      )}
      <div className={clsx(rowColStructure && 'col')}>
        <Stack
          direction="horizontal"
          gap={helpTitle !== '' || helpContent !== '' ? 3 : 0}
        >
          <div className="input-group">
            {dollarSign ? (
              <span className="ms-sm-n4 pe-0 input-group-text bg-white border-0">
                $&nbsp;
              </span>
            ) : null}
            <Form.Control
              type="text"
              placeholder={placeholder}
              value={value}
              onChange={handleInputRangeChange}
              disabled={disabled}
              className={clsx(dollarSign ? 'rounded' : '', inputColor)}
              onBlur={onBlur}
            />
          </div>
          {(helpTitle || helpContent) && (
            <HelpButton title={helpTitle} content={helpContent} />
          )}
        </Stack>
      </div>
    </Form.Group>
  )
}
