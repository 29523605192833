import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getHosts as getHostsQuery } from '../graphql/queries'
import {
  refreshHostCode as refreshHostCodeMutation,
  cognitoUpdateUser as cognitoUpdateUserMutation,
  updateHost as updateHostMutation,
} from '../graphql/mutations'

export const useHosts = () => {
  const getHosts = useCallback(async () => {
    return API.graphql(graphqlOperation(getHostsQuery))
  }, [])

  const refreshHostCode = useCallback(async (hostId, hostNumber) => {
    return API.graphql(
      graphqlOperation(refreshHostCodeMutation, {
        input: { hostId, hostNumber },
      })
    )
  }, [])

  const updateHostCodeInCognito = useCallback(
    (groupName, userId, newAccessCode) => {
      const userName = `${groupName}${userId}`

      return API.graphql(
        graphqlOperation(cognitoUpdateUserMutation, {
          input: { userName, newAccessCode },
        })
      )
    },
    []
  )

  const getHostNumber = useCallback((hosts, hostId) => {
    return hosts.findIndex((host) => host.id === hostId)
  }, [])

  const updateHostName = useCallback((id, hostName) => {
    return API.graphql(
      graphqlOperation(updateHostMutation, {
        input: { id, hostName },
      })
    )
  }, [])

  return {
    getHosts,
    getHostNumber,
    refreshHostCode,
    updateHostCodeInCognito,
    updateHostName,
  }
}
