import React, { useState, useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import Tile from './Tile.jsx'
import Knob from './Knob'

import './style.css'

import { useAuth } from '../../hooks/useAuth'
import { useAppState } from '../../hooks/useAppState'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { NOTIF_CH_FIRM_DESIGN } from '../../utils/constants'

const triangleGroup = [
  [14],
  [13, 14, 15],
  [13, 14, 15],
  [12, 13, 14, 15, 16],
  [12, 13, 14, 15, 16],
  [11, 12, 13, 14, 15, 16, 17],
  [10, 11, 12, 13, 14, 15, 16, 17, 18],
  [10, 11, 12, 13, 14, 15, 16, 17, 18],
  [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
  [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
  [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20],
  [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
  [7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
  [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
  [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  [5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
  [
    4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24,
  ],
  [
    4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24,
  ],
  [
    3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
    24, 25,
  ],
  [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26,
  ],
  [
    2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26,
  ],
  [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27,
  ],
  [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27,
  ],
  [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
    21, 22, 23, 24, 25, 26, 27, 28,
  ],
]

const snapPositions = [
  { r: 0, c: 14, value: { asMix: 1, tsMix: 0, csMix: 0 } },
  { r: 9, c: 14, value: { asMix: 0.5, tsMix: 0.25, csMix: 0.25 } },
  { r: 16, c: 14, value: { asMix: 0.34, tsMix: 0.33, csMix: 0.33 } },
  { r: 24, c: 14, value: { asMix: 0, tsMix: 0.5, csMix: 0.5 } },

  { r: 24, c: 0, value: { asMix: 0, tsMix: 1, csMix: 0 } },
  { r: 20, c: 7, value: { asMix: 0.25, tsMix: 0.5, csMix: 0.25 } },
  { r: 12, c: 21, value: { asMix: 0.5, tsMix: 0, csMix: 0.5 } },

  { r: 24, c: 28, value: { asMix: 0, tsMix: 0, csMix: 1 } },
  { r: 20, c: 21, value: { asMix: 0.25, tsMix: 0.25, csMix: 0.5 } },
  { r: 12, c: 7, value: { asMix: 0.5, tsMix: 0.5, csMix: 0 } },
]

const getPositionFromValue = (value) => {
  if (value) {
    const pos = snapPositions.find(
      (element) =>
        element.value.asMix === value.asMix &&
        element.value.csMix === value.csMix &&
        element.value.tsMix === value.tsMix
    )

    return pos ? pos : { r: 12, c: 7 }
  } else {
    return { r: 12, c: 7 }
  }
}

const InputTriangle = ({ value, disabled = false }) => {
  const rows = 25
  const cols = 29
  const tileSize = 10
  const constraintsRef = useRef(null)
  const { sendRTNotification } = useNotificationChannel()
  const { userId } = useAuth()
  const [row, setRow] = useState()
  const [col, setCol] = useState()

  const {
    state: { me, rtNotification },
    dispatch,
  } = useAppState()

  const handleInputChange = (nv) => {
    // Send real-time notification update to the team members
    const statusInput = {
      to: rtNotification.channel,
      message: JSON.stringify({
        teamId: userId,
        type: 'set-lobMix',
        value: nv,
      }),
      updater: me,
      type: NOTIF_CH_FIRM_DESIGN,
    }

    sendRTNotification(statusInput).catch((err) => {
      console.log('sendRTNotification failed', err)
    })

    // Update the app state
    dispatch({ type: 'set-lobMix', payload: nv })
  }

  /**
   * Tiles Grid is for visual debuging purposes
   * */
  const renderTiles = () => {
    let boardTiles = []
    let counter = 0

    for (let r = 0; r < rows; r++) {
      for (let c = 0; c < cols; c++) {
        counter = r * 29 + c

        const id = c + '_' + r
        const color = triangleGroup[r].includes(c) ? 'white' : 'black'
        boardTiles.push(
          <Tile key={counter} id={id} col={c} row={r} color={color} />
        )
      }
    }
    return boardTiles
  }

  const onDropHandler = (cx, cy, row, col) => {
    const dropCol = Math.trunc(cx / tileSize)
    const dropRow = Math.trunc(cy / tileSize)

    /**
     * Helper element is for visual debuging purposes
     * let h = document.getElementById('helper')
     * h.style.top = cy + 'px'
     * h.style.left = cx + 'px'
     * */

    const check = triangleGroup[dropRow].includes(dropCol)

    const dropPoint = solvePosition(dropRow, dropCol)
    const points = snapPositions.map((pos) => {
      return solvePosition(pos.r, pos.c)
    })

    const closest = points.reduce((a, b) =>
      distance(a, dropPoint) < distance(b, dropPoint) ? a : b
    )

    const newValues = snapPositions.find(
      (element) =>
        element.r === Math.trunc(closest.y / tileSize) &&
        element.c === Math.trunc(closest.x / tileSize)
    )

    handleInputChange(newValues.value)

    return check
      ? {
          c: Math.trunc(closest.x / tileSize),
          r: Math.trunc(closest.y / tileSize),
        }
      : {
          c: col,
          r: row,
        }
  }

  const solvePosition = (r, c) => {
    return { x: c * tileSize, y: r * tileSize }
  }

  /*checks euclidean distance between points */
  const distance = (p, point) => {
    return Math.sqrt(Math.pow(point.x - p.x, 2) + Math.pow(point.y - p.y, 2))
  }

  useEffect(() => {
    if (!value) {
      return
    }

    const r = getPositionFromValue(value).r
    const c = getPositionFromValue(value).c

    if (row === r && col === c) {
      return
    }

    setRow(r)
    setCol(c)
  }, [value, row, col])

  return (
    <motion.div
      className="input-triangle-wrapper drag-area"
      ref={constraintsRef}
    >
      {/*renderTiles()*/}
      <div className="input-triangle-label as">Assurance</div>
      <div className="input-triangle-label ta">Tax</div>
      <div className="input-triangle-label cs">Consulting</div>
      <Knob
        constraintsRef={constraintsRef}
        key="5"
        color="white"
        col={col}
        row={row}
        setCol={setCol}
        setRow={setRow}
        dropCallback={onDropHandler}
        turn={'white'}
        disabled={disabled}
      />
      {/*<div id="helper"></div>*/}
    </motion.div>
  )
}

export default InputTriangle
