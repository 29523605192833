import Form from 'react-bootstrap/Form'

// import HelpButton from '../../components/HelpButton'

export default function InputSelect({
  id,
  label,
  helpTitle,
  helpContent,
  options,
  value,
  setValue,
  disabled = false,
  onChange,
}) {
  return (
    <Form.Select
      aria-label={label}
      className="mb-3"
      disabled={disabled}
      value={value}
      onChange={onChange}
    >
      <option>{label}</option>
      {options.map((opt) => (
        <option key={opt.key} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </Form.Select>
  )
}
