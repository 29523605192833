import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import Spinner from 'react-bootstrap/Spinner'

import './style.css'

const LoadingData = ({ show }) => {
  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      contentClassName="rounded-0 shadow-sm px-2 py-2"
      dialogClassName="loading-data-modal"
    >
      <Modal.Body>
        <Stack direction="horizontal" gap={2} className="align-items-center">
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="primary"
          />
          <span>Loading...</span>
        </Stack>
      </Modal.Body>
    </Modal>
  )
}

export default LoadingData
