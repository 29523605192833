import { useState } from 'react'
import Button from 'react-bootstrap/Button'

import { ReactComponent as HelpIcon } from '../../images/icons/help-icon.svg'
import BlsModal from '../BlsModal'

export default function HelpButton({ title, content }) {
  const [show, setShow] = useState(false)

  const handleShow = () => setShow(true)

  return (
    <>
      <BlsModal show={show} setShow={setShow} title={title} body={content} />
      <Button variant="outline" className="p-0" onClick={handleShow}>
        <HelpIcon width="24" height="24" fill="currentColor" />
      </Button>
    </>
  )
}
