import { useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'

import './style.css'

import HelpButton from '../HelpButton'
import ModalConfirm from '../ModalConfirm'

import { useAppState } from '../../hooks/useAppState'
import { useTeam } from '../../hooks/useTeam'
import { useHost } from '../../hooks/useHost'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { NOTIF_CH_HOST_ADVANCE_SIM_YEAR } from '../../utils/constants'

const HostAdvanceYear = () => {
  const {
    state: {
      me,
      year,
      offering: { offeringId },
      rtNotification,
    },
    dispatch,
  } = useAppState()
  const { getTeamsByOfferingId } = useTeam()
  const { advanceSimYear } = useHost()
  const {
    state: {
      hostDashboardAdvanceRefetchTeam,
      teamConfirmedDesign,
      hostMergeTeams,
    },
    dispatch: dispatchNC,
    sendRTNotification,
  } = useNotificationChannel()
  const [teams, setTeams] = useState([])
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [isAdvancing, setIsAdvancing] = useState(false)

  const fetchTeamsInThisOfferings = useCallback(
    async (showLoading = true) => {
      if (showLoading) {
        setLoading(true)
      }
      getTeamsByOfferingId(offeringId)
        .then((getTeamsByOfferingIdData) => {
          setTeams(
            getTeamsByOfferingIdData.data.getTeamsByOfferingId.filter(
              (team) => team.mergedWith === null
            )
          )
        })
        .finally(() => {
          if (showLoading) {
            setLoading(false)
          }
        })
    },
    [getTeamsByOfferingId, offeringId]
  )

  /**
   * Load the team info
   */
  useEffect(() => {
    fetchTeamsInThisOfferings()
  }, [fetchTeamsInThisOfferings])

  /**
   * Fetch the team info whenever we receive this notification
   */
  useEffect(() => {
    if (hostDashboardAdvanceRefetchTeam) {
      fetchTeamsInThisOfferings(false)
      dispatchNC({ type: 'host-dashboard-advance-refetch-team-executed' })
    }
  }, [hostDashboardAdvanceRefetchTeam, fetchTeamsInThisOfferings, dispatchNC])

  /**
   * Fetch the teams info whenever we receive this notification
   * that the team has confirmed their design
   */
  useEffect(() => {
    if (teamConfirmedDesign.enabled) {
      fetchTeamsInThisOfferings(false)
      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [dispatchNC, fetchTeamsInThisOfferings, teamConfirmedDesign.enabled])

  const handleAdvanceClick = async () => {
    setConfirm(false)
    setIsAdvancing(true)

    // Update all teams' year to year + 1
    // Update all teams' confirm to false
    try {
      await advanceSimYear(offeringId, year + 1)

      // Save the new year in the app state
      dispatch({ type: 'set-year', payload: year + 1 })

      // Send real-time notification
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ year: year + 1, started: true }),
        updater: me,
        type: NOTIF_CH_HOST_ADVANCE_SIM_YEAR,
      }
      sendRTNotification(statusInput)

      // Refetch teams data
      fetchTeamsInThisOfferings()
    } catch (err) {
      console.log('Advancing to next year failed', err)
    } finally {
      setIsAdvancing(false)
    }
  }

  /**
   * Fetch the teams info whenever we receive this notification
   * that the merge has been completed
   */
  useEffect(() => {
    if (offeringId === null || offeringId === -1) {
      return
    }

    if (
      hostMergeTeams.enabled &&
      hostMergeTeams.message &&
      hostMergeTeams.message.completed &&
      hostMergeTeams.in &&
      !hostMergeTeams.in.includes('HostAdvanceYear')
    ) {
      fetchTeamsInThisOfferings()
      dispatchNC({
        type: 'host-merge-teams-executed',
        payload: { in: 'HostAdvanceYear' },
      })
    }
  }, [hostMergeTeams, dispatchNC, fetchTeamsInThisOfferings, offeringId])

  return (
    <div className="mt-3 mt-lg-5">
      <ModalConfirm
        title="Advance Simulation Year"
        body={`<p>Are you sure you want to advance to year ${
          year + 1
        }?</p><p>This action cannot be undone.</p>`}
        show={confirm}
        headerTextColor="text-danger"
        spinnerVariant="danger"
        handleClose={() => setConfirm(false)}
        handleConfirm={handleAdvanceClick}
      />

      <Stack direction="horizontal" gap={2} className="mb-3">
        <h2 className="text-primary h5 fw-bold mb-0">
          Advance Simulation Year
        </h2>
        <HelpButton
          title="Advance Simulation Year"
          content="<p>Advance the simulation year to trigger a number of actions for all users, including changing which businesses are up for bid. Participants will not be able to submit bids for the following year until <strong>Advance to Next Year</strong> has been clicked.</p>"
        />
      </Stack>
      <p>
        When all teams are ready to move to the next year of the simulation,
        click <strong>Advance to Next Year</strong>.
      </p>
      <p>
        You cannot reverse this action, so be sure to double check before
        advancing!
      </p>
      <p className="fw-bold">Current Year: {year}</p>
      <Table
        responsive
        className="table-borderless w-100 mt-2 mb-4 align-middle advance-year-status-tbl"
      >
        <tbody>
          {loading ? (
            <tr>
              <td width="75%">
                <Spinner
                  as="div"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                />
              </td>
              <td className="status">
                <Spinner
                  as="div"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  variant="secondary"
                />
              </td>
            </tr>
          ) : (
            teams.map((team, teamIndex) => (
              <tr key={team.name !== '' ? team.name : `team---${teamIndex}`}>
                <td width="75%">
                  {team.name !== '' ? team.name : `Firm${teamIndex + 1}`}
                </td>
                <td className={clsx('status', team.ready && 'ready')}>
                  <span className="indicator"></span>{' '}
                  {team.ready ? 'Ready!' : 'Not Ready'}
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Button
        variant="outline-success"
        className="rounded-pill"
        disabled={isAdvancing || year === 5}
        onClick={() => setConfirm(true)}
      >
        {isAdvancing && (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="success"
            className="me-2"
          />
        )}
        Advance to Next Year
      </Button>
    </div>
  )
}

export default HostAdvanceYear
