import { useEffect, useState } from 'react'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import clsx from 'clsx'

import { retrieveSmallerEngagements } from '../../utils/bls-utils'
import { NOTIF_CH_RUN_RETRIEVE } from '../../utils/constants'

import { useAppState } from '../../hooks/useAppState'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import { useAuth } from '../../hooks/useAuth'
import { useIncome } from '../../hooks/useIncome'

export default function RunRetrieve() {
  const { userId } = useAuth()
  const {
    state: {
      me,
      year,
      ready,
      design: {
        lobMix: { asMix, tsMix, csMix },
        expertise,
        numberOfPartners,
      },
      offering: { offeringId },
      rtNotification,
    },
    dispatch,
  } = useAppState()
  const {
    state: { teamRunExecuteRefetchOtherIncome },
    dispatch: dispatchNC,
    sendRTNotification,
  } = useNotificationChannel()
  const { addOtherIncome, getOtherIncomeByYear } = useIncome()
  const [numAsClients, setNumAsClients] = useState(null)
  const [numTsClients, setNumTsClients] = useState(null)
  const [numCsClients, setNumCsClients] = useState(null)
  const [asProfit, setAsProfit] = useState(null)
  const [tsProfit, setTsProfit] = useState(null)
  const [csProfit, setCsProfit] = useState(null)
  const [totalProfits, setTotalProfits] = useState(null)
  const [isRetrieving, setIsRetrieving] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * Fetch other income for this year if already retrieved
   */
  useEffect(() => {
    let isMounted = true
    if (offeringId === null || offeringId < 0 || year === null) {
      return
    }

    setLoading(true)
    getOtherIncomeByYear(userId, year)
      .then((otherIncomeData) => {
        if (otherIncomeData.data.getOtherIncomes.length > 0) {
          const data = otherIncomeData.data.getOtherIncomes[0]
          if (isMounted) {
            setNumAsClients(data.numAsClients)
            setNumTsClients(data.numTsClients)
            setNumCsClients(data.numCsClients)
            setAsProfit(data.asProfit)
            setTsProfit(data.tsProfit)
            setCsProfit(data.csProfit)
            setTotalProfits(data.profit)
          }
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    return () => {
      isMounted = false
    }
  }, [getOtherIncomeByYear, userId, year, offeringId])

  /**
   * Refetch other income whenever we receive this notification that a team
   * member has run "Retrieve"
   */
  useEffect(() => {
    let isMounted = true
    if (
      teamRunExecuteRefetchOtherIncome.enabled &&
      teamRunExecuteRefetchOtherIncome.message.teamId === userId
    ) {
      setLoading(true)
      getOtherIncomeByYear(userId, year)
        .then((otherIncomeData) => {
          if (otherIncomeData.data.getOtherIncomes.length > 0) {
            const data = otherIncomeData.data.getOtherIncomes[0]
            if (isMounted) {
              setNumAsClients(data.numAsClients)
              setNumTsClients(data.numTsClients)
              setNumCsClients(data.numCsClients)
              setAsProfit(data.asProfit)
              setTsProfit(data.tsProfit)
              setCsProfit(data.csProfit)
              setTotalProfits(data.profit)
            }
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false)
          }
        })
      dispatchNC({
        type: 'team-run-retrieve-refetch-other-income-executed',
      })
    }
    return () => {
      isMounted = false
    }
  }, [
    teamRunExecuteRefetchOtherIncome.enabled,
    teamRunExecuteRefetchOtherIncome.message.teamId,
    dispatchNC,
    getOtherIncomeByYear,
    userId,
    year,
  ])

  const handleRetrieveResults = async () => {
    if (userId === null) {
      console.log('Retrieve results failed :: User id not found')
      return
    }

    setIsRetrieving(true)
    const [
      _numAsClients,
      _numTsClients,
      _numCsClients,
      _asProfit,
      _tsProfit,
      _csProfit,
      _totalProfits,
    ] = retrieveSmallerEngagements(
      asMix,
      tsMix,
      csMix,
      expertise,
      numberOfPartners
    )

    try {
      // Save income to the database
      await addOtherIncome(
        offeringId,
        userId,
        year,
        _numAsClients,
        _numTsClients,
        _numCsClients,
        _asProfit,
        _tsProfit,
        _csProfit,
        _totalProfits
      )

      // Send real-time notification
      const statusInput = {
        to: rtNotification.channel,
        message: JSON.stringify({ teamId: userId }),
        updater: me,
        type: NOTIF_CH_RUN_RETRIEVE,
      }
      sendRTNotification(statusInput)

      // Disable the retrieve button
      dispatch({
        type: 'set-other-profits',
        payload: {
          asProfit: _asProfit,
          _tsProfit: tsProfit,
          csProfit: _csProfit,
          totalProfit: _totalProfits,
        },
      })
    } catch (err) {
      console.log('Saving income to database failed', err)
    } finally {
      setNumAsClients(_numAsClients)
      setNumTsClients(_numTsClients)
      setNumCsClients(_numCsClients)
      setAsProfit(_asProfit)
      setTsProfit(_tsProfit)
      setCsProfit(_csProfit)
      setTotalProfits(_totalProfits)
      setIsRetrieving(false)
    }
  }

  return (
    <Container>
      <Row>
        <Col lg={4}>
          <h2 className="text-primary h5 fw-bold">You’ve Been Busy!</h2>
          <p>
            It’s not just the high-profile engagements that make up the
            financials of your firm. There’s also your portfolio of smaller
            engagements! Your firm has been busy, and those results show here.
          </p>
          <p>
            How profitable were your smaller engagements this year? Click{' '}
            <strong>Retrieve Results</strong> to find out!
          </p>
        </Col>
        <Col>
          <Stack direction="horizontal" className="align-items-start" gap={4}>
            <div className="d-none d-lg-flex" style={{ height: 380 }}>
              <div className="vr"></div>
            </div>
            <div className="flex-grow-1">
              <h2 className="text-success h3 fw-bold">
                Retrieve Other Results
              </h2>
              <Table responsive className="w-100 mt-4 align-middle run-table">
                <thead className="bg-gray-100">
                  <tr>
                    <th width="40%">No.</th>
                    <th>LoB</th>
                    <th width="20%">Total Profit</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : numTsClients === null ? (
                        '--'
                      ) : (
                        numTsClients
                      )}
                    </td>
                    <td>Tax</td>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : tsProfit === null ? (
                        '--'
                      ) : (
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(tsProfit)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : numAsClients === null ? (
                        '--'
                      ) : (
                        numAsClients
                      )}
                    </td>
                    <td>Assurance</td>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : asProfit === null ? (
                        '--'
                      ) : (
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(asProfit)
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : numCsClients === null ? (
                        '--'
                      ) : (
                        numCsClients
                      )}
                    </td>
                    <td>Consulting</td>
                    <td>
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : csProfit === null ? (
                        '--'
                      ) : (
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(csProfit)
                      )}
                    </td>
                  </tr>
                </tbody>
                <tfoot className="fw-bold">
                  <tr className="border-top">
                    <td colSpan={2}>Total Year</td>
                    <td
                      className={clsx(
                        !isNaN(parseInt(totalProfits)) &&
                          parseInt(totalProfits) < 0 &&
                          'text-danger'
                      )}
                    >
                      {loading ? (
                        <Spinner
                          as="div"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                          variant="secondary"
                        />
                      ) : totalProfits === null ? (
                        '--'
                      ) : (
                        new Intl.NumberFormat('en-US', {
                          style: 'currency',
                          currency: 'USD',
                          currencySign: 'accounting',
                          signDisplay: 'auto',
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        }).format(totalProfits)
                      )}
                    </td>
                  </tr>
                </tfoot>
              </Table>

              <Button
                variant="outline-success"
                className="rounded-pill d-block mx-auto mt-5"
                disabled={
                  isRetrieving || loading || totalProfits !== null || ready
                }
                onClick={handleRetrieveResults}
              >
                {isRetrieving && (
                  <Spinner
                    as="div"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    variant="success"
                    className="me-2"
                  />
                )}
                Retrieve Results
              </Button>
            </div>
          </Stack>
        </Col>
      </Row>
    </Container>
  )
}
