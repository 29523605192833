import { useState } from 'react'
import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { API, graphqlOperation } from 'aws-amplify'

import ModalConfirm from '../ModalConfirm'
import ModalProgress from '../ModalProgress'

import {
  removeOffering as removeOfferingMutation,
  cognitoDeleteUser as cognitoDeleteUserMutation,
} from '../../graphql/mutations'
import { useAuth } from '../../hooks/useAuth'
import { useHost } from '../../hooks/useHost'
import { useLeadersByOfferingId } from '../../hooks/useLeadersByOfferingId'
import { useTeamsByOfferingId } from '../../hooks/useTeamsByOfferingId'
import { useStats } from '../../hooks/useStats'

export default function HostEndSimulation({ onEnded }) {
  const { userId } = useAuth()
  const { fetchHost } = useHost()
  const { getLeadersByOfferingId } = useLeadersByOfferingId()
  const { getTeamsByOfferingId } = useTeamsByOfferingId()
  const { addTeamStats } = useStats()
  const [confirm, setConfirm] = useState(false)
  const [removing, setRemoving] = useState(false)

  const handleConfirm = () => {
    setConfirm(true)
  }

  const handleEndSimulation = async () => {
    setConfirm(false)
    setRemoving(true)

    // Fetch host info
    try {
      const host = await fetchHost(userId)

      if (!host) {
        return
      }

      const offeringId = host.data.getHost.offering.id

      // Get leader info
      const leadersPromise = getLeadersByOfferingId(offeringId)
      const teamsPromise = getTeamsByOfferingId(offeringId)
      const leadersTeams = await Promise.all([leadersPromise, teamsPromise])
      const leaders = leadersTeams[0].data.getLeadersByOfferingId.map(
        (data) => data.id
      )
      const teams = leadersTeams[1].data.getTeamsByOfferingId.map(
        (data) => data.id
      )
      let removePromise = []

      try {
        // Save the team stats before removing the data
        await addTeamStats(offeringId)
      } catch (err) {
        console.log('Error saving team stats', err)
      }

      try {
        // Remove offering
        removePromise.push(
          API.graphql(
            graphqlOperation(removeOfferingMutation, {
              input: { id: offeringId },
            })
          )
        )

        // Remove cognito users
        leaders.forEach((id) => {
          removePromise.push(
            API.graphql(
              graphqlOperation(cognitoDeleteUserMutation, {
                input: { groupName: 'Leader', userId: id },
              })
            )
          )
        })
        teams.forEach((id) => {
          removePromise.push(
            API.graphql(
              graphqlOperation(cognitoDeleteUserMutation, {
                input: { groupName: 'Team', userId: id },
              })
            )
          )
        })

        await Promise.all(removePromise)
      } catch (err) {
        console.log('Remove offering failed', err)
      } finally {
        // Finally signal successful ended
        onEnded()
        setRemoving(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className="mt-3 mt-lg-5">
      <ModalConfirm
        title="End Simulation"
        body={`<p>Are you sure you want to end this simulation?</p><p>This action cannot be undone.</p>`}
        show={confirm}
        headerTextColor="text-danger"
        spinnerVariant="danger"
        handleClose={() => setConfirm(false)}
        handleConfirm={handleEndSimulation}
      />
      <ModalProgress
        body={`Ending simulation... Do not close/refresh your browser.`}
        show={removing}
        headerTextColor="text-danger"
        spinnerVariant="danger"
      />
      <Stack direction="horizontal" gap={2} className="mb-3">
        <h2 className="text-primary h5 fw-bold mb-0">End Simulation</h2>
      </Stack>
      <p>
        When the active simulation concludes, click{' '}
        <strong>End Simulation</strong>. This will delete your active simulation
        and redirect you to the first Host Dashboard page. From there, you can
        create a new simulation.
      </p>
      <p>
        On click of <strong>End Simulation</strong>, the active simulation is
        wiped from the web application’s database. You cannot reverse this
        action, so be sure to double check before ending!
      </p>
      <div className="text-center">
        <Button
          variant="outline-danger"
          className="rounded-pill px-3 mt-2"
          disabled={removing}
          onClick={handleConfirm}
        >
          {removing && (
            <Spinner
              as="div"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="danger"
              className="me-2"
            />
          )}
          End Simulation
        </Button>
      </div>
    </div>
  )
}
