import { createContext, useContext, useReducer } from 'react'

const AppStateContext = createContext()

export const initialState = {
  year: null,
  ready: false,
  design: {
    confirm: null,
    yearConfirm: null,
    name: '',
    tagline: '',
    values: '',
    numberOfPartners: 1,
    expertise: 5,
    industries: {
      fs: false,
      cs: false,
      hc: false,
      in: false,
      np: false,
      ls: false,
      tmt: false,
    },
    lobMix: {
      asMix: 0.34,
      tsMix: 0.33,
      csMix: 0.33,
    },
    infra: 5,
    empDev: 5,
    comp: 5,
    staffing: 5,
    pracDev: 5,
    pracDevBal: 5,
    philo: 5,
    geoFocus: 5,
  },
  run: {
    // Hold all submitted bids
    bids: [],
    // Other results
    otherProfits: {
      asProfit: null,
      tsProfit: null,
      csProfit: null,
      totalProfit: null,
    },
    // Hold last visited tab name
    lastVisitedTab: '',
  },
  // Hold each offering data
  offering: {
    offeringId: null,
  },
  // Host: Hold chats from teams
  chats: [],
  // Error
  error: {
    // When true, show the modal telling the user to reload the page.
    reloadRequired: false,
  },
}

const initialIdentifyState = {
  // My identity (use in design firm page)
  me: '',
  // Real-time notification
  rtNotification: {
    channel: '', // Must be unique for each offering
  },
}

function reducer(state, action) {
  switch (action.type) {
    case 'reset-state': {
      return { ...initialState, ...initialIdentifyState }
    }
    case 'reset-the-year': {
      return {
        ...state,
        year: state.year + 1,
        ready: true,
        run: {
          ...state.run,
          bids: [],
          otherProfits: {
            asProfit: null,
            tsProfit: null,
            csProfit: null,
            totalProfit: null,
          },
        },
      }
    }
    case 'set-year': {
      return { ...state, year: parseInt(action.payload) }
    }
    case 'set-ready': {
      return { ...state, ready: action.payload }
    }
    case 'set-firm-name': {
      return {
        ...state,
        design: {
          ...state.design,
          name: action.payload,
        },
      }
    }
    case 'set-firm-tagline': {
      return {
        ...state,
        design: {
          ...state.design,
          tagline: action.payload,
        },
      }
    }
    case 'set-firm-values': {
      return {
        ...state,
        design: {
          ...state.design,
          values: action.payload,
        },
      }
    }
    case 'set-expertise': {
      return {
        ...state,
        design: {
          ...state.design,
          expertise: parseInt(action.payload),
        },
      }
    }
    case 'set-number-partners': {
      return {
        ...state,
        design: {
          ...state.design,
          numberOfPartners: parseInt(action.payload),
        },
      }
    }
    case 'set-infrastructure': {
      return {
        ...state,
        design: {
          ...state.design,
          infra: parseInt(action.payload),
        },
      }
    }
    case 'set-employee-dev': {
      return {
        ...state,
        design: {
          ...state.design,
          empDev: parseInt(action.payload),
        },
      }
    }
    case 'set-compensation': {
      return {
        ...state,
        design: {
          ...state.design,
          comp: parseInt(action.payload),
        },
      }
    }
    case 'set-staffing': {
      return {
        ...state,
        design: {
          ...state.design,
          staffing: parseInt(action.payload),
        },
      }
    }
    case 'set-practice-dev': {
      return {
        ...state,
        design: {
          ...state.design,
          pracDev: parseInt(action.payload),
        },
      }
    }
    case 'set-practice-dev-balance': {
      return {
        ...state,
        design: {
          ...state.design,
          pracDevBal: parseInt(action.payload),
        },
      }
    }
    case 'set-philosophy': {
      return {
        ...state,
        design: {
          ...state.design,
          philo: parseInt(action.payload),
        },
      }
    }
    case 'set-geographic-focus': {
      return {
        ...state,
        design: {
          ...state.design,
          geoFocus: parseInt(action.payload),
        },
      }
    }
    case 'set-industry': {
      return {
        ...state,
        design: {
          ...state.design,
          industries: {
            ...state.design.industries,
            ...action.payload,
          },
        },
      }
    }
    case 'set-lobMix': {
      return {
        ...state,
        design: {
          ...state.design,
          lobMix: action.payload,
        },
      }
    }
    case 'set-confirm': {
      return {
        ...state,
        design: {
          ...state.design,
          confirm: true,
        },
      }
    }
    case 'set-yearConfirm': {
      return {
        ...state,
        design: {
          ...state.design,
          yearConfirm: action.payload,
        },
      }
    }
    case 'set-firm': {
      const {
        confirm,
        name,
        tagline,
        values,
        numberOfPartners,
        expertise,
        industries,
        lobMix,
        infra,
        empDev,
        comp,
        staffing,
        pracDev,
        pracDevBal,
        philo,
        geoFocus,
        yearConfirm,
      } = action.payload
      return {
        ...state,
        design: {
          confirm,
          name,
          tagline,
          values,
          numberOfPartners,
          expertise,
          industries: JSON.parse(industries),
          lobMix: JSON.parse(lobMix),
          infra,
          empDev,
          comp,
          staffing,
          pracDev,
          pracDevBal,
          philo,
          geoFocus,
          yearConfirm,
        },
      }
    }
    /**
     * Run
     */
    case 'add-bid': {
      return {
        ...state,
        run: {
          ...state.run,
          bids: [...state.run.bids, action.payload],
        },
      }
    }
    case 'set-bids': {
      return {
        ...state,
        run: {
          ...state.run,
          bids: action.payload,
        },
      }
    }
    case 'set-other-profits': {
      return {
        ...state,
        run: {
          ...state.run,
          otherProfits: action.payload,
        },
      }
    }
    case 'set-run-tab': {
      return {
        ...state,
        run: {
          ...state.run,
          lastVisitedTab: action.payload,
        },
      }
    }
    /**
     * Offering
     */
    // Number of teams
    case 'set-number-teams': {
      return {
        ...state,
        offering: {
          ...state.offering,
          numberOfTeams: parseInt(action.payload),
        },
      }
    }
    // Offering id
    case 'set-offering-id': {
      return {
        ...state,
        offering: {
          ...state.offering,
          offeringId: action.payload,
        },
      }
    }
    // Set program host data
    case 'set-program-host': {
      const { id, accessCode, hostName } = action.payload
      const newHost = state.program.hosts.map((host) =>
        host.id === id
          ? {
              id,
              accessCode: accessCode ? accessCode : host.accessCode,
              hostName: hostName ? hostName : host.hostName,
            }
          : host
      )

      return {
        ...state,
        program: {
          ...state.program,
          host: newHost,
        },
      }
    }
    // My identity
    case 'set-me': {
      return {
        ...state,
        me: action.payload,
      }
    }
    // Real-time notification
    case 'set-notification-channel': {
      return {
        ...state,
        rtNotification: {
          ...state.rtNotification,
          channel: action.payload,
        },
      }
    }
    // Chats from teams for Host
    case 'add-chat': {
      const chat = action.payload
      const teamChat = state.chats.find(
        (c) => `${c.receiver}` === `${chat.receiver}`
      )
      let newChats = []

      if (teamChat) {
        const otherTeamChats = state.chats.filter(
          (c) => `${c.receiver}` !== `${chat.receiver}`
        )

        newChats = [
          ...otherTeamChats,
          { ...teamChat, chats: [...teamChat.chats, chat] },
        ]
      } else {
        newChats = [
          ...state.chats,
          { receiver: `${chat.receiver}`, chats: [chat] },
        ]
      }

      return {
        ...state,
        chats: newChats,
      }
    }
    // Turn on require-reload
    case 'reload-on': {
      return {
        ...state,
        error: { ...state.error, reloadRequired: true },
      }
    }
    // Turn off require-reload
    case 'reload-off': {
      return {
        ...state,
        error: { ...state.error, reloadRequired: false },
      }
    }
    default:
      return state
  }
}

export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(
    (state, action) => reducer(state, action),
    { ...initialState, ...initialIdentifyState }
  )

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  )
}

export const useAppState = () => {
  return useContext(AppStateContext)
}
