import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LeaderAwardTotalWonEngagement from '../../components/LeaderAwardTotalWonEngagement'
import LeaderAwardTargetList from '../../components/LeaderAwardTargetList'

import './style.css'

export default function AwardBids() {
  return (
    <Container as="main" className="pb-5 main">
      <Row>
        <Col md={{ span: 10, offset: 1 }}>
          <h1 className="text-center text-primary fw-bold">
            Award High-Profile Engagements
          </h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <div className="shadow-plain px-3 py-2 mt-4 mb-5 rounded">
            <p>
              Select a business below to view the bids received. Once you have
              determined the winning bid, award the firm with the high-profile
              work.
            </p>
            <p className="mb-0">
              Remember, each Client Decision-Making Facilitator awards work for{' '}
              <strong>specific</strong> businesses. To determine which
              businesses you are responsible for, consult the Leader Guide.
            </p>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }}>
          <LeaderAwardTotalWonEngagement />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <LeaderAwardTargetList />
        </Col>
      </Row>
    </Container>
  )
}
