import { useEffect, useState } from 'react'
import Stack from 'react-bootstrap/Stack'
import Spinner from 'react-bootstrap/Spinner'

import { useLeadersByOfferingId } from '../../hooks/useLeadersByOfferingId'
import { useAppState } from '../../hooks/useAppState'

import HelpButton from '../HelpButton'

import personIcon from '../../images/icons/person-icon.svg'

export default function HostLeaderAccessCode() {
  const { getLeadersByOfferingId } = useLeadersByOfferingId()
  const [leaders, setLeaders] = useState([])
  const [loading, setLoading] = useState(true)
  const {
    state: {
      offering: { offeringId },
    },
  } = useAppState()

  /**
   * Load leader info every time the page is visited
   */
  useEffect(() => {
    if (offeringId === -1) {
      return
    }

    getLeadersByOfferingId(offeringId)
      .then((leaderData) => {
        const l = leaderData.data.getLeadersByOfferingId.map(
          (leader) => leader.code.code
        )

        setLeaders(l)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [offeringId, getLeadersByOfferingId])

  return (
    <div className="mt-3 mt-lg-0">
      <Stack direction="horizontal" gap={2}>
        <h2 className="text-primary h5 fw-bold mb-0">Leader Access Code</h2>
        <HelpButton
          title="Leader Access Code"
          content="Distribute the Leader Access Code to the facilitation team before the simulation runs. All Leaders/Facilitators share the same code."
        />
      </Stack>
      <Stack className="align-items-center mt-5" gap={3}>
        <img src={personIcon} alt="person" width={195} height={195} />
        {loading && (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="secondary"
          />
        )}
        {leaders.length > 0 && (
          <span className="h4 text-success fw-bold">{leaders[0]}</span>
        )}
      </Stack>
    </div>
  )
}
