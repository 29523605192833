/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onStatusUpdate = /* GraphQL */ `
  subscription OnStatusUpdate($to: String!) {
    onStatusUpdate(to: $to) {
      to
      message
      type
      updater
      updatedAt
    }
  }
`;
export const onChatUpdate = /* GraphQL */ `
  subscription OnChatUpdate($to: String!) {
    onChatUpdate(to: $to) {
      to
      sender
      receiver
      message
      type
      sentAt
    }
  }
`;
