import clsx from 'clsx'
import Modal from 'react-bootstrap/Modal'
import Stack from 'react-bootstrap/Stack'
import Spinner from 'react-bootstrap/Spinner'

const ModalProgress = ({
  title = '',
  body = '',
  children,
  show = false,
  headerTextColor = 'text-primary',
  spinnerVariant = 'primary',
}) => {
  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      contentClassName="rounded-0 shadow-sm px-2 py-2"
    >
      {title !== '' && (
        <Modal.Header className="border-0 pb-0">
          <Modal.Title className={clsx('h5 fw-bold', headerTextColor)}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body>
        {body !== '' && (
          <Stack direction="horizontal" gap={2} className="align-items-start">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              variant={spinnerVariant}
              className="mt-1"
            />
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </Stack>
        )}
        {children && children}
      </Modal.Body>
    </Modal>
  )
}

export default ModalProgress
