import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getTargets } from '../graphql/queries'

export const useTarget = () => {
  const fetchTargets = useCallback(async (offeringId) => {
    return API.graphql(graphqlOperation(getTargets, { offeringId }))
  }, [])

  return {
    fetchTargets,
  }
}
