import { useEffect, useState, useCallback, useRef } from 'react'
import Stack from 'react-bootstrap/Stack'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Badge from 'react-bootstrap/Badge'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import clsx from 'clsx'

import { NOTIF_CH_RUN_EXECUTE } from '../../utils/constants'

import greenCheckmarkIcon from '../../images/icons/green-checkmark-icon.svg'

import BlsModal from '../BlsModal'
import InputText from '../InputText'

import './style.css'

import { useAuth } from '../../hooks/useAuth'
import { useAppState } from '../../hooks/useAppState'
import { useClient } from '../../hooks/useClient'
import { useIncome } from '../../hooks/useIncome'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import {
  calcAnticipatedHours,
  calcCostPerEngagementHour,
  didTeamBlowBudget,
  calcActualHours,
  calcEngagementProfit,
  getClientSatisfaction,
  doesDropFirm,
  doesAddLOB,
  getNewLOB,
  calcNextYearFee,
} from '../../utils/bls-utils'
import {
  SATISFACTION_DELIGHTED,
  SATISFACTION_DISSATISFIED,
  SATISFACTION_SATISFIED,
} from '../../utils/constants'
import { formatCurrency } from '../../utils/utils'

export default function RunExecute() {
  const { userId } = useAuth()
  const {
    state: { year },
  } = useAppState()
  const {
    state: { teamRunExecuteRefetchActiveClients },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { getMyActiveClients } = useClient()
  const [clients, setClients] = useState([])
  const [financialModalShow, setFinancialModalShow] = useState(false)
  const [satisfactionModalShow, setSatisfactionModalShow] = useState(false)
  const [viewResultsModalShow, setViewResultsModalShow] = useState(false)
  const [financialModalBody, setFinancialModalBody] = useState('')
  const [satisfactionModalBody, setSatisfactionModalBody] = useState('')
  const [curClientDisplayName, setCurClientDisplayName] = useState('')
  const [curClientAnticipatedHours, setCurClientAnticipatedHours] = useState('')
  const [curClientActualHours, setCurClientActualHours] = useState('')
  const [curClientAnticipatedProfit, setCurClientAnticipatedProfit] =
    useState('')
  const [curAdditionalHours, setCurAdditionalHours] = useState(0)
  const [curClientActualProfit, setCurClientActualProfit] = useState('')
  const [curClientNextYearFee, setCurClientNextYearFee] = useState('')
  const isMounted = useRef(true)

  const refetchMyActiveClients = useCallback(async () => {
    if (userId === null || year === null) {
      return
    }

    setLoading(true)
    getMyActiveClients(userId, year)
      .then((activeClients) => {
        if (isMounted.current) {
          setClients(activeClients.data.getMyActiveClients)
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        if (isMounted.current) {
          setLoading(false)
        }
      })
  }, [getMyActiveClients, userId, year])

  useEffect(() => {
    isMounted.current = true
    refetchMyActiveClients()
    return () => {
      isMounted.current = false
    }
  }, [refetchMyActiveClients])

  const handleFinancialModalClose = () => {
    setSatisfactionModalShow(true)
  }

  const handleViewResultsModalClose = () => {
    setViewResultsModalShow(true)
  }

  useEffect(() => {
    if (
      teamRunExecuteRefetchActiveClients.enabled &&
      teamRunExecuteRefetchActiveClients.message.teamId === userId
    ) {
      setLoading(true)
      getMyActiveClients(userId, year)
        .then((activeClients) => {
          if (isMounted.current) {
            setClients(activeClients.data.getMyActiveClients)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          if (isMounted.current) {
            setLoading(false)
          }
        })
      dispatchNC({
        type: 'team-run-execute-refetch-active-clients-executed',
      })
    }
  }, [
    teamRunExecuteRefetchActiveClients.enabled,
    teamRunExecuteRefetchActiveClients.message.teamId,
    dispatchNC,
    getMyActiveClients,
    userId,
    year,
  ])

  return (
    <>
      {
        // Modal for view results
      }
      <BlsModal
        show={viewResultsModalShow}
        setShow={setViewResultsModalShow}
        title={curClientDisplayName}
        onClose={handleViewResultsModalClose}
        headerTextColor="text-success"
      >
        {modalLoading ? (
          <Spinner
            as="div"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <>
            <FinancialResult
              body={financialModalBody}
              curClientAnticipatedHours={curClientAnticipatedHours}
              curClientActualHours={curClientActualHours}
              curClientAnticipatedProfit={curClientAnticipatedProfit}
              curClientActualProfit={curClientActualProfit}
              showWhy={false}
            />
            <SatisfactionResult
              body={satisfactionModalBody}
              curAdditionalHours={curAdditionalHours}
              curClientNextYearFee={curClientNextYearFee}
              marginTop="mt-5"
              showWhy={false}
            />
          </>
        )}
      </BlsModal>
      {
        // Modal for financial result
      }
      <BlsModal
        show={financialModalShow}
        setShow={setFinancialModalShow}
        title={curClientDisplayName}
        onClose={handleFinancialModalClose}
        headerTextColor="text-success"
      >
        <FinancialResult
          body={financialModalBody}
          curClientAnticipatedHours={curClientAnticipatedHours}
          curClientActualHours={curClientActualHours}
          curClientAnticipatedProfit={curClientAnticipatedProfit}
          curClientActualProfit={curClientActualProfit}
        />
      </BlsModal>
      {
        // Modal for client satisfaction
      }
      <BlsModal
        show={satisfactionModalShow}
        setShow={setSatisfactionModalShow}
        title={curClientDisplayName}
        onClose={refetchMyActiveClients}
        headerTextColor="text-success"
      >
        <SatisfactionResult
          body={satisfactionModalBody}
          curAdditionalHours={curAdditionalHours}
          curClientNextYearFee={curClientNextYearFee}
        />
      </BlsModal>
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className="text-primary h5 fw-bold">Time to Work!</h2>
            {year <= 3 && (
              <p>
                Great job making bids on high-profile business. Hopefully you
                won at least one of the high-profile engagements your firm bid
                on. But if you didn’t, don’t panic. There’s more work to come!
                If you did, it’s time to execute those engagements now.
              </p>
            )}
            <p>
              Click <strong>Execute</strong> on all high-profile engagements
              that appear in the table. This will simulate each engagement to
              completion, using your firm’s operating parameters.
            </p>
          </Col>
          <Col>
            <Stack direction="horizontal" className="align-items-start" gap={4}>
              <div className="d-none d-lg-flex" style={{ height: 500 }}>
                <div className="vr"></div>
              </div>
              <div className="flex-grow-1">
                <h2 className="text-success h3 fw-bold">
                  Execute High-Profile Engagements
                </h2>
                <Table responsive className="w-100 mt-4 align-middle run-table">
                  <thead className="bg-gray-100">
                    <tr>
                      <th width="50%">Client Name</th>
                      <th>Profit</th>
                      <th width={150}>Status</th>
                      <th width={18}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      // Empty row with a little bit of paddings to fill in the gap
                    }
                    <tr>
                      <td className="py-1"></td>
                    </tr>
                    {loading ? (
                      <tr>
                        <td>
                          <Spinner
                            as="div"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                          />
                        </td>
                        <td>
                          <Spinner
                            as="div"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                          />
                        </td>
                        <td>
                          <Spinner
                            as="div"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="secondary"
                          />
                        </td>
                      </tr>
                    ) : (
                      clients.map((client) => {
                        return (
                          <Client
                            key={client.target.displayName}
                            data={client}
                            actualProfit={curClientActualProfit}
                            setCurClientAnticipatedHours={
                              setCurClientAnticipatedHours
                            }
                            setCurClientActualHours={setCurClientActualHours}
                            setCurClientAnticipatedProfit={
                              setCurClientAnticipatedProfit
                            }
                            setCurClientActualProfit={setCurClientActualProfit}
                            setCurClientNextYearFee={setCurClientNextYearFee}
                            setCurAdditionalHours={setCurAdditionalHours}
                            setFinancialModalShow={setFinancialModalShow}
                            setFinancialModalBody={setFinancialModalBody}
                            setSatisfactionModalBody={setSatisfactionModalBody}
                            setViewResultsModalShow={setViewResultsModalShow}
                            setCurClientDisplayName={setCurClientDisplayName}
                            refetchMyActiveClients={refetchMyActiveClients}
                            setModalLoading={setModalLoading}
                          />
                        )
                      })
                    )}
                  </tbody>
                </Table>
              </div>
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const Client = ({
  data,
  setCurClientAnticipatedHours,
  setCurClientActualHours,
  setCurClientAnticipatedProfit,
  setCurClientActualProfit,
  setCurClientNextYearFee,
  setCurAdditionalHours,
  setFinancialModalShow,
  setFinancialModalBody,
  setSatisfactionModalBody,
  setViewResultsModalShow,
  setCurClientDisplayName,
  refetchMyActiveClients,
  setModalLoading,
}) => {
  const [executing, setExecuting] = useState(false)
  const [executed, setExecuted] = useState(false)
  const [actualProfit, setActualProfit] = useState(null)
  const {
    state: {
      me,
      year,
      ready,
      design,
      offering: { offeringId },
      rtNotification,
    },
  } = useAppState()
  const { updateClient, getMyActiveClients } = useClient()
  const { userId } = useAuth()
  const { addHighProfileIncome } = useIncome()
  const { sendRTNotification } = useNotificationChannel()

  const handleViewResult = async (clientId) => {
    try {
      setModalLoading(true)
      const myActiveClientData = await getMyActiveClients(
        userId,
        year,
        clientId
      )
      setModalLoading(false)
      const _data = myActiveClientData.data.getMyActiveClients[0]
      setCurClientDisplayName(_data.target.displayName)
      setCurClientAnticipatedHours(_data.oldAnticipatedHours)
      setCurClientActualHours(_data.actualHours)
      setCurClientAnticipatedProfit(_data.anticipatedProfit)
      setCurClientActualProfit(_data.actualProfit)
      setFinancialModalBody(getFinancialText(_data.didBlowBudget))
      setCurClientNextYearFee(_data.agreedFee)
      const newLob = _data.lob3
        ? _data.lob3
        : _data.lob2
        ? _data.lob2
        : _data.lob1

      const satisfactionText = getSatisfactionText(
        _data.satisfaction,
        _data.target.engageType,
        _data.drop,
        _data.awardNewLOB,
        newLob
      )
      setSatisfactionModalBody(satisfactionText)
    } catch (err) {
      console.log('Unable to view result', err)
    }
  }

  const handleClick = async () => {
    const {
      lob1,
      lob2,
      lob3,
      anticipatedHours,
      target: {
        id: targetId,
        displayName,
        targetHours,
        delighted,
        engageType,
        lob2: targetLob2,
        lob3: targetLob3,
      },
      agreedFee,
    } = data
    setExecuting(true)
    let newLob = ''
    const lobCount = [lob1, lob2, lob3].filter((lob) => lob).length

    // Did the firm blow the budget?
    const didBlowBudget = didTeamBlowBudget(lob1, lob2, lob3, design.philo)

    // Calculate anticipated hours
    let _anticipatedHours =
      anticipatedHours !== null
        ? anticipatedHours
        : calcAnticipatedHours(design, targetHours)
    const oldAnticipatedHours = _anticipatedHours

    // Calculate actual hours
    const actualHours = calcActualHours(
      didBlowBudget,
      _anticipatedHours,
      lobCount
    )

    // Calculate cost per engagement hours
    const costPerEngagementHour = calcCostPerEngagementHour(design)

    // Calculate anticipated profit
    const anticipatedProfit = calcEngagementProfit(
      _anticipatedHours,
      costPerEngagementHour,
      agreedFee
    )

    // Calculate profit on the engagement
    const actualProfit = calcEngagementProfit(
      actualHours,
      costPerEngagementHour,
      agreedFee
    )

    // Determine client satisfaction
    const clientSatisfaction = getClientSatisfaction(design, delighted)

    // Determine if the client drops the firm
    const dropFirm = doesDropFirm(
      clientSatisfaction,
      design.pracDevBal,
      engageType
    )

    // Determine if the client awards a new LOB
    let awardNewLOB = doesAddLOB(
      dropFirm,
      clientSatisfaction,
      design.pracDevBal,
      lobCount,
      engageType
    )

    // Get the new LOB if awarded
    newLob = getNewLOB(awardNewLOB, lob2, lob3, targetLob2, targetLob3)

    // Determine if awarding new LOB is valid
    if (awardNewLOB) {
      if (
        (newLob === 'Aud' && design.lobMix.asMix > 0) ||
        (newLob === 'Tax' && design.lobMix.tsMix > 0) ||
        (newLob === 'Con' && design.lobMix.csMix > 0)
      ) {
        // Award additional hours if the firm engages in the next line of
        // business
        _anticipatedHours = _anticipatedHours + targetHours
      } else {
        // If the next line of business is one that the firm does not engage in
        // (e.g., if a firm does no audit work), then expansion of relationship
        // automatically fails
        awardNewLOB = false
        newLob = ''
      }
    }

    // Show the financial result
    setCurClientAnticipatedHours(oldAnticipatedHours)
    setCurClientActualHours(actualHours)
    setCurClientAnticipatedProfit(anticipatedProfit)
    setCurClientActualProfit(actualProfit)
    setCurClientDisplayName(displayName)
    setCurAdditionalHours(_anticipatedHours - oldAnticipatedHours)

    setFinancialModalBody(getFinancialText(didBlowBudget))

    // Client satisfaction text
    const satisfactionText = getSatisfactionText(
      clientSatisfaction,
      engageType,
      dropFirm,
      awardNewLOB,
      newLob
    )

    setSatisfactionModalBody(satisfactionText)

    // Next year fee
    const nextYearFee = calcNextYearFee(
      dropFirm,
      awardNewLOB,
      clientSatisfaction,
      engageType,
      agreedFee,
      targetHours,
      costPerEngagementHour
    )
    setCurClientNextYearFee(nextYearFee)

    // console.log(
    //   `EXECUTE :: agreedFee (${agreedFee}) :: targetHours (${targetHours}) :: anticipatedHours (${_anticipatedHours}) :: oldAnticipatedHours (${oldAnticipatedHours}) :: delighted (${delighted}) :: engageType (${engageType}) :: targetlob2 (${targetLob2}) :: targetlob3 (${targetLob3}) :: awardNewLOB (${awardNewLOB}) :: newLob (${newLob})`
    // )

    // Update to the client
    try {
      let updateClientInput = {
        teamId: userId,
        targetId,
        oldAnticipatedHours,
        anticipatedHours: _anticipatedHours,
        actualHours,
        anticipatedProfit,
        actualProfit,
        agreedFee: nextYearFee,
        satisfaction: clientSatisfaction,
        drop: dropFirm,
        awardNewLOB,
        didBlowBudget,
        lobCount: awardNewLOB && lobCount < 3 ? lobCount + 1 : lobCount,
        year: year + 1,
      }

      // Add new LOB if client has awarded a new LOB
      if (awardNewLOB) {
        if (lob2 === null) {
          updateClientInput = { ...updateClientInput, lob2: newLob }
        } else if (lob3 === null) {
          updateClientInput = { ...updateClientInput, lob3: newLob }
        }
      }

      const updateClientPromise = updateClient({
        input: updateClientInput,
      })
      // And add income for this year
      const addIncomePromise = addHighProfileIncome(
        offeringId,
        userId,
        targetId,
        year,
        actualProfit,
        agreedFee
      )

      await Promise.all([updateClientPromise, addIncomePromise])
    } catch (err) {
      console.log('updateBid() failed', err)
    }

    setExecuting(false)

    // Send real-time notification
    const statusInput = {
      to: rtNotification.channel,
      message: JSON.stringify({ teamId: userId }),
      updater: me,
      type: NOTIF_CH_RUN_EXECUTE,
    }
    sendRTNotification(statusInput)

    // Show the financial modal
    setFinancialModalShow(true)

    // Save the local state for display purpose
    setActualProfit(actualProfit)

    setExecuted(true)
  }

  return (
    <tr>
      <td>
        {data.target.displayName}{' '}
        {data.drop && (
          <OverlayTrigger
            overlay={
              <Tooltip id={`tooltip-ended`}>
                This client has chosen not to expand their relationship with
                your firm.
              </Tooltip>
            }
          >
            <Badge pill bg="light" text="danger">
              ENDED
            </Badge>
          </OverlayTrigger>
        )}
      </td>
      <td
        className={clsx(
          (actualProfit !== null && actualProfit < 0) ||
            (actualProfit === null &&
              data.income.incomeYear !== null &&
              data.actualProfit !== null &&
              data.actualProfit < 0)
            ? 'text-danger'
            : 'gray-500'
        )}
      >
        {(data.drop || data.income.incomeYear !== null) && data.actualProfit
          ? formatCurrency(data.actualProfit)
          : actualProfit !== null
          ? formatCurrency(actualProfit)
          : '--'}
      </td>
      <td>
        {executed ||
        actualProfit !== null ||
        data.drop ||
        (data.income.incomeYear !== null && data.actualProfit !== null) ? (
          <Button
            variant="outline-primary rounded-pill px-3 w-100"
            size="sm"
            onClick={() => {
              setViewResultsModalShow(true)
              setCurClientDisplayName(data.target.displayName)
              handleViewResult(data.id)
            }}
          >
            View Results
          </Button>
        ) : (
          <Button
            variant="outline-success"
            className="rounded-pill px-3 w-100"
            size="sm"
            disabled={executing || ready}
            onClick={handleClick}
          >
            {executing && (
              <Spinner
                as="div"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="success"
              />
            )}{' '}
            Execute
          </Button>
        )}
      </td>
      <td width={20} className="px-0">
        {(executed ||
          actualProfit !== null ||
          (data.actualProfit !== null && data.income.incomeYear !== null)) && (
          <img
            src={greenCheckmarkIcon}
            alt="checkmark"
            width={18}
            height={13.2}
          />
        )}
      </td>
    </tr>
  )
}

const getLOBFullName = (lob) => {
  if (lob === 'Aud') {
    return 'assurance'
  } else if (lob === 'Tax') {
    return 'tax'
  } else if (lob === 'Con') {
    return 'consulting'
  }
}

const FinancialResult = ({
  body,
  curClientAnticipatedHours,
  curClientActualHours,
  curClientAnticipatedProfit,
  curClientActualProfit,
  marginTop = '',
  showWhy = true,
}) => {
  return (
    <>
      <h1 className={clsx('h5 text-primary fw-bold', marginTop)}>
        Financial Results
      </h1>
      <p>{body}</p>
      <div className="execute-finanicial-results-wrapper">
        <Form noValidate>
          <Stack gap={2}>
            <InputText
              as="div"
              className="d-flex justify-content-between"
              label="Anticipated hours"
              labelClassName="mb-0"
              disabled
              value={curClientAnticipatedHours}
            />
            <InputText
              as="div"
              className="d-flex justify-content-between"
              label="Actual hours"
              labelClassName="mb-0"
              disabled
              value={curClientActualHours}
            />
            <InputText
              as="div"
              className="d-flex justify-content-between"
              label="Anticipated profit"
              labelClassName="mb-0"
              inputColor={clsx(
                !isNaN(parseInt(curClientAnticipatedProfit)) &&
                  parseInt(curClientAnticipatedProfit) < 0
                  ? 'text-danger'
                  : 'gray-500'
              )}
              disabled
              value={formatCurrency(curClientAnticipatedProfit)}
            />
            <InputText
              as="div"
              className="d-flex justify-content-between"
              label="Actual profit"
              labelClassName="mb-0 fw-bold"
              inputColor={clsx(
                !isNaN(parseInt(curClientActualProfit)) &&
                  parseInt(curClientActualProfit) < 0
                  ? 'text-danger'
                  : 'gray-500'
              )}
              disabled
              value={formatCurrency(curClientActualProfit)}
            />
          </Stack>
        </Form>
      </div>
      {showWhy && (
        <>
          <hr />
          <div className="run-execute-why-wrapper">
            <p className="fw-bold">Why?</p>
            <p>
              Whether or not your firm is able to complete an engagement on time
              and within budget is tied to the philosophy operating parameter.
              More aggressive firms take on more risk—the more aggressive, the
              greater the risk that the budget will be blown. Being more
              conservative lessens the risk, by assigning work to more
              experienced staff and prescribing more procedures and
              documentation.
            </p>
            <p className="mb-0">
              The line of business also matters, with assurance engagements
              tending more toward cost overruns than tax or consulting. This is
              due to the “you don’t know what you’ll find until you get there”
              nature of the work, which makes planning difficult.
            </p>
          </div>
        </>
      )}
    </>
  )
}

const SatisfactionResult = ({
  body,
  curAdditionalHours,
  curClientNextYearFee,
  marginTop = '',
  showWhy = true,
}) => {
  return (
    <>
      <h1 className={clsx('h5 text-primary fw-bold', marginTop)}>
        Client Satisfaction
      </h1>
      <div dangerouslySetInnerHTML={{ __html: body }} />
      <div className="execute-finanicial-results-wrapper">
        <Form noValidate>
          <Stack gap={2}>
            {curAdditionalHours > 0 && (
              <InputText
                as="div"
                className="d-flex justify-content-between"
                label="Additional hours"
                labelClassName="mb-0"
                disabled
                value={curAdditionalHours}
              />
            )}
            <InputText
              as="div"
              className="d-flex justify-content-between"
              label="Next year's fees"
              labelClassName="mb-0"
              disabled
              value={formatCurrency(curClientNextYearFee)}
            />
          </Stack>
        </Form>
      </div>
      {showWhy && (
        <>
          <hr />
          <div className="run-execute-why-wrapper">
            <p className="fw-bold">Why?</p>
            <p>
              In Bottom Line, client satisfaction falls into one of three
              buckets: delighted, satisfied, or dissatisfied. An average firm
              delights about 25% of its customers, leaves about 25%
              dissatisfied, and satisfies the rest. In the real world, the
              satisfaction level of a client is not totally under the firm's
              control; even the best firm can sometimes fail to satisfy a
              client. All a firm can do is try to increase the odds of success.
            </p>
            <p>
              Most of the operating parameters affect the odds of satisfying
              clients one way or another. The biggest drivers are:
              infrastructure, employee development, compensation and practice
              development balance.
            </p>
            <p className="mb-0">
              Customer satisfaction also depends on the client. Every client
              comes with base satisfaction odds, and you can identify which
              clients will likely be the hardest to satisfy by looking closely
              at the business intelligence details on the Explore Visionville
              page.
            </p>
          </div>
        </>
      )}
    </>
  )
}

const getSatisfactionText = (
  clientSatisfaction,
  engageType,
  dropFirm,
  awardNewLOB,
  newLOB
) => {
  let text = ''

  if (clientSatisfaction === SATISFACTION_DELIGHTED) {
    text = '<p>Your client is delighted!</p>'
  } else if (clientSatisfaction === SATISFACTION_SATISFIED) {
    text = '<p>Your client is satisfied.</p>'
  } else if (clientSatisfaction === SATISFACTION_DISSATISFIED) {
    text = '<p>Your client is dissatisfied.</p>'
  }

  if (engageType === 'Annual' && dropFirm) {
    text = text.concat(
      '<p>Unfortunately, your client has elected not to proceed with your firm next year. The client will be removed from your list of High-Profile Engagements next year.</p>'
    )
  } else {
    if (awardNewLOB) {
      text = text.concat(
        `<p>In fact, your client is so impressed with your work, they've decided to award your firm with their ${getLOBFullName(
          newLOB
        )} business for the upcoming year, along with a significant fee increase.</p>`
      )
    } else {
      if (engageType === 'Single Year') {
        text = text.concat(
          '<p>Unfortunately, this one-time engagement has ended. The client will be removed from your list of High-Profile Engagements next year.</p>'
        )
      } else if (
        clientSatisfaction === SATISFACTION_DELIGHTED &&
        engageType === 'Annual'
      ) {
        text = text.concat(
          `<p>They have agreed to a 5% fee increase for the upcoming year.</p>`
        )
      } else if (
        clientSatisfaction === SATISFACTION_SATISFIED &&
        engageType === 'Annual'
      ) {
        text = text.concat(
          `<p>They have agreed to a 3% fee increase for the upcoming year.</p>`
        )
      } else if (
        clientSatisfaction === SATISFACTION_DISSATISFIED &&
        !dropFirm
      ) {
        text = text.concat(
          `<p>However, the client will continue to use your firm next year for the same fees.</p>`
        )
      }
    }
  }

  return text
}

const getFinancialText = (didBlowBudget) => {
  if (didBlowBudget) {
    return 'Your engagement team blew the budget.'
  } else {
    return 'Your engagement team completed the work on time and on budget.'
  }
}
