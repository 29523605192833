import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getHighProfileEngagements as getHighProfileEngagementsQuery,
  getLeader as getleaderQuery,
  getTeamsWonEngageByOfferingId as getTeamsWonEngageByOfferingIdMutation,
} from '../graphql/queries'

export const useLeader = () => {
  const getHighProfileEngagements = useCallback(async (offeringId, bidYear) => {
    return API.graphql(
      graphqlOperation(getHighProfileEngagementsQuery, {
        offeringId,
        bidYear,
      })
    )
  }, [])

  const fetchLeader = useCallback(async (id) => {
    return API.graphql(
      graphqlOperation(getleaderQuery, {
        id,
      })
    )
  }, [])

  const getTeamsWonEngageByOfferingId = useCallback(async (offeringId) => {
    return API.graphql(
      graphqlOperation(getTeamsWonEngageByOfferingIdMutation, {
        offeringId,
      })
    )
  }, [])

  return {
    getHighProfileEngagements,
    fetchLeader,
    getTeamsWonEngageByOfferingId,
  }
}
