import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import allQuestions from './data.json'
import './styles.css'

export default function Help() {
  return (
    <Container as="main" className="pb-5 main help-faq">
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          <h1 className="text-center text-primary fw-bold">Help/FAQs</h1>
          <div className="shadow-plain px-3 py-2 mt-4 mb-5 rounded">
            Have a question? Check out our answers to common questions below.
            Can’t find what you’re looking for? Ask your Coach.
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={{ span: 10, offset: 1 }} lg={{ span: 6, offset: 3 }}>
          {allQuestions.map((qdata, i) => {
            return (
              <div key={qdata.gropuID}>
                <h4 className="text-center text-success fw-bold mb-2">
                  {qdata.title}
                </h4>
                <Accordion className="mb-5">
                  {qdata.questions.map((subqdata, j) => {
                    return (
                      <Accordion.Item eventKey={subqdata.id} key={subqdata.id}>
                        <Accordion.Header>{subqdata.title}</Accordion.Header>
                        <Accordion.Body>
                          <div
                            dangerouslySetInnerHTML={{ __html: subqdata.text }}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
                </Accordion>
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}
