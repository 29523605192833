import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import {
  getHighProfileIncomes as getHighProfileIncomesQuery,
  getOtherIncomes as getOtherIncomesQuery,
} from '../graphql/queries'
import {
  addHighProfileIncome as addHighProfileIncomeMutation,
  addOtherIncome as addOtherIncomeMutation,
} from '../graphql/mutations'

export const useIncome = () => {
  const addHighProfileIncome = useCallback(
    async (
      offeringId,
      teamId,
      targetId,
      incomeYear,
      profit = null,
      agreedFee
    ) => {
      return API.graphql(
        graphqlOperation(addHighProfileIncomeMutation, {
          input: {
            offeringId,
            teamId,
            targetId,
            incomeType: 'HIGH',
            incomeYear,
            profit,
            agreedFee,
          },
        })
      )
    },
    []
  )

  const addOtherIncome = useCallback(
    async (
      offeringId,
      teamId,
      incomeYear,
      numAsClients = null,
      numTsClients = null,
      numCsClients = null,
      asProfit = null,
      tsProfit = null,
      csProfit = null,
      profit
    ) => {
      return API.graphql(
        graphqlOperation(addOtherIncomeMutation, {
          input: {
            offeringId,
            teamId,
            incomeType: 'OTHER',
            incomeYear,
            numAsClients,
            numTsClients,
            numCsClients,
            asProfit,
            tsProfit,
            csProfit,
            profit,
          },
        })
      )
    },
    []
  )

  const getHighProfileIncomesByTeamId = useCallback(async (userId) => {
    return API.graphql(
      graphqlOperation(getHighProfileIncomesQuery, {
        teamId: userId,
        incomeType: 'HIGH',
      })
    )
  }, [])

  const getHighProfileIncomeByYear = useCallback(async (userId, incomeYear) => {
    return API.graphql(
      graphqlOperation(getHighProfileIncomesQuery, {
        teamId: userId,
        incomeType: 'HIGH',
        incomeYear,
      })
    )
  }, [])

  const getOtherIncomesByTeamId = useCallback(async (userId) => {
    return API.graphql(
      graphqlOperation(getOtherIncomesQuery, {
        teamId: userId,
        incomeType: 'OTHER',
      })
    )
  }, [])

  const getOtherIncomeByYear = useCallback(async (userId, incomeYear) => {
    return API.graphql(
      graphqlOperation(getOtherIncomesQuery, {
        teamId: userId,
        incomeType: 'OTHER',
        incomeYear,
      })
    )
  }, [])

  return {
    addHighProfileIncome,
    addOtherIncome,
    getHighProfileIncomeByYear,
    getOtherIncomeByYear,
    getHighProfileIncomesByTeamId,
    getOtherIncomesByTeamId,
  }
}
