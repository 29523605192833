import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Stack from 'react-bootstrap/Stack'
import Spinner from 'react-bootstrap/Spinner'
import { API, graphqlOperation } from 'aws-amplify'
import clsx from 'clsx'

import BlsModal from '../BlsModal'

import { addBid as addBidMutation } from '../../graphql/mutations'
import InputSelect from '../../components/InputSelect'
import InputText from '../../components/InputText'
import InputNumber from '../../components/InputNumber'
import { useAuth } from '../../hooks/useAuth'
import { useAppState } from '../../hooks/useAppState'
import { useTarget } from '../../hooks/useTarget'
import { useBids } from '../../hooks/useBids'
import { useNotification } from '../../hooks/useNotification'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'
import {
  calcAnticipatedHours,
  calcCostPerEngagementHour,
  calcBreakEvenPoint,
} from '../../utils/bls-utils'
import {
  NOTIF_CH_RUN_BID,
  BID_STATUS_PENDING,
  BID_STATUS_WON,
  BID_STATUS_LOST,
} from '../../utils/constants'

export default function RunBid() {
  const {
    state: {
      year,
      me,
      ready,
      design,
      run: { bids },
      offering: { offeringId },
      rtNotification,
    },
    dispatch,
  } = useAppState()
  const { userId } = useAuth()
  const { fetchTargets } = useTarget()
  const { getTeamBidsByYear } = useBids()
  const {
    state: { teamRunBidRefetchBids },
    sendRTNotification,
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const navigate = useNavigate()
  const [anticipatedHours, setAnticipatedHours] = useState('')
  const [costPerEngagementHour, setCostPerEngagementHour] = useState('')
  const [anticipatedBreakeven, setAnticipatedBreakeven] = useState('')
  const [bid, setBid] = useState('')
  const [bidInputIsInvalid, setBidInputIsInvalid] = useState(false)
  const [selectedTargetName, setSelectedTargetName] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalBody, setModalBody] = useState('')
  const { dispatch: notificationDispatch } = useNotification()
  const [isSubmittingBid, setIsSubmittingBid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [targets, setTargets] = useState(null)

  /**
   * Fetch targets and all bids submitted by this team in the current year
   */
  useEffect(() => {
    let isMounted = true
    if (userId === null || year === null) {
      return
    }
    if (offeringId === null || offeringId === -1) {
      return
    }

    setLoading(true)
    fetchTargets(offeringId)
      .then((targetsList) => {
        const _targets = targetsList.data.getTargets

        if (isMounted) {
          setTargets(_targets)
        }
      })
      .catch((err) => {
        console.log('err', err)
      })
    getTeamBidsByYear(userId, year)
      .then((allBids) => {
        const payload = allBids.data.getTeamBidsByYear.map((bid) => {
          return {
            agreedFee: bid.agreedFee,
            bidYear: bid.bidYear,
            offeringId: bid.offeringId,
            targetId: bid.target.id,
            teamId: userId,
            targetDisplayName: bid.target.displayName,
            status: bid.status,
            winningReason: bid.winningReason,
          }
        })
        dispatch({ type: 'set-bids', payload })
      })
      .finally(() => {
        if (isMounted) {
          setLoading(false)
        }
      })
    return () => {
      isMounted = false
    }
  }, [fetchTargets, offeringId, getTeamBidsByYear, userId, year, dispatch])

  /**
   * Refetch all bids data whenever a member submits a bid
   */
  useEffect(() => {
    if (teamRunBidRefetchBids.enabled) {
      getTeamBidsByYear(userId, year).then((allBids) => {
        const payload = allBids.data.getTeamBidsByYear.map((bid) => {
          return {
            agreedFee: bid.agreedFee,
            bidYear: bid.bidYear,
            offeringId: bid.offeringId,
            targetId: bid.target.id,
            teamId: userId,
            targetDisplayName: bid.target.displayName,
            status: bid.status,
            winningReason: bid.winningReason,
          }
        })
        // Save bids in local app state
        dispatch({ type: 'set-bids', payload })

        // Notify that this task has been executed
        dispatchNC({ type: 'team-run-bid-refetch-bids-executed' })
      })
    }
  }, [
    teamRunBidRefetchBids.enabled,
    getTeamBidsByYear,
    dispatch,
    userId,
    year,
    dispatchNC,
  ])

  /**
   * Redirect the user to the EXECUTE tab in one of the following situations:
   * - User accesses BID tab using direct URL
   * - User is on the BID tab when the other team member advances to either
   *   Year 4 or 5
   */
  useEffect(() => {
    if (year !== null && year > 3) {
      // After year 3, BID tab is inactive, redirect the user to EXECUTE
      // tab instead.
      // Save tab name to the app state
      dispatch({ type: 'set-run-tab', payload: 'execute' })
      navigate('/run?tab=execute', { replace: true })
    }
  }, [year, dispatch, navigate])

  const getCurrentTarget = (targets) => {
    return targets ? targets.filter((target) => target.engageYear === year) : []
  }

  const handleTargetClientChange = (e, currentTargets) => {
    const target = currentTargets.filter(
      (target) => target.displayName === e.target.value
    )

    if (target.length > 0) {
      const _anticipatedHours = calcAnticipatedHours(
        design,
        target[0].targetHours
      )
      const _costPerEngagementHour = calcCostPerEngagementHour(design)
      const _anticipatedBreakeven = calcBreakEvenPoint(
        _anticipatedHours,
        _costPerEngagementHour
      )
      setAnticipatedHours(_anticipatedHours.toString())
      setCostPerEngagementHour(_costPerEngagementHour.toString())
      setAnticipatedBreakeven(_anticipatedBreakeven.toString())
      setSelectedTargetName(target[0].displayName)
      setBid('')
      setBidInputIsInvalid(false)
    } else {
      // Reset selected target
      setSelectedTargetName('')
      // Reset bid input error
      setBidInputIsInvalid(false)
    }
  }

  const handleSubmitBid = async (type, value) => {
    // Make sure the value is not empty
    if (value === '') {
      setBidInputIsInvalid(true)
      return
    }

    // Make sure the bid is not already submitted
    if (
      bids.filter((bid) => bid.targetDisplayName === selectedTargetName)
        .length > 0
    ) {
      setModalTitle(`Bid exists!`)
      setModalBody(
        `You already have an active bid with <strong>${selectedTargetName}</strong>.`
      )
      setModalShow(true)
      return
    }

    setIsSubmittingBid(true)

    const agreedFee = parseInt(value.replace(/,/g, ''))
    const selectedTarget = getCurrentTarget(targets).find(
      (target) => target.displayName === selectedTargetName
    )
    const targetId = selectedTarget ? selectedTarget.id : null

    if (targetId === null) {
      console.log('Cannot place the bid :: Target id is null')
      return
    }
    if (userId === null) {
      console.log('Cannot place the bid :: User id is null')
      return
    }

    // Save bid to the database
    const addBidInput = {
      input: {
        agreedFee,
        bidYear: year,
        offeringId,
        targetId,
        teamId: userId,
      },
    }

    try {
      await API.graphql(graphqlOperation(addBidMutation, addBidInput))
    } catch (err) {
      const errorMessage = err?.errors[0]?.message ?? ''

      if (errorMessage.startsWith('Duplicate entry')) {
        // Another team member has already placed a bid on this target client
        setModalTitle(`Bid Exists!`)
        setModalBody(
          `You already have an active bid with <strong>${selectedTargetName}</strong>.`
        )
      } else if (errorMessage.startsWith('Awarded bid exists for target')) {
        // This target client has already been awarded
        setModalTitle(`Bid Failed!`)
        setModalBody(
          `This target client has already been awarded. Please bid on another target client!`
        )
      } else {
        // Other unknown errors
        setModalTitle(`Bid Failed!`)
        setModalBody(`Your bid didn't go through. Please try again!`)
      }

      setModalShow(true)
      setIsSubmittingBid(false)
      return
    }

    // Update the app state
    dispatch({
      type,
      payload: {
        agreedFee,
        bidYear: year,
        offeringId,
        targetId,
        teamId: userId,
        targetDisplayName: selectedTargetName,
        status: BID_STATUS_PENDING,
        winningReason: '',
      },
    })

    // Send live updated value
    const statusInput = {
      to: rtNotification.channel,
      message: JSON.stringify({
        teamId: userId,
        targetDisplayName: selectedTargetName,
      }),
      updater: me,
      type: NOTIF_CH_RUN_BID,
    }
    sendRTNotification(statusInput)

    setIsSubmittingBid(false)

    // Show a notification
    notificationDispatch({
      type: 'ADD_TOAST',
      payload: {
        title: `Bid Submitted`,
        body: `You've successfully submitted a bid for <strong>${selectedTargetName}</strong>.`,
        time: new Date().toLocaleTimeString(),
        type: 'success',
        op: { delay: 5000, autohide: true },
      },
    })
  }

  // Filter targets to show
  const currentTargets = getCurrentTarget(targets)

  // Don't render anything after Year 3
  if (year > 3) {
    return null
  }

  return (
    <>
      <BlsModal
        show={modalShow}
        setShow={setModalShow}
        title={modalTitle}
        body={modalBody}
      />
      <Container>
        <Row>
          <Col lg={4}>
            <h2 className="text-primary h5 fw-bold">Time to Bid!</h2>
            <p>
              Your first task is to bid on high-profile work. Select your target
              client from the drop-down list and then enter your bid below. You
              can bid on more than one target client!
            </p>
            <p>
              Note that the work that you win does not represent all of your
              business — these are the high-profile engagements only. Your firm
              also runs a number of smaller engagements, and you’ll see those
              details soon.
            </p>
            <p>
              When forming a bid, you may wish to revisit the business
              intelligence details on the <strong>Explore Visionville</strong>{' '}
              page. The more you know about a prospective client, the better!
            </p>
            <div className="text-center mt-5">
              <Button
                as={Link}
                to="/explore"
                variant="outline-primary rounded-pill"
              >
                Take me to Visionville!
              </Button>
            </div>
          </Col>
          <Col>
            <Stack direction="horizontal" className="align-items-start" gap={4}>
              <div className="d-none d-lg-flex" style={{ height: 440 }}>
                <div className="vr"></div>
              </div>
              <Form as={Row} className="gx-0" noValidate>
                <Col>
                  <h2 className="text-success h3 fw-bold mb-4 mt-5 mt-lg-0">
                    Bid Calculator
                  </h2>
                  <InputSelect
                    label="Select your target client"
                    options={currentTargets.map((target) => ({
                      key: target.displayName,
                      label: target.displayName,
                      value: target.displayName,
                    }))}
                    disabled={ready || year > 3 || isSubmittingBid}
                    onChange={(e) =>
                      handleTargetClientChange(e, currentTargets)
                    }
                  />
                  <InputText
                    id="anticipatedHours"
                    className="mb-3"
                    label='Anticipated hours to complete engagement <em class="text-gray-300 fs-7">(auto-populated)</em>'
                    labelClassName="col-sm-7 col-md-8 col-xl-9"
                    placeholder="--"
                    helpTitle="Anticipated Hours to Complete Engagement"
                    helpContent={`<p><strong>How was this number computed?</strong></p><p>Your operating parameters determine how efficient your firm is. Less efficient firms will have to bid higher to break even.</p><p>If you want to become more efficient, consider investing more in technology (infrastructure) or moving your philosophy parameter towards <strong><em>aggressive</em></strong>. Other parameters can also affect your efficiency.</p>`}
                    disabled
                    value={anticipatedHours}
                    setValue={setAnticipatedBreakeven}
                  />
                  <InputText
                    id="costPerEngagementHour"
                    className="mb-3"
                    label='Cost per engagement hour <em class="text-gray-300 fs-7">(auto-populated)</em>'
                    labelClassName="col-sm-7 col-md-8 col-xl-9"
                    placeholder="--"
                    helpTitle="Cost Per Engagement Hour"
                    helpContent={`<p><strong>How was this number computed?</strong></p><p>Your operating parameters determine your cost basis per hour. More expensive firms will have to bid higher to break even.</p><p>If you want to drive costs down, consider reducing your level of expertise and/or compensation. Almost all parameters affect your costs, but these two affect it the most.</p>`}
                    disabled
                    dollarSign
                    value={costPerEngagementHour}
                    setValue={setCostPerEngagementHour}
                  />
                  <InputText
                    id="anticipatedBreakeven"
                    className="mb-3"
                    labelClassName="col-sm-7 col-md-8 col-xl-9"
                    label='Anticipated breakeven point <em class="text-gray-300 fs-7">(auto-populated)</em>'
                    placeholder="--"
                    helpTitle="Anticipated Breakeven Point"
                    helpContent={`<p><strong>So...what should we bid?</strong></p><p>Consider bidding around the breakeven point if the client is particularly cost-sensitive and you have invested in areas that are likely to lead to good customer satisfaction. Satisfied customers are more likely to give you more business in future years, and at premium prices.</p><p>This can be a dangerous strategy if your firm is more aggressive, however, since the risks of cost overruns are higher. This can also be a riskier strategy on audit engagements, as they are harder to scope effectively.</p><p>If your firm is lean and aggressive, your breakeven point may well be lower than your competition’s. You may be able to get away with bidding higher than breakeven and still undercutting your competitors. Consider bidding 20% above breakeven.</p><p>If you believe you offer a client a pretty good fit for their needs, consider bidding 20 to 25% above your costs. You’re bringing value to the client, and you deserve a profit in return.</p><p>Or, if you believe that your firm is the perfect fit for a particular client, and that no other firm matches the client’s needs as well as yours, then consider building in a significant premium, such as 80% above your breakeven costs.</p>`}
                    disabled
                    dollarSign
                    value={anticipatedBreakeven}
                  />
                  <Row className="my-5 g-0">
                    <Col
                      md={{ span: 6, offset: 1 }}
                      lg={{ span: 6, offset: 1 }}
                      xl={{ span: 6, offset: 2 }}
                    >
                      <InputNumber
                        id="bid"
                        label="Your Bid:"
                        labelBreakpointClassName="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-3"
                        min={1}
                        max={9999999}
                        setMaxAttribute={false}
                        value={bid}
                        setValue={setBid}
                        isInvalid={bidInputIsInvalid}
                        setIsInvalid={setBidInputIsInvalid}
                        errorText="Please enter your bid"
                        disabled={selectedTargetName === ''}
                        dollarSign
                        formatNumber
                      />
                    </Col>
                    <Col
                      md={3}
                      lg={4}
                      xl={3}
                      className="mt-5 mt-md-0 text-center"
                    >
                      <Button
                        variant="outline-success"
                        className="rounded-pill"
                        disabled={selectedTargetName === '' || isSubmittingBid}
                        onClick={() => handleSubmitBid('add-bid', bid)}
                      >
                        {isSubmittingBid && (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="success"
                            className="me-2"
                          />
                        )}
                        Submit Bid!
                      </Button>
                    </Col>
                  </Row>
                  <hr />

                  {/** Active bid(s) status */}
                  {loading ? (
                    <div className="text-center">
                      <Spinner
                        as="div"
                        animation="border"
                        role="status"
                        aria-hidden="true"
                        variant="secondary"
                      />
                    </div>
                  ) : (
                    bids.map((bid) => (
                      <ActiveBidStatus
                        key={bid.targetDisplayName}
                        targetDisplayName={bid.targetDisplayName}
                        status={bid.status}
                        winningReason={bid.winningReason}
                      />
                    ))
                  )}
                </Col>
              </Form>
            </Stack>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const ActiveBidStatus = ({
  targetDisplayName = '',
  status = '',
  winningReason = '',
}) => {
  const statusText = getBidStatusText(status, winningReason)
  const statusBgColor = getBidStatusBgColor(status)
  const statusTextColor = getBidStatusTextColor(status)

  return (
    <div
      className={clsx(
        'rounded-2 px-3 py-3 mx-1 mt-4',
        statusBgColor,
        statusTextColor
      )}
    >
      <p>You have an active bid with {targetDisplayName}.</p>
      <p className="mb-0">Status: {statusText}</p>
    </div>
  )
}

/**
 * Get status detail status text
 *
 * @param {String} status - Bid status text
 * @param {String} status - Bid status text
 * @returns Detail status text
 */
const getBidStatusText = (status, winningReason) => {
  let statusText = ''

  switch (status) {
    case BID_STATUS_PENDING:
      statusText = 'Pending Client Decision'
      break
    case BID_STATUS_WON:
      statusText =
        `Congratulations! You won! Your firm stood out in the following areas: ${
          winningReason !== '' ? winningReason + '.' : ''
        }`.trim()
      break
    case BID_STATUS_LOST:
      statusText =
        `Unfortunately, you lost. The winning firm stood out in the following areas: ${
          winningReason !== '' ? winningReason + '.' : ''
        }`.trim()
      break
    default:
      statusText = ''
  }

  return statusText
}

/**
 * Get background color based on status
 *
 * @param {String} status - Bid status text
 * @returns Bootstrap background color css class
 */
const getBidStatusBgColor = (status) => {
  let bgColor = ''

  switch (status) {
    case BID_STATUS_WON:
      bgColor = 'bg-success'
      break
    case BID_STATUS_LOST:
      bgColor = 'bg-danger'
      break
    case BID_STATUS_PENDING:
    default:
      bgColor = 'bg-gray-100'
  }

  return bgColor
}

/**
 * Get text color based on status
 *
 * @param {String} status - Bid status text
 * @returns Bootstrap text color css class
 */
const getBidStatusTextColor = (status) => {
  let textColor = ''

  switch (status) {
    case BID_STATUS_WON:
    case BID_STATUS_LOST:
      textColor = 'text-white'
      break
    case BID_STATUS_PENDING:
    default:
      textColor = 'text-gray-500'
  }

  return textColor
}
