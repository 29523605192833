import Container from 'react-bootstrap/Container'

export default function Footer() {
  return (
    <footer className="bg-gray-200">
      <Container className="py-4">
        <span className="text-gray-800">
          &copy; {new Date().getFullYear()} RSM US, LLP. All rights reserved.
        </span>
      </Container>
    </footer>
  )
}
