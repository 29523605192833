import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getTeamsByOfferingId as getTeamsByOfferingIdQuery } from '../graphql/queries'

export const useTeamsByOfferingId = () => {
  const getTeamsByOfferingId = useCallback((offeringId) => {
    return API.graphql(
      graphqlOperation(getTeamsByOfferingIdQuery, { offeringId })
    )
  }, [])

  return { getTeamsByOfferingId }
}
