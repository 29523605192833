import clsx from 'clsx'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

import './style.css'

const ModalConfirm = ({
  title = '',
  body = '',
  show = false,
  headerTextColor = 'text-primary',
  handleClose,
  handleConfirm,
}) => {
  return (
    <Modal
      centered
      show={show}
      backdrop="static"
      keyboard={false}
      contentClassName="rounded-0 shadow-sm px-2 py-2"
    >
      {title !== '' && (
        <Modal.Header className="border-0 pb-0">
          <Modal.Title className={clsx('h5 fw-bold', headerTextColor)}>
            <div dangerouslySetInnerHTML={{ __html: title }} />
          </Modal.Title>
        </Modal.Header>
      )}
      {body !== '' && (
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Modal.Body>
      )}
      <Modal.Footer className="justify-content-center border-0">
        <Button
          variant="outline-secondary"
          className="rounded-pill px-4"
          onClick={handleClose}
        >
          No
        </Button>
        <Button
          variant="outline-success"
          className="rounded-pill px-4"
          onClick={handleConfirm}
        >
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ModalConfirm
