import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import './App.css'
import { Amplify } from '@aws-amplify/core'

// Components
import Layout from './components/Layout'

// Pages
import Home from './pages/Home'
import DesignFirm from './pages/DesignFirm'
import ExploreVisionville from './pages/ExploreVisionville'
import RunFirm from './pages/RunFirm'
import Leaderboards from './pages/Leaderboards'
import ViewTeams from './pages/ViewTeams'
import Help from './pages/Help'
import AwardBids from './pages/AwardBids'
import NotFoundPath from './pages/NotFoundPath'
import BottomLineStats from './pages/BottomLineStats'
import HostAccessCodes from './pages/HostAccessCodes'
import HostDashboard from './pages/HostDashboard'

import aws_exports from './aws-exports'
import { useAuth } from './hooks/useAuth'

Amplify.configure(aws_exports)

function RequireAuth({ children }) {
  const { user } = useAuth()
  let location = useLocation()

  if (user === null) {
    // Redirect them to the home page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

function App() {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Home />} />
        <Route
          path="/design"
          element={
            <RequireAuth>
              <DesignFirm />
            </RequireAuth>
          }
        />
        <Route
          path="/run"
          element={
            <RequireAuth>
              <RunFirm />
            </RequireAuth>
          }
        />
        <Route
          path="/explore"
          element={
            <RequireAuth>
              <ExploreVisionville />
            </RequireAuth>
          }
        />
        <Route
          path="/leaderboards"
          element={
            <RequireAuth>
              <Leaderboards />
            </RequireAuth>
          }
        />
        <Route
          path="/teams"
          element={
            <RequireAuth>
              <ViewTeams />
            </RequireAuth>
          }
        />
        <Route
          path="/help"
          element={
            <RequireAuth>
              <Help />
            </RequireAuth>
          }
        />
        {/** Host */}
        <Route
          path="/host/dashboard"
          element={
            <RequireAuth>
              <HostDashboard />
            </RequireAuth>
          }
        />
        {/** Leader */}
        <Route
          path="/leader/award"
          element={
            <RequireAuth>
              <AwardBids />
            </RequireAuth>
          }
        />
        {/** Program Owner (Admin) */}
        <Route
          path="/admin/stats"
          element={
            <RequireAuth>
              <BottomLineStats />
            </RequireAuth>
          }
        />
        <Route
          path="/admin/access-codes"
          element={
            <RequireAuth>
              <HostAccessCodes />
            </RequireAuth>
          }
        />
      </Route>
      {/** No match route */}
      <Route path="*" element={<NotFoundPath />} />
    </Routes>
  )
}

export default App
