import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header'
import Footer from '../Footer'
import ScrollToTop from '../ScrollToTop'
import { useNavigate, useLocation } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import ToastManager from '../../components/ToastManager'
import LoadingData from '../LoadingData'
import ChatFloat from '../ChatFloat'
import ModalProgress from '../ModalProgress'

import { useAuth } from '../../hooks/useAuth'
import { useHost } from '../../hooks/useHost'
import { useTeam } from '../../hooks/useTeam'
import { useLeader } from '../../hooks/useLeader'
import { useAppState } from '../../hooks/useAppState'
import { useNotification } from '../../hooks/useNotification'
import { useNotificationChannel } from '../../hooks/useNotificationChannel'

export default function Layout() {
  const [loading, setLoading] = useState(false)
  const [merging, setMerging] = useState(false)
  const [reloading, setReloading] = useState(false)
  const [mustReloadPage, setMustReloadPage] = useState(false)
  const {
    user,
    userId,
    isHost,
    isTeam,
    isLeader,
    getUserId,
    getAuthenticatedUser,
  } = useAuth()
  const { fetchHost } = useHost()
  const { fetchTeam } = useTeam()
  const { fetchLeader } = useLeader()
  const {
    state: {
      offering: { offeringId },
      year,
      error: { reloadRequired },
    },
    dispatch,
  } = useAppState()
  const {
    state: {
      hostAdvanceSimYear,
      hostMergeTeams,
      teamRunAdvance,
      teamRunAdvanceOobNotif,
      teamConfirmedDesign,
    },
    dispatch: dispatchNC,
  } = useNotificationChannel()
  const { dispatch: notificationDispatch } = useNotification()
  const navigate = useNavigate()
  const location = useLocation()
  const [mergeModalTitle, setMergeModalTitle] = useState('Merge In Progress!')
  const [largerTeamName, setLargerTeamName] = useState('')

  /**
   * Get authenticated user
   */
  useEffect(() => {
    getAuthenticatedUser()
  }, [getAuthenticatedUser])

  /**
   * Set notification channel
   */
  useEffect(() => {
    if (offeringId === null || offeringId < 0) {
      return
    }
    // Save real-time notification channel name
    const channel = `notification-room-${offeringId}`
    dispatch({ type: 'set-notification-channel', payload: channel })
  }, [offeringId, dispatch])

  /**
   * If the authenticated user is "Host" user, fetch their data and save
   * the associated offeringId to the app state.
   */
  useEffect(() => {
    if (user === false) {
      return
    }
    if (user && isHost) {
      // Fetch the host data
      const userId = getUserId(user)

      setLoading(true)
      fetchHost(userId)
        .then((host) => {
          if (host) {
            // Save the offering id
            const offeringId = host.data.getHost.offering.id
            dispatch({
              type: 'set-offering-id',
              payload: offeringId !== null ? offeringId : -1,
            })

            // Save the year
            dispatch({
              type: 'set-year',
              payload: !isNaN(parseInt(host.data.getHost.offering.year))
                ? host.data.getHost.offering.year
                : 1,
            })
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [user, isHost, getUserId, fetchHost, dispatch, navigate])

  /**
   * If the authenticated user is "Team" user, fetch their data and save to
   * the app state.
   */
  useEffect(() => {
    if (user === false) {
      return
    }
    if (user && isTeam) {
      // Fetch the team data
      const userId = getUserId(user)

      setLoading(true)
      fetchTeam(userId)
        .then((team) => {
          if (team) {
            // Save team data into the app state
            dispatch({ type: 'set-firm', payload: team })

            // Save the offering id
            dispatch({
              type: 'set-offering-id',
              payload: team.offeringId,
            })

            // Save the current year
            dispatch({
              type: 'set-year',
              payload: team.year,
            })

            // Save the ready state
            dispatch({
              type: 'set-ready',
              payload: team.ready,
            })

            // Save real-time notification channel name
            const channel = `notification-room-${team.offeringId}`
            dispatch({ type: 'set-notification-channel', payload: channel })

            // Save real-time chat channel name
            const chatChannel = `chat-room-${team.offeringId}`
            dispatch({ type: 'set-chat-channel', payload: chatChannel })
          }

          return team.year
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [user, isTeam, getUserId, fetchTeam, dispatch])

  /**
   * If the authenticated user is "Leader" user, fetch their data and save
   * the associated offeringId to the app state.
   */
  useEffect(() => {
    if (user === false) {
      return
    }
    if (user && isLeader) {
      // Fetch the leader data
      const userId = getUserId(user)

      setLoading(true)
      fetchLeader(userId).then((leader) => {
        if (leader) {
          const offeringId = leader.data.getLeader.offering.id
          const year = leader.data.getLeader.offering.year

          // Save the offering id
          dispatch({
            type: 'set-offering-id',
            payload: offeringId,
          })

          // Save the current year
          dispatch({
            type: 'set-year',
            payload: year,
          })

          // Save real-time notification channel name
          const channel = `notification-room-${offeringId}`
          dispatch({ type: 'set-notification-channel', payload: channel })
        }

        setLoading(false)
      })
    }
  }, [dispatch, fetchLeader, getUserId, isLeader, user])

  /**
   * Fetch the team info whenever we receive this notification when host
   * advances to next year
   */
  useEffect(() => {
    if (offeringId === -1 || offeringId === null) {
      return
    }

    if (hostAdvanceSimYear.enabled) {
      dispatch({ type: 'set-year', payload: hostAdvanceSimYear.message.year })
      dispatch({ type: 'set-ready', payload: false })
      dispatch({ type: 'set-yearConfirm', payload: false })
      dispatchNC({ type: 'host-advance-sim-year-executed' })

      // Show notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Simulation Year`,
          body: `Great news! You're now in simulation <strong>Year ${hostAdvanceSimYear.message.year}</strong>.`,
          time: new Date().toLocaleTimeString(),
          type: 'info',
          op: { delay: 10000, autohide: false },
        },
      })
    }
  }, [
    hostAdvanceSimYear.enabled,
    hostAdvanceSimYear.message,
    dispatch,
    dispatchNC,
    offeringId,
    notificationDispatch,
    year,
  ])

  /**
   * Show in-app notification to members of the same team whenever we receive
   * this notification that the team has confirmed their design
   * Don't show this if they're already on the Design page
   */
  useEffect(() => {
    if (
      teamConfirmedDesign.enabled &&
      teamConfirmedDesign.message &&
      teamConfirmedDesign.message.teamId === userId
    ) {
      if (offeringId === null || offeringId === -1) {
        return
      }

      if (location.pathname === '/design') {
        return
      }

      // Show notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Design Your Firm`,
          body: `Tweaks have been made to your firm’s design!`,
          time: new Date().toLocaleTimeString(),
          type: 'info',
          op: { delay: 10000, autohide: true },
        },
      })

      // We don't need to show the popup for this when this member returns
      // to the Design page
      dispatchNC({ type: 'team-confirmed-design-executed' })
    }
  }, [
    dispatchNC,
    notificationDispatch,
    offeringId,
    teamConfirmedDesign,
    location,
    userId,
  ])

  /**
   * Show merge modal when received a merge in progress notification
   */
  useEffect(() => {
    if (hostMergeTeams.enabled) {
      if (
        hostMergeTeams.message.started &&
        [
          parseInt(hostMergeTeams.message.teamOne),
          parseInt(hostMergeTeams.message.teamTwo),
        ].includes(userId)
      ) {
        dispatchNC({ type: 'host-merge-teams-executed-final' })
        setMerging(true)
      }
    }
  }, [hostMergeTeams.enabled, hostMergeTeams.message, userId, dispatchNC])

  /**
   * Hide merge modal when received a merge completed notification
   */
  useEffect(() => {
    if (hostMergeTeams.enabled) {
      if (
        hostMergeTeams.message.completed &&
        [
          parseInt(hostMergeTeams.message.largerTeamId),
          parseInt(hostMergeTeams.message.smallerTeamId),
        ].includes(userId)
      ) {
        dispatchNC({
          type: 'host-merge-teams-executed-final',
          payload: { enabled: false, message: {}, in: [] },
        })
        setLargerTeamName(hostMergeTeams.message.largerTeamName)
        setMergeModalTitle('Merge Complete!')
        setMerging(false)
        setReloading(true)
      }
    }
  }, [hostMergeTeams.enabled, hostMergeTeams.message, userId, dispatchNC])

  /**
   * Show an in-app notification to teams other than the merging team members
   */
  useEffect(() => {
    if (userId === null || userId === -1 || !isTeam) {
      return
    }

    if (hostMergeTeams.enabled) {
      if (
        hostMergeTeams.message.completed &&
        hostMergeTeams.message &&
        ![
          parseInt(hostMergeTeams.message.largerTeamId),
          parseInt(hostMergeTeams.message.smallerTeamId),
        ].includes(userId) &&
        hostMergeTeams.in &&
        !hostMergeTeams.in.includes('Layout')
      ) {
        dispatchNC({
          type: 'host-merge-teams-executed',
          payload: {
            in: 'Layout',
          },
        })

        notificationDispatch({
          type: 'ADD_TOAST',
          payload: {
            title: `Merge Firms`,
            body: `${hostMergeTeams.message.largerTeamName} and ${hostMergeTeams.message.smallerTeamName} have merged. They are now ${hostMergeTeams.message.largerTeamName}.`,
            time: new Date().toLocaleTimeString(),
            type: 'info',
            op: { delay: 10000, autohide: true },
          },
        })
      }
    }
  }, [dispatchNC, hostMergeTeams, notificationDispatch, userId, isTeam])

  /**
   * Show in-app notification whenever we receive this notification when a
   * member of the same team has advanced to the next year
   */
  useEffect(() => {
    if (userId === null || userId === -1) {
      return
    }

    if (
      teamRunAdvance.enabled &&
      teamRunAdvance.message &&
      teamRunAdvance.message.teamId === userId
    ) {
      const newYear = teamRunAdvance.message?.newYear ?? ''
      const tabText = newYear < 4 ? 'BID' : 'EXECUTE'

      // Show notification
      notificationDispatch({
        type: 'ADD_TOAST',
        payload: {
          title: `Advance to Year ${newYear}`,
          body: `Woohoo! You’re now approaching Year ${newYear}. Return to the ${tabText} tab on the <strong>Run Your Firm</strong> page.`,
          time: new Date().toLocaleTimeString(),
          type: 'info',
          op: { delay: 20000, autohide: true },
        },
      })

      // Fetch the team data
      dispatchNC({
        type: 'team-run-advance-executed',
      })

      fetchTeam(userId).then((team) => {
        if (team) {
          // Save team data into the app state
          dispatch({ type: 'set-firm', payload: team })

          // Save the current year
          dispatch({
            type: 'set-year',
            payload: team.year,
          })

          // Save the ready state
          dispatch({
            type: 'set-ready',
            payload: team.ready,
          })
        }
      })
    }
  }, [
    teamRunAdvance,
    notificationDispatch,
    dispatchNC,
    userId,
    dispatch,
    fetchTeam,
  ])

  /**
   * Show in-app notification whenever we receive this notification when a
   * client has gone out of business
   */
  useEffect(() => {
    if (userId === null) {
      return
    }

    if (teamRunAdvanceOobNotif.enabled) {
      if (teamRunAdvanceOobNotif.message) {
        try {
          const { teamId, oobClients } = teamRunAdvanceOobNotif.message

          // Only members of the same team should show this notification
          if (teamId !== userId) {
            return
          }

          // Show notification
          oobClients.forEach((oobClient) => {
            notificationDispatch({
              type: 'ADD_TOAST',
              payload: {
                title: `Out of Business`,
                body: `Unfortunately, ${oobClient} has gone out of business. They are no longer your client.`,
                time: new Date().toLocaleTimeString(),
                type: 'info',
                op: { delay: 10000, autohide: true },
              },
            })
          })
        } catch (err) {
          console.log('Unable to parse oobClients JSON.', err)
        }
      }
    }
  }, [
    teamRunAdvanceOobNotif.enabled,
    teamRunAdvanceOobNotif.message,
    notificationDispatch,
    userId,
  ])

  /**
   * Show connection interrupted modal
   */
  useEffect(() => {
    if (reloadRequired) {
      setMustReloadPage(true)
    }
  }, [dispatch, reloadRequired])
  useEffect(() => {
    if (mustReloadPage && reloadRequired) {
      dispatch({ type: 'reload-off' })
    }
  }, [dispatch, mustReloadPage, reloadRequired])

  return (
    <div className="App">
      <LoadingData show={loading} />
      <ModalProgress title={mergeModalTitle} show={merging || reloading}>
        {merging && (
          <>
            <p>
              Congratulations! You and the other firm have chosen to merge
              together.
            </p>
            <div>
              Please wait while the merge is in progress...{' '}
              <Spinner
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="primary"
              />
            </div>
          </>
        )}
        {reloading && (
          <>
            <p>
              Congratulations! You have merged with another firm. Your name is
              now {largerTeamName}.
            </p>
            <div className="text-center">
              <Button
                variant="outline-primary"
                className="rounded-pill"
                onClick={() => window.location.reload()}
              >
                Reload
              </Button>
            </div>
          </>
        )}
      </ModalProgress>

      <ModalProgress
        headerTextColor="text-danger"
        title={'Connection Closed'}
        show={mustReloadPage}
      >
        <>
          <p>Your connection was interrupted!</p>
          <div className="text-center">
            <Button
              variant="outline-primary"
              className="rounded-pill"
              onClick={() => window.location.reload()}
            >
              Reload
            </Button>
          </div>
        </>
      </ModalProgress>
      <ScrollToTop />
      <Header />
      <ToastManager />
      <Outlet />
      <Footer />
      {isTeam && <ChatFloat />}
    </div>
  )
}
