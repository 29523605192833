import { useCallback } from 'react'
import { API, graphqlOperation } from 'aws-amplify'

import { getHost } from '../graphql/queries'
import { advanceSimYear as advanceSimYearMutation } from '../graphql/mutations'

export const useHost = () => {
  const fetchHost = useCallback(async (id) => {
    return API.graphql(
      graphqlOperation(getHost, {
        id,
      })
    )
  }, [])

  const advanceSimYear = useCallback(async (offeringId, toYear) => {
    return API.graphql(
      graphqlOperation(advanceSimYearMutation, {
        input: {
          offeringId,
          toYear,
        },
      })
    )
  }, [])

  return {
    fetchHost,
    advanceSimYear,
  }
}
