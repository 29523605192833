import React, { useState } from 'react'
import clsx from 'clsx'
import { ReactComponent as LocationIcon } from '../../images/icons/location-icon.svg'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import './style.css'

const Building = (props) => {
  /** Company Status will have 3 posible values:
   *    0- Under construction
   *    1- Working
   *    2- Out of business
   */
  const companyStatus =
    props.status >= 0 && props.status < 3 ? parseInt(props.status) : 0

  /*Company´s name that will be shown as a label*/
  const companyName = props.name

  /*Changes the Building Style*/
  const buildingType = props.type
  const typeClass = 'building-type-' + buildingType

  /* Accepts Bids  */
  const acceptBids = props.acceptBids === true

  const detail = props.detail
  const immediateNeed = props.immediateNeed
  const industry = props.industry
  const engageSize = props.engageSize
  const engageType = props.engageType

  const clickHandler = () => {
    companyStatus === 1 && handleShow()
  }

  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <div
        className={clsx(
          'building',
          typeClass,
          acceptBids && 'activeBid',
          companyStatus === 0 && 'building-underconstruction',
          companyStatus === 1 && 'building-working',
          companyStatus === 2 && 'building-outofbusiness'
        )}
        onClick={clickHandler}
      >
        <div className="building-icon"></div>
        {companyStatus === 1 && (
          /* Company Name and loc icon only shown if companyStatus is 1 ::*/
          <React.Fragment>
            <h5>{companyName}</h5>
            <LocationIcon />
          </React.Fragment>
        )}
      </div>
      <Modal
        centered
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        contentClassName="rounded-0 shadow-sm px-2 py-2"
        dialogClassName="bls-modal"
      >
        {companyName && (
          <Modal.Header className="border-0 pb-0">
            <Modal.Title className="h5 text-primary fw-bold">
              {companyName}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          <ul>
            <li>
              <strong>Industry:</strong> {industry}
            </li>
            <li>
              <strong>Immediate Need:</strong> {immediateNeed}
            </li>
            <li>
              <strong>Engagement Size:</strong> {engageSize}
            </li>
            <li>
              <strong>Recurrence:</strong> {engageType}
            </li>
          </ul>

          <div dangerouslySetInnerHTML={{ __html: detail }} />
        </Modal.Body>
        <Modal.Footer className="justify-content-center border-0">
          <Button
            variant="outline-success"
            className="rounded-pill"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Building
